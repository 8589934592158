import { Component, OnInit } from "@angular/core";
import { I18NService } from "../../../services/I18NService";
import { LocalService } from "../../../services/LocalService";
import { GenericComponent } from "../../../GenericComponent";
import { TokenStorageService } from "../../../services/token-storage.service";
import { Document } from "../../../pojo/Document";
import { DocumentService } from "../../../services/document.service";
import { DocCategoryService } from "../../../services/doc-category.service";
import { ModalService } from "../../../common-elements/_modal";
import { DomSanitizer } from "@angular/platform-browser";
import { UploadFileService } from "../../../services/upload-file.service";
import { DocSubCategoryService } from "../../../services/doc-sub-category.service";
import { HttpParams } from '@angular/common/http';
import 'rxjs/Rx' ;
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { isEmptyObject } from "jquery";

@Component({
  selector: "app-list-document",
  templateUrl: "./list-document.component.html",
  styleUrls: ["./list-document.component.css"],
})
export class ListDocumentComponent extends GenericComponent implements OnInit {
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  subcategories: any = [];
  searchTitle: any;
  categoryId: any;
  subcategoryId: any;
  categories: any[];
  documents: any[];
  documentDetail: Document;
  documentUpdated: Document;
  deleteDocId: any;
  updateDocumentId: any;
  hasimage: boolean;
  isImage: boolean;
  hasVideo: boolean;
  invalidDocForm: boolean;
  isFile: boolean;
  extList = ["png", "jpg", "jpeg", "gif"];
  extFileList = ["pdf"];
  extDocList = ["doc", "csv", "docx", "zip", "ppt", "pptx","html", "msoffice"];
  extXlsList = ["xls", "xlsx","stream"];

  fileSrc: any;
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  fileType: string;
  visible: boolean;
  mode: string = "indeterminate";
  docForm: FormGroup;
  submitted = false;
  fileName: string;
  p: number = 1;
  count: number ;
  getDocumentBy: string ;
  noDocFound : boolean = false ;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: " ",
    translate: "no",
    toolbarHiddenButtons: [["insertImage"], ["insertVideo"], ["link"], ["unlink"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  constructor(
    private modalService: ModalService,
    private docCategoryService: DocCategoryService,
    private documentService: DocumentService,
    public sanitizer: DomSanitizer,
    private localService: LocalService,
    private i18NService: I18NService,
    private token: TokenStorageService,
    private fileService: UploadFileService,
    private docSubCategoryService: DocSubCategoryService,
    private fb: FormBuilder
  ) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit() {
    this.getDocumentBy = 'all';
    this.invalidDocForm = false;
    this.documentDetail = new Document();
    this.documentUpdated = new Document();
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes("ROLE_ADMIN");
    this.visible = false;
    this.docCategoryService.getAllDocCategories().subscribe(
      (data) => {
        this.categories = data;
      },
      (error) => { }
    );
    this.getAlldocuments(this.p);
    // init form edit
    this.initForm(this.documentUpdated);
  }
  getVideoType(documentLink, document) {
    if (documentLink.includes("youtube")) {
      document.videoType = "youtube";
    }
    else if (documentLink.includes("dailymotion")) {
      document.videoType = "dailymotion";
    }
    else if (documentLink.includes("vimeo")) {
      document.videoType = "vimeo";
    }
  }

  /**
   * Download one document by id
   * @param document
   * @param index
   */
  downloadDocument(document: any, id: number){
    this.documentService.downloadById(id, this.i18n).subscribe((data) => {
      document.fileUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(data['file']);
      if (document.isPdf) {
        const base64String = data['file'].toString();
        var stringLength = base64String.length - 'data:application/pdf;base64,'.length;
        var sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
        var sizeInKb = sizeInBytes / 1000;
        if (sizeInKb > 1500) {
          document.isLarge = true;
        }
      }
      const url= window.URL.createObjectURL(this.base64toBlob(data['file'].toString(), document));
      var newWindow = window.open(url, "_blank");
      newWindow.document.title = document.title;
    });
  }

  /**
   * Base64 string to blob
   * @param base64Data
   * @param document 
   * @returns 
   */
  base64toBlob(base64Data: string, document: any) {
    const sliceSize = 1024;
    const startPoint = base64Data.indexOf(',') >=0 ? base64Data.indexOf(','): base64Data.indexOf(';');
    const byteCharacters = atob(base64Data.substring(startPoint+1));
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    var typeDoc: string = document.isDoc? "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            document.isXls? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            "application/pdf";
    return new Blob(byteArrays, { type: typeDoc });
  }

  /**
   * Empty file handler method
   * @param data1 
   */
  emptyFileHandler(data: any[]){
    this.count = data["count"];
    this.documents = data["results"];
    this.documents.forEach((value, index) => {
      if (value.videoLink) {
         var documentLink = value.videoLink;
         this.getVideoType(documentLink, value);
      }
      if (value.fileUrl != null && value.fileUrl != "") {
        let mimeType = value.fileUrl.match(/[^:/]\w+(?=;|,)/)[0];
        value.isPdf = this.extFileList.some(ext => mimeType.includes(ext));
        value.isImage = this.extList.some(ext => mimeType.includes(ext));
        value.isDoc = this.extDocList.some(ext => mimeType.includes(ext));
        if(value.isImage) {
          value.fileUrl = value.fileUrl;
        } else {
          value.isLarge = true;
        }
      }
    });
  }

  /**
   * Get all the documents [WITHOUT THE FILE!]
   * @param p 
   */
  getAlldocuments(p) {
    this.noDocFound = false;
    this.getDocumentBy = 'all';
    this.documentService.setRequestParams(
      new HttpParams().set('page', p.toString())
    );
    this.documentService.getAllDocuments(this.i18n, p).subscribe(
      (data) => {
        if(data == null) {
          this.noDocFound = true;
        }
        if(data){
          this.emptyFileHandler(data);
        }
      },
      (error) => { }
    );
  }

  /**
   * Get all documents by title [WITHOUT THE FILE!]
   * @param p
   */
  searchDocumentByTitle(p) {
    this.documentService.setRequestParams(
      new HttpParams().set('page', p.toString())
    );
    this.p = p ;
    this.getDocumentBy = 'title';
    var fieldValue = this.searchTitle;
    if (fieldValue == null || fieldValue == '' || fieldValue.length == 0) {
      this.getAlldocuments(1);
    }
    else {
      this.documentService
        .getDocumentByTitle(fieldValue, this.i18n)
        .subscribe(
          (data) => {
            if(data == null) {
                this.noDocFound = true;
            }
            else {
             this.noDocFound = false;
             this.emptyFileHandler(data);
            }
          },
          (error) => { }
        );
    }
  }

  /**
   * Filter documents by category [WITHOUT THE FILE!]
   * @param p 
   */
  searchDocumentByCategory(p) {
    this.noDocFound = false;
    if (this.categoryId == "undefined") {
      this.subcategories = []
      this.getAlldocuments(1);
    } else {
      this.documentService.setRequestParams(
        new HttpParams().set('page', p.toString())
      );
      this.p = p ;
      this.getDocumentBy = 'category';
      this.documentService
        .getDocumentByCategoryId(this.categoryId, this.i18n)
        .subscribe(
          (data) => {
              this.getCategoryId(this.categoryId);
              if(data == null) {
                this.noDocFound = true;
              }
              else {
                this.emptyFileHandler(data);
              }
          },
          (error) => { }
        );
    }
    this.subcategoryId = "undefined";
  }

  /**
   * Get cateogory with subcategories by ID
   * @param categoryId 
   */
  getCategoryId(categoryId) {
    this.docSubCategoryService.getSubCategoriesByCatId(categoryId, this.i18n).subscribe((data) => {
      this.subcategories = data;
    });
  }

  /**
   * Filter documents by Subcategory [WITHOUT THE FILE!]
   * @param p 
   */
  searchDocumentBySubCategory(p) {
    this.noDocFound = false;
    if (this.subcategoryId == "undefined") {
      this.searchDocumentByCategory(1)
    } else {
      this.documentService.setRequestParams(
        new HttpParams().set('page', p.toString())
      );
      this.p = p ;
      this.getDocumentBy = 'subcategory';
      this.documentService
        .getDocumentBySubCategoryId(this.subcategoryId, this.i18n)
        .subscribe(
          (data) => {
            this.getCategoryId(this.categoryId);
            if(data == null) {
              this.noDocFound = true;
            }
            else {
              this.emptyFileHandler(data);
            }
          },
          (error) => { }
        );
    }
  }

  /**
   * Operan modal to display one picture
   * @param id 
   * @param documentId 
   */
  openModalRead(id: string, documentId: any) {
    this.modalService.open(id);
    this.documentService
      .getDocumentByDocumentId(documentId, this.i18n)
      .subscribe(
        (data) => {
          this.documentDetail = data;
          if (data.videoLink) {
            var documentLink = data.videoLink;
            this.getVideoType(documentLink, this.documentDetail);
          }
          if (data.fileUrl != null && data.fileUrl != "") {
            let mimeType = data.fileUrl.match(/[^:/]\w+(?=;|,)/)[0];
            this.documentDetail.isPdf = this.extFileList.some(ext => mimeType.includes(ext));
            this.documentDetail.isImage = this.extList.some(ext => mimeType.includes(ext));
            if (this.documentDetail.isPdf) {
              this.documentDetail.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.fileUrl);
              const base64String = this.documentDetail.fileUrl.toString();
              var stringLength = base64String.length - 'data:application/pdf;base64,'.length;
              var sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
              var sizeInKb = sizeInBytes / 1000;
              if (sizeInKb > 1500) {
                this.documentDetail.isLarge = true;
              }
              else {
                this.documentDetail.isLarge = false;
              }
            }
            else {
              this.documentDetail.fileUrl = data.fileUrl;
            }
          }
        },
        (error) => { }
      );
  }
  getDocCategoryId(categoryId){
    this.docSubCategoryService.getSubCategoriesByCatId(categoryId, this.i18n).subscribe((data) => {
      this.subcategories = data;
    });
  }
  openPdf(url: any) {
    window.open(url, "_blank");
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  openDeleteModal(id: string, documentId: any) {
    this.modalService.open(id);
    this.deleteDocId = documentId;
  }
  deleteDocument() {
    this.documentService.deleteDocument(this.deleteDocId, this.i18n).subscribe(
      (data) => {
        this.documents = data;
        this.closeModal("custom-modal-delete-document");
        window.location.reload();
      },
      (error) => { }
    );
  }
  openEditModal(id: string, documentId: any) {
    this.modalService.open(id);
    this.updateDocumentId = documentId;
    this.documentService.getDocumentByDocumentId(documentId, this.i18n).subscribe(
      (data) => {
        this.documentUpdated = data;
        this.getDocCategoryId(data.categoryId);
        if (data.fileUrl != null && data.fileUrl != "") {
          this.documentUpdated.fileType = "file";
          let mimeType = data.fileUrl.match(/[^:/]\w+(?=;|,)/)[0];
          let isPdf = this.extFileList.some(ext => mimeType.includes(ext));
          this.fileName = "";
          if (isPdf) {
            this.documentUpdated.fileType = "file";
          }
        }
        else {
          this.documentUpdated.fileType = "video";
        }
        this.initForm(data);
      },
      (error) => { }
    );
  }
  activateProgressBar() {
    this.mode = "determinate";
  }
  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }
  upload(): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFile = this.selectedFiles.item(i);
      const formData = new FormData();
      formData.append("targetType", "media");
      formData.append("targetId", JSON.stringify(this.updateDocumentId));
      formData.append("file", this.currentFile);
      formData.append("cover", this.documentUpdated.fileUrl);
      this.fileService.udpateFile(formData).subscribe(
        (event) => {
          if(event['add file'] == 'true'){
            this.closeModal("custom-modal-loading-doc");
            this.closeModal("custom-modal-edit-document");
            window.location.reload();
          }
          if(event['update file'] == 'true'){
            this.closeModal("custom-modal-loading-doc");
            this.closeModal("custom-modal-edit-document");
            window.location.reload();
          }
        },
        (err) => {
          console.log("Error while uploading file");
        }
      );
    }
  }
  editDocument() {
    this.openModal("custom-modal-loading-doc");
    this.submitted = true;
    if (this.selectedFiles != null) {
      this.docForm.patchValue({ videoLink: null });
      this.documentUpdated.videoLink = null;
    }
    if (this.docForm.value.videoLink != null && this.selectedFiles != undefined) {
      this.invalidDocForm = true;
      this.docForm.patchValue({ videoLink: null });
      this.docForm.controls["videoLink"].clearValidators();
      this.closeModal("custom-modal-loading-doc");
      return;
    }
    if ((this.docForm.value.videoLink != null || this.docForm.value.videoLink != '') && this.docForm.value.fileType === 'video') {
      this.fileService
        .deleteFile(JSON.stringify(this.updateDocumentId), "media")
        .subscribe(
          (event) => {
          },
          (err) => {
            console.log("Error while deleting file");
          }
        );
    }
    this.documentService
      .updateDocument(this.updateDocumentId, this.docForm.value, this.i18n)
      .subscribe(
        (data) => {
          if (this.selectedFiles != null) {
            this.upload();
          }
          else {
            this.closeModal("custom-modal-loading-doc");
            this.closeModal("custom-modal-edit-document");
            window.location.reload();
          }
        },
        (error) => { }
      );
  }
  initForm(oldDocument) {
    this.docForm = this.fb.group({
      title: [oldDocument.title],
      content: [oldDocument.content],
      categoryId: [oldDocument.categoryId, Validators.required],
      subcategoryId: [oldDocument.subcategoryId , Validators.required],
      fileType: [oldDocument.fileType, Validators.required],
      videoLink: [oldDocument.videoLink, this.requiredIfVideo],
    });
  }
  requiredIfVideo(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get("fileType").value == "video") {
      return Validators.required(formControl);
    }
    return null;
  }
  get f() {
    return this.docForm.controls;
  }
  updateFileType(e) {
    this.docForm.patchValue({ fileType: e.value });
  }
  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  getDailyMotionId(url) {
    var m = url.match(/^.+dailymotion.com\/(video|hub)\/([^_?]+)[^#]*(#video=([^_&]+))?/);
    if (m !== null) {
      if (m[4] !== undefined) {
        return m[4];
      }
      return m[2];
    }
    return null;
  }
  getVimeoId = (url: string) => {
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    }
  };
  pageChanged($event) {
    this.p = $event;
    if(this.getDocumentBy == 'all'){
      this.getAlldocuments($event);
    }
    else if(this.getDocumentBy == 'title'){
      this.searchDocumentByTitle($event);
    } else if(this.getDocumentBy == 'category'){
      this.searchDocumentByCategory($event);
    } else if(this.getDocumentBy == 'subcategory'){
      this.searchDocumentBySubCategory($event);
    }
  }
}

