import {Component, OnDestroy, OnInit} from '@angular/core';
import {I18NService} from './services/I18NService';
import {LocalService} from './services/LocalService';
import {GenericComponent} from './GenericComponent';
import { MatDialog } from '@angular/material/dialog';
import { ReportChoiceComponent } from './components/report-choice/report-choice.component';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends GenericComponent implements OnInit,OnDestroy{

  title = 'dcaf-ui';
  appsUI: any = {};
  hasAdvertBeenShown = this.hasAdvertBeenShownBefore();

  nbVisit:number = 0;
  scrollStrategy: ScrollStrategy;

  ngOnInit(){
    if(this.hasAdvertBeenShown == "false" || this.hasAdvertBeenShown == null){
      this.openDialog();
    }
  }
  constructor(private localService: LocalService, private i18NService: I18NService,public dialog: MatDialog,private readonly sso: ScrollStrategyOptions) {
    
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
    this.scrollStrategy = this.sso.noop(); 
  }

  openDialog() {
    this.dialog.open(ReportChoiceComponent, {width: '900px', height: '569px',scrollStrategy: this.scrollStrategy});
    this.markAsSeen();
  }
  hasAdvertBeenShownBefore(){
    return  window.sessionStorage.getItem("OPENED");
  }
  markAsSeen(){
    window.sessionStorage.setItem("OPENED", JSON.stringify(true));
  }
  markAsNotSeen(){
    window.sessionStorage.setItem("OPENED", JSON.stringify(false));
  }
  ngOnDestroy(){
    this.markAsNotSeen();
  }
}
