import { Component, OnInit } from '@angular/core';
import {GenericComponent} from "../../GenericComponent";
import {Router} from "@angular/router";
import {TokenStorageService} from "../../services/token-storage.service";
import {LocalService} from "../../services/LocalService";
import {I18NService} from "../../services/I18NService";
import {ModalService} from "../_modal";
import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent extends GenericComponent implements OnInit {

  appsUI: any = {};
  isLoggedIn : boolean;
  isAdmin : boolean ;
  roles: string[] = [];
  currentUser:  any = {};
  overlay = false;


  constructor(private router: Router, private tokenStorageService: TokenStorageService, private localService: LocalService, private i18NService: I18NService, private modalService:ModalService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if (this.tokenStorageService.getToken()) {
      this.roles = this.tokenStorageService.getUser().roles;
      this.currentUser = this.tokenStorageService.getUser();
    }
    if (this.tokenStorageService.getRememberMe()) {
      this.roles = this.tokenStorageService.getUserRememberMe().roles;
      this.currentUser = this.tokenStorageService.getUserRememberMe();
      this.isLoggedIn = true;

    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');


    var dropdown = document.getElementsByClassName("dropdown-btn");
    var i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  }
  openNav(id:string) {
    document.getElementById(id).style.width = "17.2rem";
    this.overlay = !this.overlay;
  }
  closeNav(id:string) {
    document.getElementById(id).style.width = "0";
    this.overlay= false;
  }

  logout() {
    this.tokenStorageService.signOut();
    this.router.navigate(['/'])
      .then(() => {
          window.location.reload();
        }
      );
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
  
  onChange(event){
    localStorage.setItem("setLanguage", "true");
    localStorage.setItem("i18n", event);
    location.reload();
  }
}
