import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocCategoryService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/doccategory/' ;
    }
    getAllDocCategories(): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "public");
    }
    deleteDocCategory(id: number): Observable<any> {
       const url = `${this.DCAF_WS_API}${id}`;
       return this.http.delete(url);
    }
    addDocCategory(category: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API , category);
    }
    getDocCategoryById(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API +  + "public/" + id);
    }
    updateDocCategory(id: any , category: any): Observable<any> {
       return this.http.put(this.DCAF_WS_API + 'update/'+  id , category);
    }}
