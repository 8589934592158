export class ContactForm {
  formId: number;
  email: string;
  name: string;
  subject: string;
  message: string;

  constructor() {
  }
}
