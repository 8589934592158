import { Component, OnInit } from '@angular/core';
import {DocCategory} from "../../../pojo/DocCategory";
import { DocCategoryService } from '../../../services/doc-category.service';
import {ActivatedRoute} from '@angular/router';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';

@Component({
  selector: 'app-edit-doc-category',
  templateUrl: './edit-doc-category.component.html',
  styleUrls: ['./edit-doc-category.component.css']
})
export class EditDocCategoryComponent extends GenericComponent implements OnInit {

  docCategoryId: any ;
  docCategory: DocCategory;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};

  constructor(private localService: LocalService, private i18NService: I18NService, private token: TokenStorageService, private route : ActivatedRoute, private docCategoryService: DocCategoryService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }


  ngOnInit() {
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.route.params.subscribe(params => {
        this.docCategoryId = params.id ;
    });
    this.docCategory = new DocCategory();
    this.docCategoryService.getDocCategoryById(this.docCategoryId).subscribe(
      data => {
        this.docCategory = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  editDocCategory(){
    this.docCategoryService.updateDocCategory(this.docCategoryId , this.docCategory).subscribe(
      data => {
      },
      error => {
        console.log(error);
      }
    );
  }
}
