import { Component, OnInit } from '@angular/core';
import {GenericComponent} from "../../../GenericComponent";
import {TokenStorageService} from "../../../services/token-storage.service";
import {LocalService} from "../../../services/LocalService";
import {I18NService} from "../../../services/I18NService";
import {CategoryBlog} from "../../../pojo/CategoryBlog";
import {CategoryBlogService} from "../../../services/category-blog.service";
import {Contact} from "../../../pojo/Contact";

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent extends GenericComponent implements OnInit {

  category: CategoryBlog;
  categoryId: any ;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};

  constructor(private token: TokenStorageService, private categoryBlogService: CategoryBlogService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }


  ngOnInit() {
    this.category = new CategoryBlog();
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.categoryBlogService.getCategoryById(this.categoryId).subscribe(
      data => {
        this.category = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  editCategory(){
    this.categoryBlogService.updateCategory(this.categoryId , this.category).subscribe(
      data => {
        console.log("updated")
      },
      error => {
        console.log(error);
      }
    );
  }

}
