export class RequestService {
  requestId: number;
  serviceName: string;
  address: string;
  tel: string;
  mail: string;
  governorate: string;
  state:string;
  description:any;
  fileUrl: any;
}
