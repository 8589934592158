import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

    private DCAF_WS_API : string = "";
    private DCAF_SI_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string , @Inject('siconf') private siconf : string) {
       this.DCAF_WS_API = conf +'/report/' ;
       this.DCAF_SI_API = siconf +'/api/' ;
    }
    getAllReports(): Observable<any> {
       return this.http.get(this.DCAF_WS_API );
    }
    addReport(report: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API + "public/", report);
    }
    updateReport(id: number, report: any): Observable<any> {
      return this.http.put(this.DCAF_WS_API + "updateReport/"+id, report);
   }

    getReportById(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + id);
    }
    deleteReport(id: number) {
       const url = `${this.DCAF_WS_API}${id}`;
       return this.http.delete(url);
    }
    updateReportState(id: any , state: string): Observable<any> {
       return this.http.put(this.DCAF_WS_API + 'update/'+  id , state);
    }
    getReportByGovernorate(governorate: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "governorate/" + governorate);
    }

    getReportByReportTypeId(reportTypeId: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "reportTypeId/"+reportTypeId);
    }
    sendMailFunction(report: any): Observable<any> {
      return this.http.post(this.DCAF_WS_API +'public/sendEmail', report);
   }
   sendMailToAdmin(report: any): Observable<any> {
      return this.http.post(this.DCAF_WS_API +'public/sendEmailToAdmin', report);
   }

}
