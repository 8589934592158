import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/question/' ;
    }
    getAllQuestions(lang:string): Observable<any> {
       return this.http.get(this.DCAF_WS_API + 'public/all/' + lang);
    }
    deleteQuestion(id: number) {
       const url = `${this.DCAF_WS_API}${id}`;
       return this.http.delete(url);
    }
    addQuestion(question: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API , question);
    }
    getQuestionById(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "public/" + id);
    }
    updateQuestion(id: any , question: any): Observable<any> {
       return this.http.put(this.DCAF_WS_API + 'update/'+  id , question);
    }
    getAllChildQuestions(lang:string,isTeenager:Boolean): Observable<any> {
      return this.http.get(this.DCAF_WS_API + 'public/child/' + lang + "/" + isTeenager);
    }
    getAllAdultQuestions(lang:string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + 'public/adult/' + lang);
    }
}
