export class Home {
  homeId: any;
  video_link: string;
  description: any;
  card1_content: any;
  card2_content: any;
  card3_content: any;
  slide1_text: any;
  slide2_text: any;
  slide3_text: any;
  lang : string;
  fileUrl1: string;
  fileUrl2: string;
  fileUrl3: string;
  urlPath1: string;
  urlPath2: string;
  urlPath3: string;

  constructor() {
  }
}
