import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CategoryBlogService {
  private DCAF_WS_API : string = "";

  constructor(private http: HttpClient, @Inject('conf') private conf : string) {
    this.DCAF_WS_API = conf + '/categoryblog/';
  }

  addCategory( category: any): Observable<any> {
    return this.http.post(this.DCAF_WS_API ,  category);
  }
  deleteCategory(id: number): Observable<any> {
    const url = `${this.DCAF_WS_API}${id}`;
    return this.http.delete(url);
  }
  updateCategory(id : any ,  category: any): Observable<any> {
    return this.http.put(this.DCAF_WS_API+ 'update/'+ id ,  category);
  }
  getCategoryById(id: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/" + id);
  }
  getAllCategories(): Observable<any>{
    return this.http.get(this.DCAF_WS_API + "public");
  }
}
