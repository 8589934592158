import { Component, OnInit } from "@angular/core";
import { GenericComponent } from "../../../GenericComponent";
import { TokenStorageService } from "../../../services/token-storage.service";
import { LocalService } from "../../../services/LocalService";
import { I18NService } from "../../../services/I18NService";
import { RequestServiceService } from "../../../services/request-service.service";
import { RequestService } from "../../../pojo/RequestService";
import { ModalService } from "../../../common-elements/_modal";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
} from "@angular/forms";
import { ServiceCategoryService } from "../../../services/service-category.service";
import { ServiceSubCategoryService } from "../../../services/service-sub-category.service";
import { UploadFileService } from "../../../services/upload-file.service";

@Component({
  selector: "app-suggestion-service",
  templateUrl: "./suggestion-service.component.html",
  styleUrls: ["./suggestion-service.component.css"],
})
export class SuggestionServiceComponent
  extends GenericComponent
  implements OnInit
{
  requestService: RequestService;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  regionId: any;
  regions: any[];
  serviceForm: FormGroup;
  show = false;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: " ",
    translate: "no",
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  submitted = false;
  categServices:any[];
  subCategServices:any[];
  mode: string = "indeterminate";
  selectedFiles: FileList;
  visible: boolean = false;
  currentFile: File;
  constructor(
    private fb: FormBuilder,
    private token: TokenStorageService,
    private requestServiceService: RequestServiceService,
    private localService: LocalService,
    private i18NService: I18NService,
    private modalService: ModalService,
    private servCategoryService:ServiceCategoryService,
    private servSubCategoryService:ServiceSubCategoryService,
    private uploadService: UploadFileService
  ) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  initForm() {
    this.serviceForm = this.fb.group({
      serviceName: ["", Validators.required],
      governorate: ["", Validators.required],
      mail: [""],
      description: [""],
      establishment: ["", Validators.required],
      address: [""],
      missions: ["", Validators.required],
      beneficiaries: ["", Validators.required],
      services: ["", Validators.required],
      partners: ["", Validators.required],
      geographiccoverage: ["", Validators.required],
      greenline: [""],
      fax: [""],
      website: [""],
      tel: ["", Validators.required],
      categoryId: ["", Validators.required],
      subCategoryId: ["", Validators.required]
    });
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  ngOnInit() {
    this.requestService = new RequestService();
    this.initForm();
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes("ROLE_ADMIN");

    this.regions = [
      this.appsUI.Ariana[this.i18n],
      this.appsUI.Beja[this.i18n],
      this.appsUI.BenArous[this.i18n],
      this.appsUI.Bizerte[this.i18n],
      this.appsUI.Gabès[this.i18n],
      this.appsUI.Gafsa[this.i18n],
      this.appsUI.Jendouba[this.i18n],
      this.appsUI.Kairouan[this.i18n],
      this.appsUI.Kasserine[this.i18n],
      this.appsUI.Kebili[this.i18n],
      this.appsUI.Kef[this.i18n],
      this.appsUI.Mahdia[this.i18n],
      this.appsUI.Manouba[this.i18n],
      this.appsUI.Médenine[this.i18n],
      this.appsUI.Monastir[this.i18n],
      this.appsUI.Nabeul[this.i18n],
      this.appsUI.Sfax[this.i18n],
      this.appsUI.SidiBouzid[this.i18n],
      this.appsUI.Siliana[this.i18n],
      this.appsUI.Sousse[this.i18n],
      this.appsUI.Tataouine[this.i18n],
      this.appsUI.Tozeur[this.i18n],
      this.appsUI.Tunis[this.i18n],
      this.appsUI.Zaghouan[this.i18n],
      this.appsUI.everywhere[this.i18n],
    ];
    this.getAllCategService();
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  addService() {
    this.submitted = true;
    if (this.serviceForm.invalid) {
      return;
    }
    if (this.serviceForm.valid) {
      this.requestServiceService
        .addRequestService(this.serviceForm.value)
        .subscribe(
          (res) => {

            this.submitted = false;
            if (this.selectedFiles != null) {
              this.upload(res.requestId);
            }
            else {
              this.closeModal("custom-modal-suggestion-service");
              this.openModal('custom-modal-confirm-add');
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
  showSuggestionForm() {
    this.openModal("custom-modal-suggestion-service");
  }
  getAllCategService(){
    this.servCategoryService.getAllServicesCategoriesPic(this.i18n).subscribe(
      (data)=>{
          this.categServices = data;
      },
      (err) =>{

      }
    )

  }
  getAllSubCategService(id){
    this.servSubCategoryService.getAllSubCategByCategId(id,this.i18n).subscribe(
      (data)=>{
          this.subCategServices = data;
      },
      (err) =>{

      }
    )

  }

  activateProgressBar() {
    this.mode = "determinate";
  }
  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }

  upload(serviceId): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFile = this.selectedFiles.item(i);
      const formData = new FormData();
      formData.append("targetType", "request-service-doc");
      formData.append("targetId", JSON.stringify(serviceId));
      formData.append("file", this.currentFile);
      this.uploadService.upload(formData).subscribe((event) => {
        if(event['add file'] == 'true'){
          this.closeModal("custom-modal-suggestion-service");
          this.openModal("custom-modal-confirm-add");
        }
      });
    }
  }
}
