import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportTypeService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/reportType/' ;
    }
    getAllReportTypes(): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "public");
    }
    deleteReportType(id: number) {
       const url = `${this.DCAF_WS_API}${id}`;
       return this.http.delete(url);
    }
    addReportType(reportName: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API , reportName);
    }
    getReportTypeById(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "public/" + id);
    }
    updateReportType(id: any , reportName: any): Observable<any> {
       return this.http.put(this.DCAF_WS_API + 'update/'+  id , reportName);
    }
}
