import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Question} from "../../../pojo/Question";
import { QuestionService } from '../../../services/question.service';
import { NotifierService } from "angular-notifier";
import { ViewChild } from "@angular/core";
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import {ModalService} from "../../../common-elements/_modal";
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.css']
})
export class EditQuestionComponent extends GenericComponent implements OnInit {

  @ViewChild("customNotification", { static: true }) customNotificationTmpl;
  questionId: any ;
  faq: Question;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};

  private notifier: NotifierService;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: ' ',
    translate: 'no',
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  constructor(private modalService: ModalService, private token: TokenStorageService, private route : ActivatedRoute, private questionService: QuestionService, notifierService: NotifierService , private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
    this.notifier = notifierService;
   }

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.questionId = params.id ;
    });
    this.faq = new Question();
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.questionService.getQuestionById(this.questionId).subscribe(
      data => {
      this.faq.question = data.question;
      this.faq.response = data.response;
      this.faq.isChild = data.isChild;

      },
      error => {
        console.log(error);
      }
    );
  }
  showNotification() {
    this.notifier.show({
      message: "",
      type: "info",
      id: "customNotificationId",
      template: this.customNotificationTmpl
    });
  }
  hideCustomNotification(){
    this.notifier.hide("customNotificationId");
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
   openModal(id: string) {
     this.modalService.open(id);
   }
  editQuestion(){
    this.questionService.updateQuestion(this.questionId , this.faq).subscribe(
      data => {
        this.openModal('custom-modal-confirm-add');
      },
      error => {
        console.log(error);
      }
    );
  }
}
