import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStorageService } from './token-storage.service';
import { approvalService } from './approval.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: TokenStorageService, private appService:approvalService ,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    if (token) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` }
      });
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if ([401, 403].includes(err.status) && token) {
            this.auth.signOut();
            this.router.navigate(['/'])
            .then(() => {
                window.location.reload();
              }
            );
          }
        }
        return throwError(err);
      })
    )
  }
}
