import {Component, Input, OnInit} from '@angular/core';
import {GenericComponent} from "../../../GenericComponent";
import {Router} from "@angular/router";
import {TokenStorageService} from "../../../services/token-storage.service";
import {LocalService} from "../../../services/LocalService";
import {I18NService} from "../../../services/I18NService";
import {Service} from "../../../pojo/Service";
import { ModalService } from '../../../common-elements/_modal';
import {ServiceService} from "../../../services/service.service";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.css']
})
export class ServiceCardComponent extends GenericComponent implements OnInit {
  @Input() service: Service;
  appsUI: any = {};
  deleteReportId:any;
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  serviceUpdate : Service ;
  establishment : any;
  serviceIdUpdate : any ;
  editServiceForm: FormGroup;
  serviceId:number;
  @Input() idService = '';
  constructor(private fb: FormBuilder, private token: TokenStorageService, private serviceService: ServiceService,
    private modalService: ModalService, private router: Router, private localService: LocalService, private i18NService: I18NService,
    private route: ActivatedRoute,public sanitizer: DomSanitizer) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  initForm() {
    this.editServiceForm = this.fb.group({
      title: [""]
    });
  }
  ngOnInit() {
  this.initForm();
  this.service = new Service();
  if (this.serviceUpdate == undefined) {
    this.serviceUpdate = new Service();
      }
    this.serviceUpdate = new Service();
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.serviceService.getServiceById(this.idService,this.i18n).subscribe((service) => {
      this.service = service;
      this.service.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(service.fileUrl);
    });
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  confirmDelete(serviceId){
    this.serviceService.deleteService(serviceId).subscribe((data)=>{
      window.location.reload();
    },
      error => {
        console.log(error);
      });
  }
  editService(id){
      console.log(id);
      this.serviceIdUpdate = id;
      this.serviceService.getServiceById(this.serviceIdUpdate,this.i18n).subscribe(
        data => {
          this.editServiceForm.get("title").setValue("data.establishment");
          this.serviceUpdate.establishment = data.establishment;
          this.establishment = data.establishment;
          this.editServiceForm.get("title").setValue("data.establishment");
          this.openModal('custom-modal-edit-service');
        },
        error => {
          console.log(error);
        }
      );
  }
  updateService(id){
    this.serviceService.updateService(id,this.serviceUpdate).subscribe((service) => {
      console.log("update service");
    });

  }
}
