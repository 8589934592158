import { Component, OnInit } from '@angular/core';
import { ReportTypeService } from '../../../services/report-type.service';
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ModalService } from "../../../common-elements/_modal";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule
} from "@angular/forms";

@Component({
  selector: 'app-add-report-type',
  templateUrl: './add-report-type.component.html',
  styleUrls: ['./add-report-type.component.css']
})
export class AddReportTypeComponent extends GenericComponent implements OnInit {

  constructor(private fb: FormBuilder, private modalService: ModalService, private reportTypeService: ReportTypeService, private token: TokenStorageService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  private reportTypeName: string;
  appsUI: any = {};
  roles: string[] = [];
  categoryForm: FormGroup;
  isAdmin: boolean;
  currentUser: any = {};

  initForm() {
    this.categoryForm = this.fb.group({
      nameFR: ["", Validators.required],
      nameEN: ["", Validators.required],
      nameAR: ["", Validators.required]
    });
  }
  ngOnInit() {
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.initForm();
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  addReportType() {
    this.reportTypeService.addReportType(this.categoryForm.value).subscribe(
      data => {
        this.openModal('custom-modal-confirm-add');
        window.location.reload();
      },
      error => {
        console.log(error);
      }
    );
  }
}
