import { Injectable } from '@angular/core';

/**
 * Local data Service
 */
@Injectable()
export class LocalService {

    /**
     * Constructor
     */
    constructor(){
    }
    /**
     * renvoyer la langue actuellement utilisée
     */
    public i18n() : string {
        if (localStorage.getItem("setLanguage") != "true"){
            var language = navigator.language;
            const en = "en";
            const ar = "ar";
            const fr = "fr";
            if (language.includes(en)) {
                localStorage.setItem("i18n", en);
            }
            else if (language.includes(ar)) {
                localStorage.setItem("i18n", ar);
            }
            if (language.includes(fr)) {
                localStorage.setItem("i18n", fr);
            }
        }
        var langage = localStorage.getItem("i18n");
        return  ("" != langage && null != langage)? langage : "fr";
    }
}
