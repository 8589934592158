import { Component, OnInit } from '@angular/core';
import { QuestionService } from '../../../services/question.service';
import { ModalService } from '../../../common-elements/_modal';
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from 'src/app/pojo/Question';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-list-question',
  templateUrl: './list-question.component.html',
  styleUrls: ['./list-question.component.css']
})
export class ListQuestionComponent extends GenericComponent implements OnInit {
  faq: Question;

  questions: any[];
  questionId: any;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  p1: number = 1;
  add = false;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: ' ',
    translate: 'no',
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  updatedquestionId: any ;
  updatedfaq:Question
  isTeenager: boolean;
  constructor(private router: Router, private token: TokenStorageService, private modalService: ModalService, private questionService: QuestionService, private localService: LocalService, private i18NService: I18NService,private route: ActivatedRoute) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];

  }

  ngOnInit() {
    this.updatedfaq = new Question();
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.route.params.subscribe(params => {
      this.isTeenager = params.isteenager ;
    });
    if (this.router.url == "/blog/children/isteenager/" + this.isTeenager) {
      this.questionService.getAllChildQuestions(this.i18n,this.isTeenager).subscribe(
        data =>{
          this.questions = data;
        },
        error => {
          console.log(error);
        }
      )
    }
    if (this.router.url == "/post/posts") {
      this.questionService.getAllAdultQuestions(this.i18n).subscribe(
        data =>{
          this.questions = data;
        },
        error => {
          console.log(error);
        }
      )
    }
    if (this.router.url == "/administration" || this.router.url == "/list-question" )  {
      this.questionService.getAllQuestions(this.i18n).subscribe(
        data =>{
          this.questions = data;
        },
        error => {
          console.log(error);
        }
      )
    }
  }
  openModal(id: string) {
    this.modalService.open(id);

  }
  closeModal(id: string) {
    this.modalService.close(id);

  }
  openAdd(id) {
    this.add = !this.add;
   this.openModal('custom-modal-add-question');

  }

  confirmDelete(questionId) {
    this.openModal('custom-modal-delete-question');
    this.questionId = questionId;
  }
  deleteQuestion() {
    this.questionService.deleteQuestion(this.questionId).subscribe(
      data => {
        this.modalService.close('custom-modal-delete-question');
        window.location.reload();
      },
      error => {
        console.log(error);
      }
    );
  }


  openEdit(questionId) {

    this.updatedquestionId = questionId ;
       this.questionService.getQuestionById(questionId).subscribe(
      data => {

        this.updatedfaq = data;
        this.openModal('custom-modal-edit-question');
      },
      error => {
        console.log(error);
      }
    );
  }
  editQuestion(){
    this.questionService.updateQuestion(this.updatedquestionId , this.updatedfaq).subscribe(
      data => {

      this.closeModal('custom-modal-edit-question');
      window.location.reload();

      },
      error => {
        console.log(error);
      }
    );
  }
}
