import { Component, Input, OnInit } from '@angular/core';
import { ServiceService } from "../../../services/service.service";
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ServiceSubCategoryService } from '../../../services/service-sub-category.service';
import { ServiceCategoryService } from '../../../services/service-category.service';
import { ModalService } from '../../../common-elements/_modal';

@Component({
  selector: 'app-list-service',
  templateUrl: './list-service.component.html',
  styleUrls: ['./list-service.component.css']
})
export class ListServiceComponent extends GenericComponent implements OnInit {
  @Input() servicesMap: any;
  services: any[];
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  serviceName: string;
  regions: any[];
  region: string;
  allServices: any;
  categServices: any[];
  subCategServices: any[];
  serviceId: number;
  showService: boolean = false;
  idServiceToShow: any;
  categoryId: any;
  subCategId: any;
  constructor(private token: TokenStorageService, private serviceService: ServiceService, private localService: LocalService, private i18NService: I18NService,
    private servCategoryService: ServiceCategoryService, private servSubCategoryService: ServiceSubCategoryService, private modalService: ModalService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.serviceService.getAllServices(this.i18n).subscribe((services) => {
      this.services = services;
      this.allServices = services;
    },
      error => {
        console.log(error);
      });
    this.regions = [this.appsUI.Ariana[this.i18n], this.appsUI.Beja[this.i18n], this.appsUI.BenArous[this.i18n]
      , this.appsUI.Bizerte[this.i18n], this.appsUI.Gabès[this.i18n], this.appsUI.Gafsa[this.i18n], this.appsUI.Jendouba[this.i18n]
      , this.appsUI.Kairouan[this.i18n], this.appsUI.Kasserine[this.i18n], this.appsUI.Kebili[this.i18n], this.appsUI.Kef[this.i18n]
      , this.appsUI.Mahdia[this.i18n], this.appsUI.Manouba[this.i18n], this.appsUI.Médenine[this.i18n], this.appsUI.Monastir[this.i18n]
      , this.appsUI.Nabeul[this.i18n], this.appsUI.Sfax[this.i18n], this.appsUI.SidiBouzid[this.i18n], this.appsUI.Siliana[this.i18n]
      , this.appsUI.Sousse[this.i18n], this.appsUI.Tataouine[this.i18n], this.appsUI.Tozeur[this.i18n], this.appsUI.Tunis[this.i18n]
      , this.appsUI.Zaghouan[this.i18n], this.appsUI.everywhere[this.i18n]];
    this.getAllCategService();
  }
  applyFilter(filterValue: any) {
    this.services = this.allServices;
    this.showService = false;
    this.serviceName = filterValue;
    if (filterValue === '' || filterValue === ' ') {
      this.services = this.allServices;
    } else {
      let filterValueLower = filterValue.trimEnd().toString().toLowerCase();
      this.services = this.services.filter((employee) => employee.establishment.toLowerCase().includes(filterValueLower));
    }
  }

  searchFilter(event) {
    this.showService = false;
    this.region = event;
      if (this.region === this.appsUI.everywhere[this.i18n]) {
        this.serviceService.getAllServices(this.i18n).subscribe((services) => {
          this.services = services;
        },
          error => {
            console.log(error);
          });
      } else if (this.region && (this.serviceName == undefined || this.serviceName == "" )) {
        this.serviceService.getServiceByRegion(this.region, this.i18n).subscribe((res) => {
          this.services = res;
        })
      }
      else if (this.region === this.appsUI.everywhere[this.i18n] && this.serviceName) {
        this.serviceService.getServiceByTitle((this.serviceName)).subscribe((res) => {
          this.services = res;
        })
      }
      else if (this.region !== this.appsUI.everywhere[this.i18n] && this.serviceName) {
        this.serviceService.getServiceByTitleAndCityNme(this.serviceName,this.region).subscribe((res) => {
          this.services = res;
        })
      }
      else if (this.region !== this.appsUI.everywhere[this.i18n] && this.serviceName && this.categoryId) {
        this.serviceService.getServiceByCategAndTitleAndRegion(this.categoryId, this.serviceName,this.region,this.i18n).subscribe(
          (res) => {
            this.services = res;
          }
        )
      }
      else if (this.region !== this.appsUI.everywhere[this.i18n] && this.serviceName && this.categoryId && this.subCategId) {
        this.serviceService.getServiceByCategAndTitleAndRegionAndSubCateg(this.categoryId, this.serviceName,this.region,this.subCategId,this.i18n).subscribe(
          (res) => {
            this.services = res;
          }
        )
      }
      else {
        this.serviceService.getServiceByTitleAndCityNme(this.serviceName, this.region).subscribe((res) => {
          this.services = res;
        })
      }
  }


  translateRegionTitle(regionTitle) {
    if (regionTitle == 'Bizerte') {
      return 'بنزرت';
    }
    if (regionTitle == 'Manouba') {
      return 'منوبة';
    }
    if (regionTitle == 'Nabeul') {
      return 'نابل';
    }
    if (regionTitle == 'Ben Arous') {
      return 'بن عروس';
    }
    if (regionTitle == 'Zaghouan') {
      return 'زغوان';
    }
    if (regionTitle == 'Jendouba') {
      return 'جندوبة';
    }
    if (regionTitle == 'Kef') {
      return 'الكاف';
    }
    if (regionTitle == 'Siliana') {
      return 'سليانة';
    }
    if (regionTitle == 'Béja') {
      return 'باجة';
    }
    if (regionTitle == 'Sousse') {
      return 'سوسة';
    }
    if (regionTitle == 'Monastir') {
      return 'المنستير';
    }
    if (regionTitle == 'Mahdia') {
      return 'المهدية';
    }
    if (regionTitle == 'Sfax') {
      return 'صفاقس';
    }
    if (regionTitle == 'Sidi Bouzid') {
      return 'سيدي بوزيد';
    }
    if (regionTitle == 'Kasserine') {
      return 'القصرين';
    }
    if (regionTitle == 'Gafsa') {
      return 'قفصة';
    }
    if (regionTitle == 'Tozeur') {
      return 'توزر';
    }
    if (regionTitle == 'Kébili') {
      return 'قبلي';
    }
    if (regionTitle == 'Gabès') {
      return 'قابس';
    }
    if (regionTitle == 'Médenine') {
      return 'مدنين';
    }
    if (regionTitle == 'Kairouan') {
      return 'القيروان';
    }
    if (regionTitle == 'Tunis') {
      return 'تونس';
    }
    if (regionTitle == 'Tataouine') {
      return 'تطاوين';
    }
    if (regionTitle == 'Ariana') {
      return 'أريانة';
    }
  }
  getServiceByRegion(cityName: any) {
    this.showService = false;
    if (this.i18n === "ar") {
      this.serviceService.getServiceByRegion(this.translateRegionTitle(cityName), this.i18n).subscribe((res => {
        this.services = res;
      }))
    } else {
      this.serviceService.getServiceByRegion(cityName, this.i18n).subscribe((res => {
        this.services = res;
      }))
    }
  }
  getAllCategService() {
    this.servCategoryService.getAllServicesCategoriesPic(this.i18n).subscribe(
      (data) => {
        this.categServices = data;
      },
      (err) => { }
    )
  }
  getAllSubCategService(id) {
    this.servSubCategoryService.getAllSubCategByCategId(id, this.i18n).subscribe(
      (data) => {
        this.subCategServices = data;
      },
      (err) => { }
    )
  }
  openModalDelete(typeModal: string, idService: number) {
    this.modalService.open(typeModal);
    this.serviceId = idService;
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  confirmDelete() {
    this.serviceService.deleteService(this.serviceId).subscribe((data) => {
      window.location.reload();
    },
      error => {
        console.log(error);
      });
  }
  showServiceDetails(id) {
    this.showService = true;
    this.idServiceToShow = id;
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
}
  searchFilterPerSubCateg(idSubCateg) {
    this.showService = false;
    this.subCategId = idSubCateg;
    if (idSubCateg === "all") {
      this.serviceService.getServiceByCategoryId(this.categoryId).subscribe(
        (res) => {
          this.services = res;
        }
      )
    }
    else {
      if (this.subCategId && this.categoryId && (this.region == this.appsUI.everywhere[this.i18n] || this.region == undefined) && (this.serviceName == undefined || this.serviceName == "" )) {
        this.serviceService.getServiceByCategoryAndSubCateg(this.subCategId).subscribe(
          (res) => {
            this.services = res;
          }
        )
      }
      else if (this.region != undefined && this.subCategId && this.categoryId && (this.serviceName == undefined || this.serviceName == "" ) ) {
        this.serviceService.getServiceBySubCategAndCity(this.subCategId, this.categoryId,this.region,this.i18n).subscribe(
          (res) => {
            this.services = res;
          }
        )
      }
      else if ((this.serviceName != undefined && this.serviceName != "") && this.subCategId && this.categoryId && this.region == undefined) {
        this.serviceService.getServiceBySubCategAndTitle(this.subCategId,this.categoryId,this.serviceName).subscribe(
          (res) => {
            this.services = res;
          }
        )
      }
      else if (this.region != undefined && (this.serviceName != undefined && this.serviceName != "") && this.categoryId && this.subCategId == undefined) {
        this.serviceService.getServiceByCategAndTitleAndRegion(this.categoryId, this.serviceName, this.region,this.i18n).subscribe(
          (res) => {
            this.services = res;
          }
        )
      }
      else if (this.region != undefined && (this.serviceName != undefined && this.serviceName != "") && this.categoryId && this.subCategId) {
        this.serviceService.getServiceByCategAndTitleAndRegionAndSubCateg(this.categoryId, this.serviceName, this.region, this.subCategId,this.i18n).subscribe(
          (res) => {
            this.services = res;
          }
        )
      }
      else {
        this.serviceService.getServiceByCategoryAndSubCateg(this.categoryId).subscribe(
          (res) => {
            this.services = res;
          }
        )
      }
    }
  }
  searchFilterPerCateg(id) {
    this.showService = false;
    this.categoryId = id;
    this.getAllSubCategService(id);

    if (this.categoryId && (this.region == this.appsUI.everywhere[this.i18n] || this.region == undefined) && (this.serviceName == undefined || this.serviceName == "")&& this.subCategId == undefined) {
      this.serviceService.getServiceByCategoryId(this.categoryId).subscribe(
        (res) => {
          this.services = res;
        }
      )
    }
    else if (this.region != undefined && this.region != this.appsUI.everywhere[this.i18n] && this.categoryId != undefined && (this.serviceName == undefined || this.serviceName == "") && this.subCategId == undefined) {
      this.serviceService.getServiceByCategAndRegion(this.region, this.categoryId,this.i18n).subscribe(
        (res) => {
          this.services = res;
        }
      )
    }
    else if ((this.serviceName != undefined && this.serviceName != "") && this.categoryId && this.region == undefined && this.subCategId == undefined) {
      this.serviceService.getServiceByCategAndTitle(this.categoryId, this.serviceName).subscribe(
        (res) => {
          this.services = res;
        }
      )
    }
    else if (this.region != undefined && this.region != this.appsUI.everywhere[this.i18n] && (this.serviceName != undefined && this.serviceName != "") && this.categoryId && this.subCategId == undefined) {
      this.serviceService.getServiceByCategAndTitleAndRegion(this.categoryId, this.serviceName, this.region,this.i18n).subscribe(
        (res) => {
          this.services = res;
        }
      )
    }
    else if (this.region != undefined && this.region != this.appsUI.everywhere[this.i18n] && (this.serviceName != undefined && this.serviceName != "") && this.categoryId && this.subCategId) {
      this.serviceService.getServiceByCategAndTitleAndRegionAndSubCateg(this.categoryId, this.serviceName, this.region, this.subCategId,this.i18n).subscribe(
        (res) => {
          this.services = res;
        }
      )
    }
    else {
      this.serviceService.getServiceByCategoryId(this.categoryId).subscribe(
        (res) => {
          this.services = res;
        }
      )
    }
  }
  searchPerAllCateg() {
    this.showService = false;
    if((this.serviceName != undefined && this.serviceName != "")){
      this.serviceService.getServiceByTitle(this.serviceName).subscribe(
        (services) => {
          this.services = services;
          this.subCategServices = [];
        },
        error => { console.log(error); }
      );
    }
    else if(this.region != undefined){
      this.serviceService.getServiceByRegion(this.region, this.i18n).subscribe((res) => {
        this.services = res;
      })
    }
    else{
      this.serviceService.getAllServices(this.i18n).subscribe(
        (services) => {
          this.services = services;
          this.subCategServices = [];
        },
        error => { console.log(error); }
      );
    }
  }
}
