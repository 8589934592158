export class Report {
  reportId: number;
  name: string;
  childname:string;
  childgender:string;
  parentphone:string;
  phone:string;
  email: string;
  address: string;
  governorate: string;
  message: string;
  state: string;
  reportTypeId: number;
  minAge: number ;
  maxAge: number ;
  videoLink: string;
  file: string;

  constructor() {
  }
}
