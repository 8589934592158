import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18NService } from '../../../services/I18NService';
import { Post } from '../../../pojo/Post';
import { PostService } from '../../../services/post.service';

@Component({
  selector: 'app-detail-post',
  templateUrl: './detail-post.component.html',
  styleUrls: ['./detail-post.component.css']
})
export class DetailPostComponent implements OnInit {

  id: number;
  post: Post;
  appsUI: any = {};
  url = 'https://dpe.tn/post/detail-post/';

  constructor(private route: ActivatedRoute, private postService: PostService, private i18NService: I18NService) { 
    this.appsUI = this.i18NService.ui().apps;
  }

  ngOnInit() {
    this.getPostById();
  }
  getPostById() {
    this.id = this.route.snapshot.params.id;
    this.postService.getPostById(this.id).subscribe(data => { this.post = data; });
  }
  likePost(postId: any) {
    this.postService.getPostById(postId).subscribe((post) => {
      this.post = post;
      this.post.isLiked = true;
      this.post.nbr_likes++;
      this.post.cover = post.cover;
      this.postService.updatePost(postId, this.post).subscribe(data => {
        this.postService.getPostById(this.id).subscribe(postData => { this.post = postData; });
      });

    });
  }
  getUrl(id) {
    return 'https://www.facebook.com/sharer/sharer.php?u=' + this.url + id + '/&amp;src=sdkpreparse';
  }
}
