import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ContactFormService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/contactform' ;
    }
    sendContactForm(contactForm: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API+ "/public", contactForm);
    }
    getAllEmails(): Observable<any> {
       return this.http.get(this.DCAF_WS_API );
    }
    delete(id: number) {
       const url = `${this.DCAF_WS_API}/${id}`;
       return this.http.delete(url);
    }
    getEmailById(id: any): Observable<any> {
      return this.http.get(this.DCAF_WS_API +"/"+ id);
   }

}
