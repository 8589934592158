export class Service {
  establishment : string;
  serviceId: number;
  title: string;
  address: string;
  tel: string;
  mail: string;
  cityName: string;
  lang:string;
  fax:any;
  website:any;
  missions:any;
  beneficiaries:any;
  services:any;
  partners:any;
  geographiccoverage:any;
  greenline:any;
  categoryId: number;
  subCategoryId: number;
  fileUrl: any;

  constructor() {
  }
}
