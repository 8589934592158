import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { i18n } from "../i18n";
import { I18NService } from "./services/I18NService";
import { LocalService } from "./services/LocalService";
import { FooterComponent } from "./common-elements/footer/footer.component";
import { NavbarComponent } from "./common-elements/navbar/navbar.component";
import { config } from "../config";
import { HttpClientModule } from "@angular/common/http";
import { AddQuestionComponent } from "./components/question/add-question/add-question.component";
import { EditQuestionComponent } from "./components/question/edit-question/edit-question.component";
import { ListQuestionComponent } from "./components/question/list-question/list-question.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AddPostComponent } from "./components/blog/add-post/add-post.component";
import { EditPostComponent } from "./components/blog/edit-post/edit-post.component";
import { ListPostComponent } from "./components/blog/list-post/list-post.component";
import { AddContactComponent } from "./components/contact/add-contact/add-contact.component";
import { EditContactComponent } from "./components/contact/edit-contact/edit-contact.component";
import { DisplayContactComponent } from "./components/contact/display-contact/display-contact.component";
import { NotifierModule } from "angular-notifier";
import { CustomNotificationComponent } from "./components/notification/custom-notification/custom-notification.component";
import { ModalModule } from "./common-elements/_modal";
import { AddServiceComponent } from "./components/service/add-service/add-service.component";
import { EditServiceComponent } from "./components/service/edit-service/edit-service.component";
import { ListServiceComponent } from "./components/service/list-service/list-service.component";
import { SendContactFormComponent } from "./components/contact-form/send-contact-form/send-contact-form.component";
import { ServiceOutletComponent } from "./components/service/service-outlet/service-outlet.component";
import { PostOutletComponent } from "./components/blog/post-outlet/post-outlet.component";
import { ListEmailsComponent } from "./components/contact-form/list-emails/list-emails.component";
import { AddUserComponent } from "./components/signup/add-user/add-user.component";
import { LoginComponent } from "./components/signin/login/login.component";
import { AddCategoryComponent } from "./components/categoryBlog/add-category/add-category.component";
import { AddDocCategoryComponent } from "./components/categoryDoc/add-doc-category/add-doc-category.component";
import { ListDocCategoryComponent } from "./components/categoryDoc/list-doc-category/list-doc-category.component";
import { EditDocCategoryComponent } from "./components/categoryDoc/edit-doc-category/edit-doc-category.component";
import { EditCategoryComponent } from "./components/categoryBlog/edit-category/edit-category.component";
import { AddAboutComponent } from "./components/about/add-about/add-about.component";
import { PermissionComponent } from "./common-elements/permission/permission.component";
import { UploadFileComponent } from "./common-elements/files/upload-file/upload-file.component";
import { AddReportTypeComponent } from "./components/report-type/add-report-type/add-report-type.component";
import { ListReportTypeComponent } from "./components/report-type/list-report-type/list-report-type.component";
import { EditReportTypeComponent } from "./components/report-type/edit-report-type/edit-report-type.component";
import { ListReportComponent } from "./components/report/list-report/list-report.component";
import { AddReportComponent } from "./components/report/add-report/add-report.component";
import { AddDocumentComponent } from "./components/document/add-document/add-document.component";
import { ListDocumentComponent } from "./components/document/list-document/list-document.component";
import { AddAccountComponent } from "./components/account/add-account/add-account.component";
import { ListAccountComponent } from "./components/account/list-account/list-account.component";
import { EditAccountComponent } from "./components/account/edit-account/edit-account.component";
import { SuggestionServiceComponent } from "./components/service/suggestion-service/suggestion-service.component";
import { SuggestionListComponent } from "./components/service/suggestion-list/suggestion-list.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { SidebarComponent } from "./common-elements/sidebar/sidebar.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgxPaginationModule } from "ngx-pagination";
import { NotifierOptions } from "angular-notifier";
import { NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AboutUsComponent } from "./components/about/about-us/about-us.component";
import { ServiceCardComponent } from "./components/service/service-card/service-card.component";
import { HomeComponent } from "./components/home/home.component";
import { ChildrenComponent } from "./components/blog/children/children.component";
import { AddHomeComponent } from "./components/home/add-home/add-home.component";
import { MatRadioModule } from "@angular/material/radio";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import {SafePipe} from "./components/document/list-document/SafePipe";
import { DetailPostComponent } from './components/blog/detail-post/detail-post.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatBadgeModule, MatChipList, MatChipsModule, MatDialogModule, MatGridListModule, MatStepperModule } from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { EditUserComponent } from './components/signup/edit-user/edit-user.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReportChoiceComponent } from './components/report-choice/report-choice.component';
import { ChildrenChoiceComponent } from './components/children-choice/children-choice.component';

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: "left",
      distance: 12,
    },
    vertical: {
      position: "bottom",
      distance: 12,
      gap: 10,
    },
  },
  theme: "material",
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: "pauseAutoHide",
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: "slide",
      speed: 300,
      easing: "ease",
    },
    hide: {
      preset: "fade",
      speed: 300,
      easing: "ease",
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: "ease",
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    FooterComponent,
    NavbarComponent,
    AddQuestionComponent,
    EditQuestionComponent,
    ListQuestionComponent,
    AddContactComponent,
    EditContactComponent,
    DisplayContactComponent,
    CustomNotificationComponent,
    AddPostComponent,
    EditPostComponent,
    ListPostComponent,
    AddServiceComponent,
    EditServiceComponent,
    SendContactFormComponent,
    ListServiceComponent,
    ServiceOutletComponent,
    PostOutletComponent,
    ListEmailsComponent,
    AddUserComponent,
    LoginComponent,
    AddCategoryComponent,
    AddDocCategoryComponent,
    ListDocCategoryComponent,
    EditDocCategoryComponent,
    EditCategoryComponent,
    AddAboutComponent,
    PermissionComponent,
    AddReportTypeComponent,
    ListReportTypeComponent,
    EditReportTypeComponent,
    ListReportComponent,
    AddReportComponent,
    UploadFileComponent,
    AddDocumentComponent,
    ListDocumentComponent,
    AddAccountComponent,
    ListAccountComponent,
    EditAccountComponent,
    ListDocumentComponent,
    SuggestionServiceComponent,
    SuggestionListComponent,
    SidebarComponent,
    AboutUsComponent,
    ServiceCardComponent,
    HomeComponent,
    ChildrenComponent,
    AddHomeComponent,
    DetailPostComponent,
    EditUserComponent,
    ReportChoiceComponent,
    ChildrenChoiceComponent
  ],
  imports: [
    MatCheckboxModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NotifierModule,
    ModalModule,
    AngularEditorModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    MatRadioModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    FontAwesomeModule,
    MatProgressBarModule,
    NotifierModule.withConfig(customNotifierOptions),
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatGridListModule,
    FlexLayoutModule,
    MatBadgeModule,
    MatChipsModule,
    MatDialogModule,
    MatStepperModule

  ],
  providers: [
    I18NService,
    LocalService,
    { provide: "conf", useValue: config.ws_url },
    { provide: "siconf", useValue: config.ws_si_url },
    { provide: "uiconf", useValue: config.ui_url },
    { provide: "config", useValue: config },
    { provide: "username", useValue: config.username },
    { provide: "password", useValue: config.password },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: "i18n", useValue: i18n },
  ],
  bootstrap: [AppComponent],
  entryComponents:[ReportChoiceComponent,AddReportComponent]
})
export class AppModule {}
