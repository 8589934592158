import { Component, OnInit } from '@angular/core';
import { ContactFormService } from '../../../services/contact-form.service';
import {ContactForm} from "../../../pojo/ContactForm";
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ModalService } from '../../../common-elements/_modal';

@Component({
  selector: 'app-list-emails',
  templateUrl: './list-emails.component.html',
  styleUrls: ['./list-emails.component.css']
})
export class ListEmailsComponent extends GenericComponent implements OnInit {

  emails: any;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  emailId : number ;
  reportMessage :any;

  constructor(private modalService: ModalService, private token: TokenStorageService, private contactFormService : ContactFormService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.contactFormService.getAllEmails().subscribe(
      data => {
        this.emails = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  confirmDelete(formId){
    this.emailId = formId;
    this.openModal('custom-modal-delete-email');
  }
  deleteEmail(){
    this.contactFormService.delete(this.emailId).subscribe(
      data => {
        this.emails = data;
        this.closeModal('custom-modal-delete-email');
      },
      error => {
        console.log(error);
      }
    );
  }
  detailsContactFormMessage(id) {
    this.contactFormService.getEmailById(id).subscribe(
      data => {
      this.reportMessage= data.message;
      this.openModal('custom-modal-show-contact-message');
      },
      error => {
        console.log(error);
      }
    );

  }
}
