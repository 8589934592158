import { Injectable, Inject } from '@angular/core';

@Injectable()
export class I18NService {

    private I18N_VALUES : any  = {};
    /**
     * Constructor
     * @param i18n
     */
    constructor(@Inject('i18n') private i18n : any) {
        this.I18N_VALUES = i18n;
    }

    public ui() : any{
        return this.I18N_VALUES;
    }
}