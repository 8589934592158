import {Component, OnInit} from '@angular/core';
import {Service} from "../../../pojo/Service";
import {ServiceService} from "../../../services/service.service";
import {ActivatedRoute} from "@angular/router";
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule
} from "@angular/forms";
import { ModalService } from "../../../common-elements/_modal";
import { Router } from "@angular/router";
import { ServiceSubCategoryService } from '../../../services/service-sub-category.service';
import { ServiceCategoryService } from '../../../services/service-category.service';
import { UploadFileService } from '../../../services/upload-file.service';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.css']
})
export class EditServiceComponent extends GenericComponent implements OnInit {
  service: Service;
  serviceId: any;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  serviceForm: FormGroup;
  regions: any[];
  chosenItem : string ;
  categServices:any[];
  subCategServices:any[];
  submittedServiceFrm: boolean = false;
  mode: string = "indeterminate";
  selectedFiles: FileList;
  visible: boolean = false;
  currentFile: File;

  constructor(private router: Router, private modalService: ModalService, private token: TokenStorageService,private fb: FormBuilder,  private serviceService: ServiceService, private route: ActivatedRoute, private localService: LocalService, private i18NService: I18NService,
    private servCategoryService:ServiceCategoryService,private servSubCategoryService:ServiceSubCategoryService,private uploadService: UploadFileService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  initForm() {
    this.serviceForm = this.fb.group({
      title: [""],
      address: [""],
      cityName: [""],
      tel: [""],
      mail: [""],
      fax: [""],
      website: [""],
      workspace: [""],
      missions: [""],
      beneficiaries: [""],
      establishment: [""],
      services: [""],
      partners: [""],
      geographiccoverage: [""],
      greenline: [""],
      lang: [""],
      categoryId: ["", Validators.required],
      subCategoryId: ["", Validators.required]
    });
  }

  ngOnInit() {
    this.regions = [this.appsUI.Ariana[this.i18n], this.appsUI.Beja[this.i18n], this.appsUI.BenArous[this.i18n]
    , this.appsUI.Bizerte[this.i18n], this.appsUI.Gabès[this.i18n], this.appsUI.Gafsa[this.i18n], this.appsUI.Jendouba[this.i18n]
    , this.appsUI.Kairouan[this.i18n], this.appsUI.Kasserine[this.i18n], this.appsUI.Kebili[this.i18n], this.appsUI.Kef[this.i18n]
    , this.appsUI.Mahdia[this.i18n], this.appsUI.Manouba[this.i18n], this.appsUI.Médenine[this.i18n], this.appsUI.Monastir[this.i18n]
    , this.appsUI.Nabeul[this.i18n], this.appsUI.Sfax[this.i18n], this.appsUI.SidiBouzid[this.i18n], this.appsUI.Siliana[this.i18n]
    , this.appsUI.Sousse[this.i18n], this.appsUI.Tataouine[this.i18n], this.appsUI.Tozeur[this.i18n], this.appsUI.Tunis[this.i18n]
    , this.appsUI.Zaghouan[this.i18n], this.appsUI.everywhere[this.i18n]];
    this.initForm();
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.route.params.subscribe(params => {
      this.serviceId = params['id'];
      this.serviceService.getServiceById(this.serviceId,this.i18n).subscribe((service) => {
        this.service = service;
        this.serviceForm.get("title").setValue(service.title);
        this.serviceForm.get("address").setValue(service.address);
        this.serviceForm.get("cityName").setValue(service.cityName);
        this.serviceForm.get("tel").setValue(service.tel);
        this.serviceForm.get("mail").setValue(service.mail);
        this.serviceForm.get("fax").setValue(service.fax);
        this.serviceForm.get("website").setValue(service.website);
        this.serviceForm.get("workspace").setValue(service.workspace);
        this.serviceForm.get("missions").setValue(service.missions);
        this.serviceForm.get("beneficiaries").setValue(service.beneficiaries);
        this.serviceForm.get("establishment").setValue(service.establishment);
        this.serviceForm.get("services").setValue(service.services);
        this.serviceForm.get("partners").setValue(service.partners);
        this.serviceForm.get("geographiccoverage").setValue(service.geographiccoverage);
        this.serviceForm.get("greenline").setValue(service.greenline);
        this.chosenItem = service.lang;
        this.serviceForm.get("lang").setValue(service.lang);
        this.serviceForm.get("categoryId").setValue(service.categoryId);
        this.serviceForm.get("subCategoryId").setValue(service.subCategoryId);
      });
    });
    this.getAllCategService();
    this.getAllSubCategService();
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  updateService() {
    this.submittedServiceFrm = true;
    if (this.serviceForm.valid) {
      this.serviceService.updateService(this.serviceId, this.serviceForm.value).subscribe((res) => {

        this.submittedServiceFrm = false;
          if (this.selectedFiles != null) {
            this.updateUpload(res.serviceId,"service-doc");
          }
          else {
            this.openModal('custom-modal-confirm-add');
          }
      }, error => { })
    }
  }
  getAllCategService(){
    this.servCategoryService.getAllServicesCategoriesPic(this.i18n).subscribe(
      (data)=>{
          this.categServices = data;
      },
      (err) =>{

      }
    )

  }
  getAllSubCategServiceById(id){
    this.servSubCategoryService.getAllSubCategByCategId(id,this.i18n).subscribe(
      (data)=>{
          this.subCategServices = data;
      }, (err) =>{}
    )
  }
  getAllSubCategService(){
    this.servSubCategoryService.getAllServicesSubCategories().subscribe((res)=>{this.subCategServices = res;})
  }

  activateProgressBar() {
    this.mode = "determinate";
  }
  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }

  updateUpload(idRs,typeRs): void {
    this.currentFile = this.selectedFiles.item(0);
    const formData = new FormData();
    formData.append("targetType", typeRs);
    formData.append("targetId", JSON.stringify(idRs));
    formData.append("file", this.currentFile);
    this.uploadService.udpateFileCateg(formData).subscribe(
      (res) => {
        if (res) {
          this.visible = false;
          this.mode = "indeterminate";
          this.selectedFiles = null;
          this.openModal('custom-modal-confirm-add');
        }
      },
      (err) => { }
    );
}
}
