import { Component, OnInit } from '@angular/core';
import { ModalService } from "../_modal";
import { GenericComponent } from "../../GenericComponent";
import { Router } from "@angular/router";
import { TokenStorageService } from "../../services/token-storage.service";
import { LocalService } from "../../services/LocalService";
import { I18NService } from "../../services/I18NService";
import { Contact } from "../../pojo/Contact";
import { ContactService } from '../../services/contact.service';
import { ContactForm } from "../../pojo/ContactForm";
import { ContactFormService } from '../../services/contact-form.service';
import { approvalService } from '../../services/approval.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends GenericComponent implements OnInit {
  appsUI: any = {};
  isLoggedIn: boolean;
  isAdmin: boolean;
  roles: string[] = [];
  currentUser: any = {};
  contact: Contact;
  contactForm: ContactForm;
  currentYear: any = new Date("2020");

  constructor(private contactFormService: ContactFormService, private contactService: ContactService, private router: Router, private tokenStorageService: TokenStorageService,
     private localService: LocalService, private i18NService: I18NService, private modalService: ModalService,private appService:approvalService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.contact = new Contact();
    this.contactForm = new ContactForm();
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if (this.tokenStorageService.getToken()) {
      this.roles = this.tokenStorageService.getUser().roles;
      this.currentUser = this.tokenStorageService.getUser();
    }
    if (this.tokenStorageService.getRememberMe()) {
      this.roles = this.tokenStorageService.getUserRememberMe().roles;
      this.currentUser = this.tokenStorageService.getUserRememberMe();
      this.isLoggedIn = true;

    }
    /*this.appService.currentApprovalStageState.subscribe(state => {
      console.log("triggered in navbar"+state);
      this.isLoggedIn = state;
      this.isAdmin = state;
    });*/
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.contactService.getContact(this.i18n).subscribe(
      data => {
        this.contact = data[0];
      },
      error => {
        console.log(error);
      }
    );
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  sendContactEmail() {
    setTimeout(() => {
      this.openModal('custom-modal-confirm-send');
    }, 2000);
    this.contactFormService.sendContactForm(this.contactForm).subscribe(
      data => {
        //this.openModal('custom-modal-confirm-send');
      },
      error => {
        console.log(error);
      }
    );
  }
}
