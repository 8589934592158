import { Component, OnInit } from '@angular/core';
import { LocalService } from '../../services/LocalService';
import { I18NService } from '../../services/I18NService';
import { GenericComponent } from '../../GenericComponent';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddReportComponent } from '../report/add-report/add-report.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-choice',
  templateUrl: './report-choice.component.html',
  styleUrls: ['./report-choice.component.css']
})
export class ReportChoiceComponent extends GenericComponent implements OnInit {

  appsUI: any = {};
  constructor(private localService: LocalService,private i18NService: I18NService,
    public dialogRef: MatDialogRef<ReportChoiceComponent>,public dialog: MatDialog,private route: Router) { 
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
  }

  closeModal(){
    this.dialogRef.close();
  }

  goToReport(){
    this.dialogRef.close();
    this.route.navigate(['/add-report']);
  }
}
