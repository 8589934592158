export class Post {
  postId: number;
  title: string;
  content: string;
  createdDate: number;
  categoryId: number;
  nbr_likes: number;
  forChildren: boolean;
  childCategoryId: number;
  cover: any;
  isLiked = false;
  lang: string;
  file: any;
  isPdf : boolean =false;
  isDoc : boolean =false;
  isImage : boolean = false;
  isLarge: boolean;
  isTeenager: boolean;
  videoLink: string;
  videoType: string ;
  constructor() {
  }

}
