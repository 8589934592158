import { Component, OnInit } from '@angular/core';
import { UserCredential } from "../../../pojo/UserCredential";
import { UserData } from "../../../pojo/UserData";
import { UserService } from '../../../services/user.service';
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { TokenStorageService } from "../../../services/token-storage.service";
import { ModalService } from "../../../common-elements/_modal";
import { Router } from "@angular/router";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})

export class EditUserComponent extends GenericComponent implements OnInit {

  userCredential: UserCredential;
  userData: UserData;
  confirmPassword: String;
  appsUI: any = {};
  currentUser: any = {};
  changePasswordFailed: boolean = false;
  wrongPassword: boolean = false;
  wrongPasswordUsername: boolean = false;

  constructor(private router: Router, private modalService: ModalService, private token: TokenStorageService, private userService: UserService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.userCredential = new UserCredential();
    this.userData = new UserData();
    if (this.token.getToken()) {
      this.currentUser = this.token.getUser();
    }
  }
  changeUsername() {
    this.userService.updateUsername(this.userData, this.currentUser.id).subscribe(
      data => {
        if (data.updateUsername === "true") {
          this.wrongPasswordUsername = false;
          this.modalService.open("custom-modal-confirm-add");
          this.logout();
        }
        else {
          if (data.error === "password doesn't match") {
            this.wrongPasswordUsername = true;
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  changePassword() {
    if (this.confirmPassword !== this.userCredential.newPassword) {
      this.changePasswordFailed = true;
    }
    else {
      this.changePasswordFailed = false;
      this.userService.updatePassword(this.userCredential, this.currentUser.id).subscribe(
        data => {
          if (data.updatePassword === "true") {
            this.wrongPassword = false;
            this.modalService.open("custom-modal-confirm-add");
            this.logout();
          }
          else {
            if (data.error === "password doesn't match") {
              this.wrongPassword = true;
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  logout() {
    this.token.signOut();
    this.router.navigate(['/home'])
      .then(() => {
        window.location.reload();
      }
      );
  }
}
