import { Component, OnInit } from '@angular/core';
import { ViewChild } from "@angular/core";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-custom-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.css']
})
export class CustomNotificationComponent  implements OnInit {

    constructor() {}

    ngOnInit() {}
}
