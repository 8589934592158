import { Component, OnInit } from '@angular/core';
import {LocalService} from "../../services/LocalService";
import {Router} from "@angular/router";
import {I18NService} from "../../services/I18NService";
import {GenericComponent} from "../../GenericComponent";
import {TokenStorageService} from "../../services/token-storage.service";
import {ModalService} from "../_modal";
import { approvalService } from '../../services/approval.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent extends GenericComponent implements OnInit {

  appsUI: any = {};
  isLoggedIn : boolean;
  isAdmin : boolean ;
  roles: string[] = [];
  currentUser:  any = {};
  setInterval = setInterval;
  change: boolean = true ;


  constructor(private router: Router, private tokenStorageService: TokenStorageService, private localService: LocalService, 
    private i18NService: I18NService, private modalService:ModalService, private appService:approvalService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  changeColor(){
    this.change = !this.change ;
  }

  setIntrvl(){
    setInterval(() => this.changeColor(),7000);
  }
  ngOnInit() {
    this.setIntrvl();
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if (this.tokenStorageService.getToken()) {
      this.roles = this.tokenStorageService.getUser().roles;
      this.currentUser = this.tokenStorageService.getUser();
    }
    if (this.tokenStorageService.getRememberMe()) {
      this.roles = this.tokenStorageService.getUserRememberMe().roles;
      this.currentUser = this.tokenStorageService.getUserRememberMe();
      this.isLoggedIn = true;

    }
    /*this.appService.currentApprovalStageState.subscribe(state => {
      console.log("triggered in navbar"+state);
      this.isLoggedIn = state;
      this.isAdmin = state;
    });*/
    this.isAdmin = this.roles.includes('ROLE_ADMIN');


  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
  setLanguage(language: string): void {
    localStorage.setItem("setLanguage", "true");
    localStorage.setItem("i18n", language);
    location.reload();
  }

}
