import {Injectable, Inject} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { Postliked } from '../pojo/postliked';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private DCAF_WS_API: string = "";
  private params;

  constructor(private http: HttpClient, @Inject('conf') private conf: string) {
    this.DCAF_WS_API = conf + '/post';
    this.params = new HttpParams();
  }
  setRequestParams(params: any){
    this.params = params;
  }
  getPostById(id: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "/public/" + id);
  }

  getPostByCategory(categoryId: any, language:string): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "/public/category/" + categoryId + '/' + language, {params: this.params});
  }

  getPostByTitle(title: any, language:string): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "/public/title/" + title + '/' + language, {params: this.params});
  }

  getChildPostByCategory(categoryId: any, language:string,isTeenager:Boolean): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "/public/child/category/" + categoryId + '/' + language + "/" + isTeenager, {params: this.params});
  }

  getChildPostByTitle(title: any, language:string,isTeenager:Boolean): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "/public/child/title/" + title + '/' + language + "/" + isTeenager, {params: this.params});
  }
  addPost(post: any): Observable<any> {
    return this.http.post(this.DCAF_WS_API, post);
  }

  getAllPosts(language:string): Observable<any> {
    return this.http.get(this.DCAF_WS_API + '/public/all/' + language, {params: this.params});

  }

  getAllPostsForChildren(language:string,isTeenager:Boolean): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "/public/child/" + language + "/" + isTeenager, {params: this.params});

  }

  deletePost(id: number) {
    return this.http.delete(this.DCAF_WS_API + "/" + id);
  }

  updatePost(id: any, post: any): Observable<any> {
    return this.http.put(this.DCAF_WS_API + '/update/' + id, post);
  }
  likePost(id: any, post: Postliked): Observable<any> {
    return this.http.put(this.DCAF_WS_API + "/public/like/" + id, post);
  }
}
