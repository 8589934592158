import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const REMEMBER_ME = 'remember-me';
const REMEMBER_USER = 'remember-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut() {
    var  opened = window.sessionStorage.getItem('OPENED');
    window.sessionStorage.clear();
    localStorage.clear();
    window.sessionStorage.setItem('OPENED', opened);
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public getRememberMe(): string {
    return localStorage.getItem(REMEMBER_ME);
  }

  public saveUser(user) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public ActiveRememberMe(user){
    localStorage.setItem(REMEMBER_ME , "true");
    localStorage.setItem(REMEMBER_USER, JSON.stringify(user));
  }

  public getUserRememberMe(){
    return JSON.parse(localStorage.getItem(REMEMBER_USER));
  }

  public getUser() {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
}
