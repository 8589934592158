import { Component, OnInit } from '@angular/core';
import { GenericComponent } from "../../GenericComponent";
import { TokenStorageService } from "../../services/token-storage.service";
import { LocalService } from "../../services/LocalService";
import { I18NService } from "../../services/I18NService";
import { HomeService } from "../../services/home.service";
import { Home } from "../../pojo/Home";
import { AboutService } from "../../services/about.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends GenericComponent implements OnInit {
  blogTitle: string;
  appsUI: any = {};
  home: Home = new Home();
  arabicHome: Home = new Home();
  about: string = "";
  videoId: any;
  defaultLanguage: boolean = false ;

  constructor(private router: Router, private homeService: HomeService, private aboutService: AboutService, private token: TokenStorageService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  ngOnInit() {
    this.aboutService.getAllAbout(this.i18n).subscribe(
      (about) => {
        if (about === null || about === "" || about.length === 0 ) {
            this.getAboutUsArabic();
        }
        if (about.length != 0) {
          this.about = about[0].content;
        }
      }, error => {
      })
    // get detail of Home
    this.getHomeArabicDetail();
    this.getHomeDetail();
    this.videoId = "";
  }
  search(event) {
    this.router.navigate(['/post/posts'])
  }
  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  getAboutUsArabic() {
    this.aboutService.getAllAbout("ar").subscribe(
      (res) => {
        if (res.length != 0) {
           this.about = res[0].content;
           this.defaultLanguage = true;
        }
      }, error => {
      })
  }
  getHomeArabicDetail() {
    this.homeService.getHome("ar").subscribe((res) => {
      this.arabicHome = res;
      if (this.home.card1_content === null || this.home.card1_content === "") {
        this.home.card1_content = this.arabicHome.card1_content;
        this.defaultLanguage = true;
      }
      if (this.home.card2_content === null || this.home.card2_content === "") {
        this.home.card2_content = this.arabicHome.card2_content;
        this.defaultLanguage = true;
      }
      if (this.home.card3_content === null || this.home.card3_content === "") {
        this.home.card3_content = this.arabicHome.card3_content;
        this.defaultLanguage = true;
      }
      if (this.home.description === null || this.home.description === "") {
        this.home.description = this.arabicHome.description;
        this.defaultLanguage = true;
      }
    },
      error => { });
  }
  getHomeDetail() {
    this.homeService.getHome(this.i18n).subscribe((res) => {
      this.home = res;
      if (this.home != undefined) {
        this.videoId = this.getId(this.home.video_link);
      }
      if (this.home == null) {
        this.home = this.arabicHome;
      }
    },
      error => { });
  }
  initFormData() {
    this.home = new Home();
    this.home.homeId = undefined;
    this.home.card1_content = "";
    this.home.card2_content = "";
    this.home.card3_content = "";
    this.home.description = " ";
    this.home.lang = "";
    this.home.slide1_text = "";
    this.home.slide2_text = "";
    this.home.slide3_text = "";
    this.home.video_link = "";
    this.home.fileUrl1 = "";
    this.home.fileUrl2 = "";
    this.home.fileUrl3 = "";
  }
}
