import { Component, OnInit } from '@angular/core';
import { GenericComponent } from "../../../GenericComponent";
import { LocalService } from "../../../services/LocalService";
import { I18NService } from "../../../services/I18NService";
import { CategoryBlog } from "../../../pojo/CategoryBlog";
import { ChildCategory } from "../../../pojo/ChildCategory";
import { SubCategory } from "../../../pojo/SubCategory";
import { CategoryBlogService } from "../../../services/category-blog.service";
import { TokenStorageService } from "../../../services/token-storage.service";
import { DocCategoryService } from "../../../services/doc-category.service";
import { ChildCategoryService } from "../../../services/child-category.service";
import { DocSubCategoryService } from "../../../services/doc-sub-category.service";
import { ServiceCategoryService } from "../../../services/service-category.service";
import { ServiceSubCategoryService } from "../../../services/service-sub-category.service";

import { DocCategory } from "../../../pojo/DocCategory";
import { Router } from "@angular/router";
import { ModalService } from "../../../common-elements/_modal";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule
} from "@angular/forms";
import { ServiceCategory } from '../../../pojo/ServiceCategory';
import { UploadFileService } from '../../../services/upload-file.service';
import { ServiceSubCategory } from '../../../pojo/ServiceSubCategory';
@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent extends GenericComponent implements OnInit {

  categoryBlog: CategoryBlog;
  categoryBlogUp: CategoryBlog;
  subCategoryUp: SubCategory;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  showQAM = false;
  categories: CategoryBlog[];
  subcategories: any[];
  childcategories: any[];
  categoriesDoc: any[];
  showCatPost = false;
  showCatDoc = false;
  showSubDoc = false;
  showChildCat = false;
  showAbout = false;
  showReportType = false;
  showPost = false;
  showService = false;
  showAddLib = false;
  showAccount = false;
  showListMail = false;
  showSugg = false;
  showHomeM = false;
  showReport = true;
  showContact = false;
  showAdminCredential = false;
  docCategory: DocCategory;
  docCategoryUp: DocCategory;
  childCategory: ChildCategory;
  childCategoryBlogUp: ChildCategory;
  categoryIdDelete: any;
  categoryIdDeletePost: any;
  docSubCategoryID: any;
  docSubCategoryIdUpdate: any;
  categoryIdUpdate: any;
  categoryIdPostUpdate: any;
  childcategoryIdPostUpdate: any;
  categoryIdDeleteChildId: any;
  categoryForm: FormGroup;
  childCategoryForm: FormGroup;
  docCategoryForm: FormGroup;
  docSubCategoryForm: FormGroup;
  submitted: boolean = false;
  docCategorySubmitted: boolean = false;
  docSubCategorySubmitted: boolean = false;
  showCatService:boolean = false;
  categoriesService: any[];
  serviceCategoryForm: FormGroup;
  servCategorySubmitted:boolean = false;
  servCategoryUp : ServiceCategory;
  categoryServIdUpdate: number;
  servCategoryIdDelete:number;

  showSubCatService:boolean = false;
  subcategoriesService: any[];
  serviceSubCategoryForm: FormGroup;
  servSubCategorySubmitted:boolean = false;
  selectedFiles: FileList;
  currentFile: File;
  visible: boolean;
  mode: string = "indeterminate";

  subcategoryServIdUpdate:number;
  servSubCategoryUp : ServiceSubCategory;
  servSubCategoryIdDelete:number;
  constructor(private docSubCategoryService: DocSubCategoryService, private childCategoryService: ChildCategoryService, private localService: LocalService, private fb: FormBuilder, private i18NService: I18NService, private categoryBlogService: CategoryBlogService, private token: TokenStorageService,
    private docCategoryService: DocCategoryService, private router: Router, private modalService: ModalService,private servCategoryService: ServiceCategoryService,private servSubCategoryService: ServiceSubCategoryService,
    private uploadService: UploadFileService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  initForm() {
    this.categoryForm = this.fb.group({
      nameFR: ["", Validators.required],
      nameEN: ["", Validators.required],
      nameAR: ["", Validators.required]
    });
  }
  initChildCategoryForm() {
    this.childCategoryForm = this.fb.group({
      nameFR: [""],
      nameEN: [""],
      nameAR: [""]
    });
  }
  initDocForm() {
    this.docCategoryForm = this.fb.group({
      docCategoryFr: ["", Validators.required],
      docCategoryAr: ["", Validators.required],
      docCategoryEn: ["", Validators.required]
    });
  }
  initDocSubCategoryForm() {
    this.docSubCategoryForm = this.fb.group({
      categoryId: ["", Validators.required],
      docSubCategoryFr: ["", Validators.required],
      docSubCategoryAr: ["", Validators.required],
      docSubCategoryEn: ["", Validators.required]
    });
  }
  initServiceCategForm() {
      this.serviceCategoryForm = this.fb.group({
        serviceCategoryFr: ["", Validators.required],
        serviceCategoryAr: ["", Validators.required],
        serviceCategoryEn: ["", Validators.required]
      });
  }
  initServiceSubCategForm() {
    this.serviceSubCategoryForm = this.fb.group({
      servSubCategoryFr: ["", Validators.required],
      servSubCategoryAr: ["", Validators.required],
      servSubCategoryEn: ["", Validators.required],
      categoryId : ["", Validators.required]
    });
}
  ngOnInit() {
    this.initForm();
    this.initDocForm();
    this.initDocSubCategoryForm();
    this.initChildCategoryForm();
    this.initServiceCategForm();
    this.initServiceSubCategForm();
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');

    this.categoryBlog = new CategoryBlog();
    this.docCategory = new DocCategory();
    this.docCategoryUp = new DocCategory();
    this.categoryBlogUp = new CategoryBlog();
    this.subCategoryUp = new SubCategory();
    this.childCategory = new ChildCategory();
    this.childCategoryBlogUp = new ChildCategory();
    this.servCategoryUp = new ServiceCategory();
    this.servSubCategoryUp = new ServiceSubCategory();
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  addChildCategory() {
    this.childCategoryService.addCategory(this.childCategoryForm.value).subscribe((res) => {
      this.childcategories = res;
      this.openModal('custom-modal-confirm-add');
    }, (error => {
      console.log("error");
    }))
  }

  addCategory() {
    this.submitted = true;
    if (this.categoryForm.valid) {
      this.categoryBlogService.addCategory(this.categoryForm.value).subscribe((res) => {
        this.categories = res;
        this.openModal('custom-modal-confirm-add');
      }, (error => {
        console.log("error");
      }))
    }
  }
  addSubCategory() {
    this.docSubCategorySubmitted = true;
    if (this.docSubCategoryForm.valid) {
      this.docSubCategoryService.addSubDocCategory(this.docSubCategoryForm.value, this.i18n).subscribe(
        data => {
          this.subcategories = data;
          this.openModal('custom-modal-confirm-add');
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  addDocCategory() {
    this.docCategorySubmitted = true;
    if (this.docCategoryForm.valid) {
      this.docCategoryService.addDocCategory(this.docCategoryForm.value).subscribe(
        data => {
          this.categoriesDoc = data;
          this.openModal('custom-modal-confirm-add');
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  showAddCatPost() {
    this.showCatPost = true;
    this.showPost = false;
    this.showCatDoc = false;
    this.showQAM = false;
    this.showAbout = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;

    this.categoryBlogService.getAllCategories().subscribe((res) => {
      this.categories = res;
    })
  }
  showAddChildPost() {
    this.showCatPost = false;
    this.showPost = false;
    this.showCatDoc = false;
    this.showQAM = false;
    this.showAbout = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = true;
    this.showCatService = false;
    this.showSubCatService = false;
    this.childCategoryService.getAllCategories().subscribe((res) => {
      this.childcategories = res;
    })
  }
  showAddSubDoc() {
    this.showCatDoc = false;
    this.showCatPost = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAbout = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = true;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
    this.docCategoryService.getAllDocCategories().subscribe((res) => {
      this.categoriesDoc = res;
    })
    this.docSubCategoryService.getAllDocSubCategories(this.i18n).subscribe((res) => {
      this.subcategories = res;
    })
  }
  showAddCatDoc() {
    this.showCatDoc = true;
    this.showSubDoc = false;
    this.showCatPost = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAbout = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
    this.docCategoryService.getAllDocCategories().subscribe((res) => {
      this.categoriesDoc = res;
    })
  }
  openDeleteModal(categoryId) {
    this.openModal('custom-modal-delete-cat');
    this.categoryIdDelete = categoryId;
  }
  closeModal(id: string) {
    this.modalService.close(id);
    this.router.navigate(['/home']);
  }
  openDeletePostModal(categoryId) {
    this.openModal('custom-modal-delete-cat-blog');
    this.categoryIdDeletePost = categoryId;
  }
  openDeleteChildModal(categoryId) {
    this.openModal('custom-modal-delete-child-category');
    this.categoryIdDeleteChildId = categoryId;
  }
  openDeleteSubCategory(categoryId) {
    this.openModal('custom-modal-delete-sub-cat');
    this.docSubCategoryID = categoryId;
  }
  deleteSubCategory() {
    this.docSubCategoryService.deleteSubDocCategory(this.docSubCategoryID, this.i18n).subscribe((res) => {
      this.subcategories = res;
      this.modalService.close('custom-modal-delete-sub-cat');
    }, (error => {
      console.log("error");
    }))
  }
  deletePostCategory() {
    this.categoryBlogService.deleteCategory(this.categoryIdDeletePost).subscribe((res) => {
      this.categories = res;
      this.modalService.close('custom-modal-delete-cat-blog');
    }, (error => {
      console.log("error");
    }))
  }
  deleteChildCategory() {
    this.childCategoryService.deleteCategory(this.categoryIdDeleteChildId).subscribe((res) => {
      this.childcategories = res;
      this.modalService.close('custom-modal-delete-child-category');
    }, (error => {
      console.log("error");
    }))
  }
  deleteDocCategory() {
    this.docCategoryService.deleteDocCategory(this.categoryIdDelete).subscribe(
      data => {
        this.categoriesDoc = data;
        this.modalService.close('custom-modal-delete-cat');
      },
      error => {
        console.log(error);
      }
    );
  }

  openDeleteServCategory(categoryId) {
    this.openModal('custom-modal-delete-serv-cat');
    this.servCategoryIdDelete = categoryId;
  }
  deleteServCategory() {
    this.servCategoryService.deleteServiceCategory(this.servCategoryIdDelete).subscribe((data) => {
        this.categoriesService = data;
        this.modalService.close('custom-modal-delete-serv-cat');
    }, (error => {
        console.log("error");
    }))
  }

  openDeleteServSubCategory(categoryId) {
    this.openModal('custom-modal-delete-serv-sub-cat');
    this.servSubCategoryIdDelete = categoryId;
  }
  deleteServSubCategory() {
    this.servSubCategoryService.deleteServiceSubCategory(this.servSubCategoryIdDelete).subscribe((data) => {
        this.subcategoriesService = data;
        this.modalService.close('custom-modal-delete-serv-sub-cat');
    }, (error => {
      console.log("error");
    }))
  }
  openEditPostCategoryModal(id: string, categoryId: any) {
    this.modalService.open(id);
    this.categoryIdPostUpdate = categoryId;
    this.categoryBlogService.getCategoryById(this.categoryIdPostUpdate).subscribe(
      data => {
        this.categoryBlogUp = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  openEditChildCategoryModal(id: string, categoryId: any) {
    this.modalService.open(id);
    this.childcategoryIdPostUpdate = categoryId;
    this.childCategoryService.getCategoryById(this.childcategoryIdPostUpdate).subscribe(
      data => {
        this.childCategoryBlogUp = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  editChildCategory() {
    this.childCategoryService.updateCategory(this.childcategoryIdPostUpdate, this.childCategoryBlogUp).subscribe(
      data => {
        this.childcategories = data;
        this.modalService.close('custom-modal-edit-child-category');
      },
      error => {
        console.log(error);
      }
    );
  }
  openEditSubCategoryModal(id: string, categoryId: any) {
    this.modalService.open(id);
    this.docSubCategoryIdUpdate = categoryId;
    this.docSubCategoryService.getSubCategoryById(this.docSubCategoryIdUpdate).subscribe(
      data => {
        this.subCategoryUp = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  editSubCategory() {
    this.docSubCategoryService.updateSubCategory(this.docSubCategoryIdUpdate, this.subCategoryUp, this.i18n).subscribe(
      data => {
        this.subcategories = data;
        this.modalService.close('custom-modal-edit-sub-category');
      },
      error => {
        console.log(error);
      }
    );
  }
  editPostCategory() {
    this.categoryBlogService.updateCategory(this.categoryIdPostUpdate, this.categoryBlogUp).subscribe(
      data => {
        this.categories = data;
        this.modalService.close('custom-modal-edit-post-category');
      },
      error => {
        console.log(error);
      }
    );
  }
  openEditDocCategoryModal(id: string, categoryId: any) {
    this.modalService.open(id);
    this.categoryIdUpdate = categoryId;
    this.docCategoryService.getDocCategoryById(this.categoryIdUpdate).subscribe(
      data => {
        this.docCategoryUp = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  editDocCategory() {
    this.docCategoryService.updateDocCategory(this.categoryIdUpdate, this.docCategoryUp).subscribe(
      data => {
        this.categoriesDoc = data;
        this.modalService.close('custom-modal-edit-doc-category');
      },
      error => {
        console.log(error);
      }
    );
  }
  openEditServCategoryModal(idModal: string, categoryId: number) {
    this.modalService.open(idModal);
    this.categoryServIdUpdate = categoryId;
    this.servCategoryService.getServiceCategoryById(this.categoryServIdUpdate).subscribe(
      data => {
        this.servCategoryUp = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  editServiceCategory() {
    this.servCategoryService.updateServCategory(this.categoryServIdUpdate, this.servCategoryUp).subscribe(
      data => {

        if (this.selectedFiles != null) {
          this.updateUpload(data.serviceCategoryID,'categoryService');
          this.modalService.close('custom-modal-edit-serv-category');
        }
        else {
          this.modalService.close('custom-modal-edit-serv-category');
        }
        this.servCategoryService.getAllServicesCategoriesPic(this.i18n).subscribe((res) => {
          this.categoriesService = res;
        });
      },
      error => {
        console.log(error);
      }
    );
  }
  openEditServSubCategoryModal(idModal: string, categoryId: number) {
    this.servCategoryService.getAllServicesCategories().subscribe((res) => {
      this.categoriesService = res;
    });

    this.modalService.open(idModal);
    this.subcategoryServIdUpdate = categoryId;
    this.servSubCategoryService.getServiceSubCategoryById(this.subcategoryServIdUpdate).subscribe(
      data => {
        this.servSubCategoryUp = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  editServiceSubCategory(){
    this.servSubCategoryService.updateServSubCategory(this.subcategoryServIdUpdate, this.servSubCategoryUp).subscribe(
      data => {
          /*if (this.selectedFiles != null) {
            this.updateUpload(data.servSubCategoryID,'subCategoryService');
            this.modalService.close('custom-modal-edit-serv-sub-category');
          }
          else {
            this.modalService.close('custom-modal-edit-serv-sub-category');
          }*/
          this.modalService.close('custom-modal-edit-serv-sub-category');
          this.servSubCategoryService.getAllServicesSubCategoriesPic(this.i18n).subscribe((res) => {
            this.subcategoriesService = res;
          });

      },
      error => {
        console.log(error);
      }
    );
  }
  showAddPost() {
    this.showPost = true;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showQAM = false;
    this.showAbout = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showQA() {
    this.showQAM = true;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showAbout = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showAddAbout() {
    this.showAbout = true;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showAddService() {
    this.showService = true;
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showAddDoc() {
    this.showAddLib = true;
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showManageAccount() {
    this.showAccount = true;
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showMails() {
    this.showListMail = true;
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showReports() {
    this.showReport = true;
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showReportTypeList() {
    this.showReportType = true;
    this.showSugg = false
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showHomeM = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showSuggestion() {
    this.showSugg = true;
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showHome() {
    this.showHomeM = true;
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showContactInfromations() {
    this.showHomeM = false;
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showReportType = false;
    this.showContact = true;
    this.showAdminCredential = false;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }
  showChangeAdminCredential() {
    this.showHomeM = false;
    this.showService = false
    this.showAbout = false;
    this.showCatPost = false;
    this.showCatDoc = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = true;
    this.showSubDoc = false;
    this.showChildCat = false;
    this.showCatService = false;
    this.showSubCatService = false;
  }

  showAddCatService(){
    this.showCatService = true;
    this.showCatDoc = false;
    this.showSubDoc = false;
    this.showCatPost = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAbout = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showChildCat = false;
    this.showSubCatService = false;

    this.servCategoryService.getAllServicesCategoriesPic(this.i18n).subscribe((res) => {
      this.categoriesService = res;
    })
  }

  showAddSubCatService(){
    this.showSubCatService = true;
    this.showCatDoc = false;
    this.showSubDoc = false;
    this.showCatPost = false;
    this.showPost = false;
    this.showQAM = false;
    this.showAbout = false;
    this.showService = false;
    this.showAddLib = false;
    this.showAccount = false;
    this.showListMail = false;
    this.showReport = false;
    this.showSugg = false;
    this.showHomeM = false;
    this.showReportType = false;
    this.showContact = false;
    this.showAdminCredential = false;
    this.showChildCat = false;
    this.showCatService = false;

    this.servCategoryService.getAllServicesCategoriesPic(this.i18n).subscribe((res) => {
      this.categoriesService = res;
    });
    this.servSubCategoryService.getAllServicesSubCategoriesPic(this.i18n).subscribe((res) => {
      this.subcategoriesService = res;
    });
  }

  addServiceCategory() {
      this.servCategorySubmitted = true;
      if (this.serviceCategoryForm.valid && this.selectedFiles != null) {
        this.servCategoryService.addServiceCategory(this.serviceCategoryForm.value).subscribe(
          data => {
            this.serviceCategoryForm.reset();
            this.servCategorySubmitted = false;
            if (this.selectedFiles != null) {
              this.upload(data.serviceCategoryID,'categoryService');
            }
            else {
              this.openModal('custom-modal-confirm-add');
            }
            this.servCategoryService.getAllServicesCategoriesPic(this.i18n).subscribe((res) => {
              this.categoriesService = res;
            });
          },
          error => {
            console.log(error);
          }
        );
      }
  }

  addSubServiceCategory() {
    this.servSubCategorySubmitted = true;
    if (this.serviceSubCategoryForm.valid) {
      this.servSubCategoryService.addServiceSubCategory(this.serviceSubCategoryForm.value).subscribe(
        data => {
          this.serviceSubCategoryForm.reset();
          this.servSubCategorySubmitted = false;
          /*if (this.selectedFiles != null) {
            this.upload(data.servSubCategoryID,'subCategoryService');
          }
          else {
            this.openModal('custom-modal-confirm-add');
          }*/
          this.openModal('custom-modal-confirm-add');
          this.servSubCategoryService.getAllServicesSubCategoriesPic(this.i18n).subscribe((res) => {
            this.subcategoriesService = res;
          });
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  upload(idRs,typeRs): void {

      this.currentFile = this.selectedFiles.item(0);
      const formData = new FormData();
      formData.append("targetType", typeRs);
      formData.append("targetId", JSON.stringify(idRs));
      formData.append("file", this.currentFile);
      this.uploadService.upload(formData).subscribe(
        (event) => {
          this.openModal("custom-modal-confirm-add");
        },
        (err) => { }
      );
    this.visible = false;
    this.mode = "indeterminate";
    this.selectedFiles = null;
  }

  activateProgressBar() {
    this.mode = "determinate";
  }
  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }

  sortCategByName(){
    if (this.i18n == "fr") {
      this.categoriesService.sort((a,b) => a.servCategoryFr.localeCompare(b.servCategoryFr));
    }
    else if (this.i18n == "en"){
      this.categoriesService.sort((a,b) => a.servCategoryEn.localeCompare(b.servCategoryEn));
    }
    else if (this.i18n == "ar"){
      this.categoriesService.sort((a,b) => a.servCategoryAr.localeCompare(b.servCategoryAr));
    }
    else {
      this.categoriesService.sort((a,b) => a.servCategoryFr.localeCompare(b.servCategoryFr));
    }
  }

  sortSubCategByName(){
    if (this.i18n == "fr") {
      this.subcategoriesService.sort((a,b) => a.servSubCategoryFr.localeCompare(b.servSubCategoryFr));
    }
    else if (this.i18n == "en"){
      this.subcategoriesService.sort((a,b) => a.servSubCategoryEn.localeCompare(b.servSubCategoryEn));
    }
    else if (this.i18n == "ar"){
      this.subcategoriesService.sort((a,b) => a.servSubCategoryAr.localeCompare(b.servSubCategoryAr));
    }
    else {
      this.subcategoriesService.sort((a,b) => a.servSubCategoryFr.localeCompare(b.servSubCategoryFr));
    }
  }

  updateUpload(idRs,typeRs): void {
      this.currentFile = this.selectedFiles.item(0);
      const formData = new FormData();
      formData.append("targetType", typeRs);
      formData.append("targetId", JSON.stringify(idRs));
      formData.append("file", this.currentFile);
      this.uploadService.udpateFileCateg(formData).subscribe(
        (res) => {
          if (res) {
            this.servSubCategoryService.getAllServicesSubCategoriesPic(this.i18n).subscribe((res) => {
              this.subcategoriesService = res;
            });
            this.visible = false;
            this.mode = "indeterminate";
            this.selectedFiles = null;
          }
        },
        (err) => { }
      );
  }
  logout() {
    this.token.signOut();
    this.router.navigate(['/'])
      .then(() => {
        window.location.reload();
      }
      );
  }
}
