import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
let roleList = [];
@Injectable({
  providedIn: 'root'
})
export class UserService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/user' ;
    }

    getUserById(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + id);
    }
    getUserInfo(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API+ '/info/' + id);
    }
    login(credentials): Observable<any> {
      return this.http.post(this.DCAF_WS_API + '/signin', credentials , httpOptions);
    }
    addUser(user): Observable<any> {
      roleList.push(user.role);
      return this.http.post(this.DCAF_WS_API+ '/signup'  , {
        firstname: user.firstname,
        lastname: user.lastname,
        birthday: user.birthday,
        address: user.address,
        gender: user.gender,
        state: user.state,
        email: user.email,
        job: user.job,
        phone: user.phone,
        username: user.username,
        password: user.password,
        role:roleList
      }, httpOptions);
    }
    updatePassword(data:any, userId:any): Observable<any> {
       return this.http.post(this.DCAF_WS_API +'/updatepassword/'+ userId, data, httpOptions);
    }
    updateUsername(data:any, userId:any): Observable<any> {
       return this.http.post(this.DCAF_WS_API +'/updateusername/'+ userId, data, httpOptions);
    }
    resetPassword(data): Observable<any> {
       return this.http.post(this.DCAF_WS_API +'/resetpassword', data);
    }
}
