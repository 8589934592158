export var i18n = {
  apps: {
    home: {
      fr: "Accueil",
      en: "Home",
      ar: "الرئيسية",
    },
    loading: {
      fr: "En cours de chargement...",
      en: "Loading...",
      ar: "جار التحميل...",
    },
    close: {
      fr: "Fermer",
      en: "Close",
      ar: "اغلاق",
    },
    largeFile: {
       fr: "Le fichier est trop volumineux, vous pouvez le télécharger en cliquant ici ",
       en: "The file is too large, you can download it by clicking on this link ",
       ar: "الملف كبير الحجم، يمكنك تنزيله بالضغط على هذا الرابط",
    },
    downloadFile: {
       fr: "Télécharger",
       en: "Download",
       ar: "تحميل",
    },
    download: {
      fr: "Télécharger le fichier",
      en: "Download the file",
      ar: "تحميل الملف",
    },
    connection: {
      fr: "Connexion",
      en: "Connection",
      ar: "تسجيل الدخول",
    },
    about: {
      fr: "A propos de nous",
      en: "About us",
      ar: "من نحن ؟",
    },
    addAbout: {
      fr: "Ajouter à propos",
      en: "Add about",
      ar: "اضف وصفا",
    },
    mediatheque: {
      fr: "Médiathèque",
      en: "Media library",
      ar: "مكتبة الوسائط",
    },
    mediathequeDescrip: {
      fr: "Vous trouverez ici des documents, des vidéos et des images utiles",
      en: "Here you will find useful documents, videos and images",
      ar: "ستجد هنا مستندات ومقاطع فيديو وصور مفيدة",
    },
    actualite: {
      fr: "Actualités et événements",
      en: "News & Events",
      ar: "الأخبار و الأحداث",
    },
    date: {
      fr: "Date",
      en: "Date",
      ar: "تاريخ",
    },
    readMore: {
      fr: "Lire plus",
      en: "Read more",
      ar: "اقرأ أكثر",
    },
    add: {
      fr: "Ajouter",
      en: "Add",
      ar: "أضف",
    },
    delete: {
      fr: "Supprimer",
      en: "Delete",
      ar: "إزالة",
    },
    blog: {
      fr: "Blog",
      en: "Blog",
      ar: "المدونة",
    },
    post: {
      fr: "Publication",
      en: "Post",
      ar: "المنشورات",
    },
    PostForChild: {
      fr: "Ce poste est pour les enfants",
      en: "This post is for children",
      ar: "هذا المنشور للأطفال",
    },
    deleteQuestion: {
      fr: "Supprimer la question",
      en: "Delete question",
      ar: "حذف السؤال",
    },
    editQuestion: {
      fr: "Modifier la question",
      en: "Edit question",
      ar: "تحرير السؤال",
    },
    addQuestion: {
      fr: "Ajouter une question",
      en: "Add question",
      ar: "أضف سؤال",
    },
    listQuestion: {
      fr: "Liste des questions",
      en: "List of questions",
      ar: "قائمة الأسئلة",
    },
    question: {
      fr: "Question",
      en: "Question",
      ar: "السؤال",
    },
    answer: {
      fr: "Réponse",
      en: "Answer",
      ar: "الإجابة",
    },
    action: {
      fr: "Actions",
      en: "Actions",
      ar: "تعريف المؤسسة",
    },
    deleteConfirmation: {
      fr: "Êtes-vous sûr de vouloir supprimer cet élément",
      en: "Are you sure you want to delete this item",
      ar: "هل أنت متأكد أنك تريد حذف هذا السؤال",
    },
    deleteConfirmationTitle: {
      fr: "Supprimer élément",
      en: "Delete item",
      ar: "حذف سؤال",
    },
    confirmSendingMessage: {
      fr: "Votre message a été envoyé avec succès",
      en: "Your message has been sent successfully",
      ar: "تم ارسال رسالتك بنجاح",
    },
    cancel: {
      fr: "Annuler",
      en: "Cancel",
      ar: "إلغاء",
    },
    contact: {
      fr: "Contact",
      en: "Contact",
      ar: "اتصل",
    },
    contactUs: {
      fr: "Contactez-nous",
      en: "Contact us",
      ar: "اتصل",
    },
    addContact: {
      fr: "Ajouter contact",
      en: "Add contact",
      ar: "إضافة جهة اتصال",
    },
    addConfirmation: {
      fr: "Les données sont ajoutées avec succès",
      en: "Your data is added successfully",
      ar: "تم إضافة بيانات بنجاح",
    },
    workspace: {
      fr: "Cadre réglementaire de travail",
      en: "Regulatory framework of work",
      ar: "الإطار المنظم للعمل",
    },
    establishment: {
      fr: "L'établissement / Structure",
      en: "establishment / structure",
      ar: "المؤسسة / الهيكل",
    },
    missions: {
      fr: "Missions",
      en: "Missions",
      ar: "المهام",
    },
    beneficiaries: {
      fr: "Bénéficiaires",
      en: "Beneficiaries",
      ar: "المستفيدون",
    },
    providedServices: {
      fr: "Services Fournis",
      en: "Provided Services",
      ar: "الخدمات المقدمة",
    },
    partners: {
      fr: "Partenaires",
      en: "Partners",
      ar: "الشركاء",
    },
    geographicCoverage: {
      fr: "Couverture géographique",
      en: "Geographic coverage",
      ar: "التغطية الجغرافية",
    },
    greenLine: {
      fr: "Ligne verte",
      en: "Green line",
      ar: "الخط الأخضر",
    },
    email: {
      fr: "Adresse email",
      en: "E-mail address",
      ar: "البريد الإلكتروني",
    },
    website: {
      fr: "Site Internet",
      en: "Website",
      ar: "الموقع الإلكتروني",
    },
    fax: {
      fr: "Le numéro de fax",
      en: "Fax number",
      ar: "الفاكس",
    },
    listMail: {
      fr: "Liste des e-mail",
      en: "List of E-mail",
      ar: "البريد الإلكتروني",
    },
    phone: {
      fr: "Numéro de téléphone",
      en: "Phone number",
      ar: "رقم الهاتف",
    },
    childInfo: {
      fr: "Données relatives à l'enfant qui fait l'objet de l'avis",
      en: "Data relating to the child who is the subject of the notice",
      ar: "المعطيات الخاصة بالطفل موضوع الإشعار",
    },
    childAddress: {
      fr: "L'adresse ou le lieu où se trouve l'enfant",
      en: "The address or place where the child is located",
      ar: "العنوان أو المكان الذي يوجد بها الطفل",
    },
    parentPhone: {
      fr: "Téléphone du tuteur (si c'est possible)",
      en: "Parent's phone (if applicable)",
      ar: "هاتف الولي (إن أمكن)",
    },
    reportType2: {
      fr: "Le type de menace pour l'enfant",
      en: "The type of threat to the child",
      ar: "نوع التهديد الذي يتعرض له الطفل",
    },
    moreInformations: {
      fr: "Pour fournir des informations supplémentaires sur la position de l'enfant",
      en: "To provide additional information about the position of the child",
      ar: "للإدلاء بمعطيات إضافية حول وضعية الطفل",
    },
    reporterInfo: {
      fr: "Informations sur le notifiant",
      en: "Information about the notifier",
      ar: "المعطيات الخاصة بالقائم بالإشعار",
    },
    childname: {
      fr: "nom et prénom de l'enfant",
      en: "name and surname of the child",
      ar: "اسم ولقب الطفل",
    },    edit: {
      fr: "Modifier",
      en: "Edit",
      ar: "تعديل",
    },
    firstname: {
      fr: "Prénom",
      en: "First name",
      ar: "الإسم",
    },
    lastname: {
      fr: "Nom",
      en: "Last name",
      ar: "اللقب",
    },
    birthday: {
      fr: "Anniversaire",
      en: "Birthday",
      ar: "يوم الميلاد",
    },
    gender: {
      fr: "Sexe",
      en: "Gender",
      ar: "الجنس",
    },
    male: {
      en: "Male",
      fr: "Homme",
      ar: "ذكر",
    },
    female: {
      en: "Female",
      fr: "Femme",
      ar: "أنثى",
    },
    state: {
      en: "civil state",
      fr: "état civil",
      ar: "الحالة المدنية",
    },
    editState: {
      en: "Change report state",
      fr: "Changer l'état du signalement",
      ar: "تغيير حالة التقرير",
    },
    single: {
      en: "Single",
      fr: "Célibataire",
      ar: "أعزب",
    },
    married: {
      en: "Married",
      fr: "Marié(e)",
      ar: "(متزوج(ة",
    },
    divorced: {
      en: "Divorced",
      fr: "Divorcé(e)",
      ar: "(مطلق (ة",
    },
    address: {
      en: "Address",
      fr: "Adresse",
      ar: "العنوان",
    },
    searchBlogHome: {
      en: "Search blogs",
      fr: "Chercher des articles",
      ar: "البحث في المدونات",
    },
    profession: {
      en: "Job",
      fr: "Profession",
      ar: "المهنة",
    },
    password: {
      en: "Password",
      fr: "Mot de passe",
      ar: "كلمة السر",
    },
    actualpassword: {
      en: "Actual Password",
      fr: "Mot de passe actuel",
      ar: "كلمة السر الحالية",
    },
    newpassword: {
      en: "New password",
      fr: "Nouveau mot de passe",
      ar: "كلمة السر الجديدة",
    },
    confirmpassword: {
      en: "Confirm Password",
      fr: "Confirmez mot de passe",
      ar: "تأكيد كلمة السر ",
    },
    changepassword: {
      en: "Password Change",
      fr: "Changement du mot de passe",
      ar: "تغير كلمة السر",
    },
    changeusername: {
      en: "Username Change",
      fr: "Changement de nom d'utilisateur",
      ar: "تغيير اسم المستخدم",
    },
    wrongPassword: {
      en: "Current password is wrong",
      fr: "Le mot de passe actuel est erroné",
      ar: "كلمة المرور الحالية خاطئة",
    },
    wrongConfirmPassword: {
      en: "New password and confirm password must match",
      fr: "Le nouveau mot de passe et le mot de passe de confirmation doivent correspondre",
      ar: "كلمة السر الجديدة و تأكيد كلمة السر يجب أن يتوافقا",
    },
    newUsername: {
      en: "New username",
      fr: "Nouveau nom d'utilisateur",
      ar: "اسم المستخدم الجديد",
    },
    username: {
      en: "Username",
      fr: "Nom d'utilisateur",
      ar: " اسم المستخدم",
    },
    signup: {
      en: "Sign up",
      fr: "S'inscrire",
      ar: " سجل",
    },
    login: {
      fr: "Se connecter",
      en: "Log In",
      ar: "تسجيل الدخول",
    },
    name: {
      fr: "Nom et prénom",
      en: "Last name and first name",
      ar: "الإسم واللقب",
    },
    subject: {
      fr: "Objet",
      en: "subject",
      ar: "الموضوع",
    },
    send: {
      fr: "Envoyer",
      en: "Send",
      ar: "إرسال",
    },
    message: {
      fr: "Message",
      en: "Message",
      ar: "الرسالة",
    },
    isSended: {
      fr: "envoyé vers si",
      en: "sent to si",
      ar: "si أرسل إلى",
    },
    com: {
      fr: "Communication",
      en: "Communication",
      ar: "الاتصالات",
    },
    happy: {
      fr: "Bonheur",
      en: "Happiness",
      ar: "السعادة",
    },
    adultBlog: {
      fr: "Blog pour les adultes",
      en: "Blog for adults",
      ar: "مدونة للكبار",
    },
    adultBlogDesc: {
      fr: "Vous trouverez nos articles ici",
      en: "You will find our articles here",
      ar: "سوف تجد مقالاتنا هنا",
    },
    childBlog: {
      fr: "Blog pour les enfants",
      en: "Blog for children",
      ar: "مدونة للا طفال",
    },
    childBlogDesc: {
      fr: "Vous trouverez ici toutes informations utiles concernant votre protection et votre bien-être",
      en: "Children can find here all useful information about their protection and well-being.",
      ar: "يمكنك العثور هنا على جميع المعلومات المفيدة حول حمايتك ورفاهتك",
    },
    children: {
      fr: "Espace enfant",
      en: "Child area",
      ar: "فضاء الطفل",
    },
    like: {
      fr: "J'aime",
      en: "Like",
      ar: " أحب",
    },
    frequentIndication: {
      fr: "Ajouter une question fréquente avec sa réponse",
      en: "Add a frequent question with its answer",
      ar: " أضف سؤالاً متكررًا بإجابته",
    },
    adminAddQA: {
      fr: "Vous pouvez ajouter les réponses des questions fréquentes à partir de cette interface",
      en: "You can add answers to frequently asked questions from this interface",
      ar: "يمكنك إضافة إجابات للأسئلة المتداولة من هذه الواجهة",
    },
    adminUpdateQA: {
      fr: "Vous pouvez modifier les réponses des questions fréquentes à partir de cette interface",
      en: "You can update answers to frequently asked questions from this interface",
      ar: "يمكنك تعديل إجابات للأسئلة المتداولة من هذه الواجهة",
    },
    solidarity: {
      fr: "Solidarité",
      en: "Solidarity",
      ar: "تضامن",
    },
    presentationDPE: {
      fr: "Présentation de la DPE et de ses principes",
      en: "Presentation of the DPE and its principles",
      ar: "وصف DPE ومبادئها",
    },
    city: {
      fr: "Région",
      en: "Region",
      ar: "منطقة",
    },
    ManageCity: {
      fr: "Gérer les régions",
      en: "Manage Regions",
      ar: "إدارة المناطق",
    },
    ManageAccount: {
      fr: "Gérer les comptes",
      en: "Manage accounts",
      ar: "إدارة المناطق",
    },
    DeleteAccount: {
      fr: "Supprimer compte",
      en: "Delete account",
      ar: "حذف الحساب",
    },
    EditAccount: {
      fr: "Modifier compte",
      en: "Edit account",
      ar: "تحرير الحساب ",
    },
    title: {
      fr: "Titre",
      en: "Title",
      ar: "عنوان",
    },
    service: {
      fr: "Services",
      en: "Services",
      ar: "خدمات",
    },
    ourService: {
      fr: "Nos services",
      en: "Our services",
      ar: "خدمات",
    },
    addService: {
      fr: "Ajouter service",
      en: "Add Service",
      ar: "أضف خدمة",
    },
    addServiceTitle: {
      fr: "Vous pouvez ajouter un service",
      en: "You can add Service",
      ar: "أضف خدمة",
    },
    cityName: {
      fr: "Nom de la région",
      en: "Name of region",
      ar: "اسم المنطقة",
    },
    postalCode: {
      fr: "Code postal",
      en: "Postal code",
      ar: "الرقم البريدي",
    },
    ministrypart1: {
      fr: "République Tunisienne",
      en: "Tunisian republic",
      ar: "الجمهورية التونسية ",
    },
    ministrypart2: {
      fr: "Ministère de la Famille, de la Femme, de l’Enfance et des Seniors",
      en: "Ministry of Family, Women, Children and Seniors",
      ar: " وزارة الأسرة والمرأة والطفولة وكبار السن",
    },
    ministrypart3: {
      fr: "Bureau du Délégué Général à la Protection de l’Enfance",
      en: "Office of the General Delegate for Child Protection",
      ar: "مكتب المندوب العام لحماية الطفولة",
    },
    accessDenied: {
      en: "Access Denied ! You don't have permission to access to this page ! ",
      fr: "Accès refusé ! Vous n'avez pas le droit d'accéder à cette page!",
      ar: " ليس لديك إذن بمشاهدة هذه الصفحة !",
    },
    rememberMe: {
      fr: "Se souvenir de moi",
      en: "Remember me ",
      ar: "تذكرني",
    },
    forgotpassword: {
      en: "Forgot password",
      fr: "Mot de passe oublié",
      ar: "نسيت كلمة المرور",
    },
    forgotpasswordText: {
      en: "To reset your password, please enter your email address. An email has been sent to you with your new password. If you can't find the mail, please also check the spam folder!",
      fr: "Pour réinitialiser votre mot de passe, veuillez saisir votre adresse email. Un email vous a été envoyé avec votre nouveau mot de passe. Si vous ne trouvez pas le courrier, veuillez également vérifier le dossier spam!",
      ar: "لإعادة تعيين كلمة المرور الخاصة بك ، يرجى إدخال عنوان بريدك الإلكتروني. تم إرسال بريد إلكتروني إليك مع كلمة المرور الجديدة. إذا لم تتمكن من العثور على البريد ، فيرجى أيضًا التحقق من مجلد الرسائل غير المرغوب فيها",
    },
    resetEmail: {
      en: "Reset password",
      fr: "Réinitialiser le mot de passe",
      ar: "إعادة تعيين كلمة المرور",
    },
    deleteCategoryConfirmation: {
      fr: "Êtes-vous sûr de vouloir supprimer  cette catégorie?",
      en: "Are you sure you want to delete this category?",
      ar: "هل أنت متأكد أنك تريد حذف هذه الفئة",
    },
    deleteCategory: {
      fr: "Supprimer la catégorie",
      en: "Delete category",
      ar: "حذف الفئة",
    },
    editCategory: {
      fr: "Modifier la catégorie",
      en: "Edit category",
      ar: "تحرير الفئة",
    },
    categoryFr: {
      fr: "La catégorie en français",
      en: "Category in French",
      ar: "الفئة بالفرنسية",
    },
    categoryAr: {
      fr: "La catégorie en arabe",
      en: "Category in Arabic",
      ar: "الفئة بالعربية",
    },
    categoryEn: {
      fr: "La catégorie en anglais",
      en: "Category in English",
      ar: "الفئة بالإنجليزية",
    },
    categoryList: {
      fr: "Liste des catégories",
      en: "List of categories",
      ar: "قائمة الفئات",
    },
    subcategoryList: {
      fr: "Liste des sous-catégories",
      en: "List of sub-categories",
      ar: "قائمة الفئات الفرعية",
    },
    mainCategory: {
      fr: "Catégorie principale",
      en: "Main category",
      ar: "الفئة الرئيسية",
    },
    subCategory: {
      fr: "Sous-catégorie",
      en: "Sub-category",
      ar: "الفئة الفرعية",
    },
    categoryListDoc: {
      fr: "Liste des catégories des documents",
      en: "List of categories of documents",
      ar: "قائمة الفئات",
    },
    addCategory: {
      fr: "Ajouter catégorie des publications",
      en: "Add category of posts",
      ar: "أضف فئة جديدة",
    },
    addCategoryDoc: {
      fr: "Ajouter catégorie des documents",
      en: "Add category of documents",
      ar: "أضف فئة جديدة",
    },
    addSubCategoryDoc: {
      fr: "Ajouter sous-catégorie des documents",
      en: "Add sub-category of documents",
      ar: "أضف فئة فرعية جديدة",
    },
    addCategoryLg: {
      fr: "Vous pouvez ajouter le nom de la catégorie en trois langues",
      en: "You can add the category name in three languages",
      ar: "يمكنك إضافة اسم الفئة بثلاث لغات",
    },
    addSubCategoryLg: {
      fr: "Vous pouvez ajouter le nom de la sous-catégorie en trois langues",
      en: "You can add the sub-category name in three languages",
      ar: "يمكنك إضافة اسم الفئة الفرعية بثلاث لغات",
    },
    subcategoryNameAR: {
      fr: "Nom de la catégorie du blog en arabe",
      en: "Name of blog category in arabic",
      ar: "اسم  الفئة الفرعية باللغة العربية",
    },
    subcategoryNameEN: {
      fr: "Nom de la catégorie du blog en anglais",
      en: "Name of blog category in english",
      ar: "اسم  الفئة الفرعية باللغة الإنجليزية",
    },
    subcategoryNameFR: {
      fr: "Catégorie du blog en français",
      en: "Blog category in french",
      ar: "اسم  الفئة الفرعية باللغة الفرنسية",
    },
    categoryListShow: {
      fr: "Afficher la liste des catégories des publications",
      en: "Display the list of publications categories",
      ar: "اعرض قائمة الفئات",
    },
    categoryListShowDoc: {
      fr: "Afficher la liste des catégories des documents",
      en: "Display the list of documents categories",
      ar: "اعرض قائمة الفئات",
    },
    reportType: {
      fr: "Type de signalement",
      en: "Report Type",
      ar: "نوع التقرير",
    },
    reportAgeRange: {
      fr: "Tranche d'âge",
      en: "Age range",
      ar: "الفئة العمرية",
    },
    supBorn: {
      fr: "Ou plus",
      en: "Or more ",
      ar: "او اكثر",
    },
    optionPlaceholder: {
      fr: "Veuillez sélectionner l'âge de l'enfant",
      en: "Please select the child age",
      ar: "الرجاء تحديد عمر الطفل",
    },
    year: {
      fr: "ans",
      en: "years",
      ar: "سنوات",
    },
    reportTypeList: {
      fr: "Liste de types de signalement",
      en: "Report Type list",
      ar: "قائمة أنواع التقارير",
    },
    reportTypeListSection: {
      fr: "La liste des types de signalement",
      en: "The report Type list",
      ar: "قائمة أنواع التقارير",
    },
    addReportType: {
      fr: "Ajouter type de signalement",
      en: "Add report Type",
      ar: "نوع التقرير",
    },
    map: {
      fr: "Carte",
      en: "Map",
      ar: "خريطة",
    },
    share: {
      fr: "Partager",
      en: "Share",
      ar: "مشاركة",
    },
    actualites: {
      fr: "Actualités",
      en: "News",
      ar: "مستجدات",
    },
    addPost: {
      fr: "Ajouter publication",
      en: "Add post",
      ar: "أضف منشورا",
    },
    addPostTitle: {
      fr: "Vous pouvez ajouter des publications pour enfants et adultes",
      en: "You can add publications for children and adults",
      ar: "يمكنك إضافة منشورات للأطفال والكبار",
    },
    listPost: {
      fr: "Publications",
      en: "Posts",
      ar: "المشاركات",
    },
    logout: {
      fr: "Déconnexion",
      en: "Logout",
      ar: "تسجيل خروج",
    },
    changeAdminCredential: {
      fr: "Changer les informations d'identification de l'administrateur",
      en: "Change admin credentials",
      ar: "تغيير بيانات المستخدم",
    },
    report: {
      fr: "signalement au délégué à la protection de l’enfance",
      en: "Reporting to the child protection officer",
      ar: "إشعار مندوب حماية الطفولة",
    },
    manageCategory: {
      fr: "Gérer les catégories ",
      en: "Manage blog categories",
      ar: "إدارة الفئات",
    },
    manageChildCategory: {
      fr: "Gérer les catégories de la section enfants ",
      en: "Manage the categories of the children section",
      ar: "إدارة فئات فضاء الأطفال",
    },
    manageSubCategory: {
      fr: "Gérer les sous-catégories",
      en: "Manage subcategories",
      ar: "إدارة الفئات الفرعية",
    },
    manageCategoryDoc: {
      fr: "Gérer les catégories des documents",
      en: "Manage documents categories",
      ar: "إدارة الفئات",
    },
    manageQuestion: {
      fr: "Gérer les questions",
      en: "Manage questions",
      ar: "إدارة الأسئلة",
    },
    categoryNameAR: {
      fr: "Nom de la catégorie du blog en arabe",
      en: "Name of blog category in arabic",
      ar: "اسم فئة المدونة باللغة العربية",
    },
    categoryNameEN: {
      fr: "Nom de la catégorie du blog en anglais",
      en: "Name of blog category in english",
      ar: "اسم فئة المدونة باللغة الإنجليزية",
    },
    categoryNameFR: {
      fr: "Catégorie du blog en français",
      en: "Blog category in french",
      ar: "اسم فئة المدونة باللغة الفرنسية",
    },
    listCategory: {
      fr: "Les catégories",
      en: "Categories",
      ar: "التصنيفات",
    },
    categoryType: {
      fr: "Type de la catégorie",
      en: "Category Type",
      ar: "نوع الفئة",
    },
    category: {
      fr: "Catégorie ",
      en: "Category",
      ar: "فئة ",
    },
    Ariana: {
      fr: "Ariana",
      en: "Ariana",
      ar: "أريانة",
    },
    Beja: {
      fr: "Béja",
      en: "Beja",
      ar: "باجة",
    },
    BenArous: {
      fr: "Ben Arous",
      en: "Ben Arous",
      ar: "بن عروس",
    },
    Bizerte: {
      fr: "Bizerte",
      en: "Bizerte",
      ar: "بنزرت",
    },
    Gabès: {
      fr: "Gabès",
      en: "Gabes",
      ar: "قابس",
    },
    Gafsa: {
      fr: "Gafsa",
      en: "Gafsa",
      ar: "قفصة",
    },
    Jendouba: {
      fr: "Jendouba",
      en: "Jendouba",
      ar: "جندوبة",
    },
    Kairouan: {
      fr: "Kairouan",
      en: "Kairouan",
      ar: "القيروان",
    },
    Kasserine: {
      fr: "Kasserine",
      en: "Kasserine",
      ar: "القصرين",
    },
    Kebili: {
      fr: "Kébili",
      en: "Kebili",
      ar: "قبلي",
    },
    Kef: {
      fr: "Kef",
      en: "Kef",
      ar: "الكاف",
    },
    Mahdia: {
      fr: "Mahdia",
      en: "Mahdia",
      ar: "المهدية",
    },
    Manouba: {
      fr: "Manouba",
      en: "Manouba",
      ar: "منوبة",
    },
    Médenine: {
      fr: "Médenine",
      en: "Medenine",
      ar: "مدنين",
    },
    Monastir: {
      fr: "Monastir",
      en: "Monastir",
      ar: "المنستير",
    },
    Nabeul: {
      fr: "Nabeul",
      en: "Nabeul",
      ar: "نابل",
    },
    Sfax: {
      fr: "Sfax",
      en: "Sfax",
      ar: "صفاقس",
    },
    SidiBouzid: {
      fr: "Sidi Bouzid",
      en: "Sidi Bouzid",
      ar: "سيدي بوزيد",
    },
    Siliana: {
      fr: "Siliana",
      en: "Siliana",
      ar: "سليانة",
    },
    Sousse: {
      fr: "Sousse",
      en: "Sousse",
      ar: "سوسة",
    },
    Tataouine: {
      fr: "Tataouine",
      en: "Tataouine",
      ar: "تطاوين",
    },
    Tozeur: {
      fr: "Tozeur",
      en: "Tozeur",
      ar: "توزر",
    },
    Tunis: {
      fr: "Tunis",
      en: "Tunis",
      ar: "تونس",
    },
    Zaghouan: {
      fr: "Zaghouan",
      en: "Zaghouan",
      ar: "زغوان",
    },
    everywhere: {
      fr: "Tous les gouvernorats de la Tunisie",
      en: "All governorates of Tunisia",
      ar: "كامل تراب الجمهورية",
    },
    signaler: {
      fr: "Signaler",
      en: "Report",
      ar: "إشعار",
    },
    reportTypeAr: {
      fr: "Type de signalement en Arabe",
      en: "Report Type in Arabic",
      ar: "نوع التقرير باللغة العربية ",
    },
    reportTypeFr: {
      fr: "Type de signalement en Français ",
      en: "Report type in French",
      ar: "نوع التقرير باللغة الفرنسية ",
    },
    reportTypeEn: {
      fr: "Type de signalement en Anglais",
      en: "Report type in English",
      ar: "نوع التقرير باللغة الإنجليزية",
    },
    governorate: {
      fr: "Gouvernorat",
      en: "Governorate",
      ar: "الولاية",
    },
    childgovernorate: {
      fr: "Gouvernorat",
      en: "Governorate",
      ar: "الولاية التي يوجد بها الطفل",
    },
    anonymous: {
      fr: "Être anonyme",
      en: "Be anonymous",
      ar: "لا أرغب في الإفصاح عن هويتي",
    },
    CreationDate: {
      en: "Creation date",
      fr: "Date de création ",
      ar: " تاريخ الإنشاء",
    },
    reportState: {
      en: "State",
      fr: "Etat",
      ar: "الحالة",
    },
    addNewReportType: {
      en: "Add new type",
      fr: "Ajouter un nouveau type",
      ar: "أضف نوع جديد",
    },
    reportList: {
      en: "Report list",
      fr: "Liste de signalements",
      ar: "قائمة التقارير",
    },
    manageReportTypeList: {
      en: "Manage report types",
      fr: "Gérer les types des signalements",
      ar: "إدارة أنواع التقارير",
    },
    french: {
      fr: "Français",
      en: "French",
      ar: "الفرنسية",
    },
    arabic: {
      fr: "Arabe",
      en: "Arabic",
      ar: "العربية",
    },
    english: {
      fr: "Anglais",
      en: "English",
      ar: "الإنجليزية",
    },
    addDoc: {
      en: "Add document",
      fr: "Ajouter un document",
      ar: "أضف الوثيقة",
    },
    sugService: {
      en: "Suggest service",
      fr: "Suggerer service",
      ar: "اقترح الخدمة",
    },
    listSugService: {
      en: "Suggestions list",
      fr: "Liste des suggestions",
      ar: "قائمة الاقتراحات",
    },
    Docs: {
      en: "Documents",
      fr: "Documents",
      ar: "الوثائق",
    },
    addAccount: {
      en: "Add account",
      fr: "Ajouter un compte",
      ar: "إضافة حساب",
    },
    addAccountTitle: {
      en: "You can add responsable account for each governorate",
      fr: "Vous pouvez ajouter un compte responsable pour chaque gouvernorat",
      ar: "يمكنك إضافة حساب مسؤول لكل محافظة",
    },
    accountList: {
      en: "Account list",
      fr: "Liste des comptes",
      ar: "قائمة الحسابات",
    },
    accountListTitle: {
      en: "Account list of governorates responsables",
      fr: "Liste des comptes des  responsables des gouvernorats",
      ar: "قائمة حساب المسئولين بالمحافظات",
    },
    aboutTitle: {
      en: "You can add description of DPE",
      fr: "Vous pouvez ajouter une description de DPE",
      ar: "يمكنك إضافة وصف DPE",
    },
    language: {
      fr: "Langue",
      en: "Language",
      ar: "اللغة",
    },
    description: {
      fr: "Description",
      en: "Description",
      ar: "وصف",
    },
    activated: {
      en: "Activated",
      fr: "Activé",
      ar: "مفعل",
    },
    deactivated: {
      en: "Deactivated",
      fr: "désactivé",
      ar: "ألغى",
    },
    accountStatus: {
      en: "Account status",
      fr: "Statut du compte",
      ar: "حالة الحساب",
    },
    next: {
      en: "Next",
      fr: "Suivant",
      ar: "التالي",
    },
    previous: {
      en: "Previous",
      fr: "Précédent",
      ar: "رجوع",
    },
    searchQuestion: {
      en: "Search question/answer",
      fr: "Chercher question/réponse",
      ar: "البحث عن سؤال/جواب",
    },
    questionList: {
      en: "List of questions and answers",
      fr: "Liste des questions et réponses",
      ar: "قائمة الأسئلة والأجوبة",
    },
    copyright: {
      en: "Copyright ",
      fr: "Droits d'auteur",
      ar: "جميع الحقوق محفوظة لـ",
    },
    informations: {
      en: "Informations ",
      fr: "Information",
      ar: "معلومات",
    },
    adminLogin: {
      en: "Administrator Portal ",
      fr: "Espace Administrateur",
      ar: "تسجيل دخول المسؤول",
    },
    administartion: {
      en: "Administration",
      fr: "Administration",
      ar: "الادارة",
    },
    sendUsMessage: {
      en: "Send us a Message",
      fr: "Envoyez-nous un message",
      ar: "أرسل لنا رسالة",
    },
    contactInfos: {
      en: "Contact Informations",
      fr: "Informations de contact",
      ar: "معلومات للتواصل",
    },
    questionResponse: {
      en: "Q/A",
      fr: "Q/R",
      ar: "الأسئلة المتواردة",
    },
    contactUsText: {
      en: "Any questions or remarks ? just write us a message",
      fr: "Des questions ou remarques? N'hésitez pas à nous envoyer un message",
      ar: "أي أسئلة أو ملاحظات؟ فقط اكتب لنا رسالة",
    },
    adminLoginText: {
      en: "This space is reserved only for administrators.",
      fr: "Cet espace est réservé uniquement pour les administrateurs.",
      ar: "هذه المساحة مخصصة للمسؤولين فقط",
    },
    LoginInText: {
      en: "Please enter your username and password to login.",
      fr: "Veuillez saisir votre identifiant et votre mot de passe pour vous connecter.",
      ar: "من فضلك أدخل إسم المستخدم و كلمة السر الخاصة بك للتسجيل",
    },
    loginfailed: {
      fr: "Echec de la connexion! Veuillez vérifier votre nom d'utilisateur et votre mot de passe",
      en: "Login failed! Please verify your username and password",
      ar: "لم يتم تسجيل الدخول! الرجاء التثبت من إسم المستخدم وكلمة السر",
    },
    resetPasswordText: {
      en: "Enter your email address and we'll send you a link to reset your password.",
      fr: "Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
      ar: "أدخل عنوان بريدك الإلكتروني وسنرسل لك رابطًا لإعادة تعيين كلمة مرورك",
    },
    resetPassword: {
      fr: "Réinitialiser le mot de passe",
      en: "Reset Password",
      ar: "إعادة تعيين كلمة المرور",
    },
    resetPasswordTextLeft: {
      fr: "Mot de passe oublié ? Pas de soucis. Entrez simplement votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
      en: "Forgot your password ? No worries. Just enter your email address and we'll send you a link to reset your password.",
      ar: "نسيت رقمك السري ؟ لا داعى للقلق. فقط أدخل عنوان بريدك الإلكتروني وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك.",
    },
    findService: {
      fr: "Trouver nos services dans les régions tunisiennes",
      en: "Find our services in the Tunisian regions",
      ar: "ابحث عن خدماتنا في المناطق التونسية",
    },
    searchService: {
      fr: "Chercher les services par nom ou par région",
      en: "Search services by name or region",
      ar: "خدمات البحث بالاسم أو المنطقة",
    },
    reportTextPart1: {
      fr: "Vous pouvez aider à mettre fin à la maltraitance des enfants si vous soupçonnez ou savez qu'un enfant est maltraité ou négligé",
      en: "There are ways you can help stop child maltreatment if you suspect or know that a child is being abused or neglected.",
      ar: "يساهم إشعار مندوب حماية الطفولة في الحد من إساءة معاملة الأطفال ووقايتهم من العنف ومن شتى أشكال الاستغلال والتهديد.",
    },
    reportTextPart2: {
      fr: "Si vous êtes témoin ou apprenez qu'un enfant est menacé ou de tout type d'abus ou d'exploitation, informez le représentant de la protection de l'enfance territorialement compétent.",
      en: "If you witness or become aware of a child being threatened or of any kind of abuse or exploitation, notify the territorially competent Child Protection Representative.",
      ar: "إذا شاهدت أو بلغ إلى علمك تعرض طفل للتهديد أو لأي نوع من أنواع الإساءة أو الاستغلال، بادر بإشعار مندوب حماية الطفولة المختص ترابيا.",
    },
    reportTextPart3: {
      fr: "Informer le délégué à la protection de l'enfance est un devoir pour tous les professionnels qui s'occupent d'enfants, y compris ceux qui sont soumis au secret professionne.",
      en: "Notifying the Child Protection Representative is a duty on all professionals who deal with children, including those who are subject to professional secrecy.",
      ar: "اشعار مندوب حماية الطفولة واجب على كل المهنيين المتعاملين مع الأطفال بمن في ذلك الخاضعين للسر المهني.",
    },
    reportTextPart4: {
      fr: "Vous pouvez aviser le représentant de la protection de l'enfance en :",
      en: "You can notify the Child Protection representative by:",
      ar: "يمكنك إشعار مندوب حماية الطفولة عن طريق:",
    },
    reportTextPart5: {
      fr: "Veuillez fournir des données précises sur l'état de l'enfant et où il se trouve afin que le représentant de la protection de l'enfance puisse le joindre et intervenir à son profit.",
      en: "Please provide accurate information about the child’s condition and whereabouts so that the Child Protection Representative can reach him and intervene for his benefit.",
      ar: "الرجاء تقديم معطيات دقيقة حول وضعية الطفل ومكان وجوده حتى يتسنى لمندوب حماية الطفولة التوصل إليه والتدخل لفائدته.",
    },
    reportTextPart5Sub1: {
      fr: "- Rendez-vous directement au bureau du délégué à la protection de l'enfance.",
      en: "- Go directly to the office of the Child Protection Representative.",
      ar: "- التوجه مباشرة لمكتب مندوب حماية الطفولة.",
    },
    reportTextPart5Sub2: {
      fr: "- Contact par téléphone.",
      en: "- Contact by phone.",
      ar: "- الاتصال بالهاتف القار.",
    },
    reportTextPart5Sub3: {
      fr: "- Envoyer une communication écrite.",
      en: "- Send a written communication.",
      ar: "- إرسال مراسلة خطية.",
    },
    reportTextPart5Sub4: {
      fr: "- Envoyer un e-mail.",
      en: "- Send an email.",
      ar: "- إرسال مراسلة الكترونية.",
    },
    reportTextPart5Sub5: {
      fr: "- Envoyez une notification à distance en remplissant ce formulaire.",
      en: "- Submit a remote notification by filling out this form..",
      ar: "- تقديم إشعار عن بعد عن طريق ملأ هذا النموذج.",
    },
    reportTextPart6: {
      fr: "Dans le cadre de l'encouragement du devoir de notification des cas de menaces à l'encontre des enfants, qui contribue à les sortir des situations difficiles dans lesquelles ils vivent, le législateur tunisien a prévu une protection juridique et des garanties au profit des responsables de la notification, représentés en ce qui suit:",
      en: "In the context of encouraging the duty to notify about cases of threats to children, which contributes to extricating them from the difficult situations in which they live, the Tunisian legislator provided legal protection and guarantees for the benefit of those responsible for the notification, represented in the following:",
      ar: "في إطار التشجيع على القيام بواجب الإشعار عن حالات تهديد الأطفال بما يساهم في انتشالهم من الوضعيات الصعبة التي يعيشونها، وفر المشرع التونسي حماية قانونية وضمانات لفائدة القائمين بالإشعار تتمثل في:",
    },
    reportTextPart6Sub1: {
      fr: "- Maintenir la confidentialité de l'identité de l'indicateur et empêcher sa divulgation.",
      en: "- Maintaining the confidentiality of the identity of the indicator and preventing its disclosure.",
      ar: "- الحفاظ على سرية هوية المشعر ومنع الكشف عنها.",
    },
    reportTextPart6Sub2: {
      fr: "- Ne pas donner suite judiciairement à l'indicateur, même s'il est prouvé que la notification n'est pas grave.",
      en: "- Not to follow up on the indicator judicially, even if it is proven that the notification is not serious.",
      ar: "- عدم تتبع المشعر قضائيا حتى وإن ثبت عدم جدية الإشعار.",
    },
    reportTextPart6Sub3: {
      fr: "- Exempter les professionnels du devoir de dissimulation du secret professionnel vis-à-vis du représentant de la Protection de l'Enfance.",
      en: "- Exempting professionals from the duty of concealing the professional secret towards the representative of Child Protection.",
      ar: "- إعفاء المهنيين من واجب كتمان السر المهني تجاه مندوب حماية الطفولة.",
    },
    reportLinkInfo: {
      fr: "Pour signaler des sites Web, des vidéos et des photos sur l'exploitation sexuelle des enfants sur Internet",
      en: "To notify about websites, videos and photos about child sexual exploitation on the Internet",
      ar: "للإشعار عن المواقع والفيديوهات والصور عن الاستغلال الجنسي على الأطفال عبر الأنترنت",
    },
    clickHere: {
      fr: "Cliquez ici",
      en: "Click here",
      ar: "اضغط هنا",
    },
    reportSituation: {
      fr: "Signaler une situation",
      en: "Report a Situation",
      ar: "إشعار مندوب حماية الطفولة",
    },
    reportSituationText: {
      fr: "Signaler un abus ou une négligence peut protéger un enfant et obtenir de l'aide.",
      en: "Reporting abuse or neglect can protect a child and get help.",
      ar: "يمكن أن يؤدي الإبلاغ عن سوء المعاملة أو الإهمال إلى حماية الطفل والحصول على المساعدة .",
    },
    ProtectChild: {
      fr: "Le signalement est une obligation légale et morale",
      en: "The report is a legal and moral obligation",
      ar: "الاشعار واجب قانوني وأخلاقي",
    },
    suggestService: {
      fr: "Vous avez remarqué un manque de service dans une région? Proposez le ici",
      en: "Have you noticed a lack of service in a region? Submit it here",
      ar: "هل لاحظت نقص الخدمة في المنطقة؟ أرسلها هنا",
    },
    suggest: {
      fr: "suggèrer",
      en: "Suggest",
      ar: "اقترح",
    },
    searchHere: {
      fr: "Taper le nom du l'établissment recherché ici",
      en: "Type the name of the establishment you are looking for here",
      ar: "اكتب اسم المؤسسة التي تبحث عنها هنا",
    },
    searchDoc: {
      fr: "Taper le titre de document recherché ici",
      en: "Type the title of the document you are looking for here",
      ar: "اكتب عنوان المستند الذي تبحث عنه هنا",
    },
    frequentQA: {
      fr: "Questions fréquentes",
      en: "frequently asked questions",
      ar: "الأسئلة المتداولة",
    },
    noResults: {
      fr: "Aucun résultat trouvé",
      en: "No results found",
      ar: "لم يتم العثور على نتائج",
    },
    dpe: {
      fr: "Délégué à la Protection de l'Enfance",
      en: "Child Protection Delegate",
      ar: "مندوب حماية الطفل",
    },
    addDocumentTitle: {
      fr: "Ajouter un fichier public à la médiathèque",
      en: "Add a public file to the media library",
      ar: "أضف ملفًا عامًا إلى مكتبة الوسائط",
    },
    editServiceDescription: {
      fr: "Tu peux modifier un service à travers ce formulaire",
      en: "You can modify a service through this form",
      ar: "يمكنك تعديل الخدمة من خلال هذا النموذج",
    },
    addDocumentDescriptionPart1: {
      fr: "Tu peux ajouter à la médiathèque un document accompagné d'une image de type(PNG, JPEG, JPG, GIF, SVG, TIF...)",
      en: "You can add to the media library a document accompanied by an image of type (PNG, JPEG, JPG, GIF, SVG, TIF ...)",
      ar: "يمكنك إضافة مستند إلى مكتبة الوسائط مصحوبًا بنوع صورة (PNG ، JPEG ، JPG ، GIF ، SVG ، TIF ...)",
    },
    titleRequired: {
      fr: "Ce champs est obligatoire, il faut saisir un titre",
      en: "This field is required, you must enter a title",
      ar: "الرجاء تعبئة كافة البيانات ،يجب إدخال عنوان",
    },
    contentRequired: {
      fr: "Ce champs est obligatoire, il faut écrire de contenu",
      en: "This field is required, you must write content",
      ar: "الرجاء تعبئة كافة البيانات ، يجب كتابة المحتوى",
    },
    urlDocRequired: {
      fr: "Ce champs est obligatoire, il faut insérer un lien de la vidéo",
      en: "This field is required, you must insert a video URL",
      ar: "الرجاء تعبئة كافة البيانات ، يجب إدخال عنوان للفيديو",
    },
    fileDocRequired: {
      fr: "Ce champs est obligatoire, il faut ajouter un document dont la taille ne dépasse pas 20KB",
      en: "This field is required, you must put a document  (image or file) with size lower than 20KB",
      ar: " الرجاء تعبئة كافة البيانات ، يجب إدخال ملف لا يتجاوز حجمه 20KB",
    },
    TypeDocRequired: {
      fr: "Ce champs est obligatoire, il faut choisir le type de média",
      en: "This field is required, you must choose the type of media",
      ar: "الرجاء تعبئة كافة البيانات ، يجب اختيار فئة",
    },
    CategDocRequired: {
      fr: "Ce champs est obligatoire, il faut choisir une catégorie",
      en: "This field is required, you must choose a category",
      ar: "الرجاء تعبئة كافة البيانات ، يجب اختيار نوع الوسائط",
    },
    DocRequired: {
      fr: "Il faut insérer soit une vidéo, soit un document",
      en: "You must insert either a video or a document",
      ar: "يجب إدراج إما مقطع فيديو أو مستند",
    },
    langRequired: {
      fr: "Ce champs est obligatoire, il faut choisir une langue",
      en: "This field is required, you must choose a language",
      ar: "الرجاء تعبئة كافة البيانات ، يجب عليك اختيار لغة",
    },
    addPostDescriptionPart1: {
      fr: "Tu peux créer une nouvelle publication accompagnée d'une image de type(PNG, JPEG, JPG, GIF, SVG, TIF...)",
      en: "You can create a new post accompanied by an image of type (PNG, JPEG, JPG, GIF, SVG, TIF ...)",
      ar: "يمكنك إنشاء منشور جديد مصحوبًا بصورة من النوع (PNG ، JPEG ، JPG ، GIF ، SVG ، TIF ...)",
    },
    addDocumentDescriptionPart2: {
      fr: "Aussi, vous peux ajouter à la médiathèque un document accompagné d'une séquence vidéo",
      en: "Also, you can add a document accompanied by a video sequence to the media library",
      ar: "أيضًا ، يمكنك إضافة مستند مصحوبًا بتسلسل فيديو إلى مكتبة الوسائط",
    },
    addPostDescriptionPart2: {
      fr: "Aussi, vous peux créer une nouvelle publication accompagnée d'une séquence vidéo",
      en: "Also, you can create a new post accompanied by a video sequence to the media library",
      ar: "أيضًا ، يمكنك إنشاء منشور جديد مصحوبًا بتسلسل فيديو إلى مكتبة الوسائط",
    },
    addDocumentDescriptionPart3: {
      fr: "Il est possible de joindre un fichier PDF au document",
      en: "It is possible to attach a PDF file to the document",
      ar: "من الممكن إرفاق ملف PDF بالمستند",
    },
    fieldRequired: {
      en: "Failure ! please complete all fields",
      fr: "Echec ! Veuillez remplir tous les champs ",
      ar: "الرجاء تعبئة كافة البيانات"
    },
    fieldRequired2: {
      en: "Failure ! please complete all fields",
      fr: "Echec ! veuillez remplir les champs obligatoires ",
      ar: "الرجاء تعبئة البيانات المطلوبة"
    },
    addPostDescriptionPart3: {
      fr: "Ces actualités ajoutées au blog sont visibles aux tous les visiteurs de site",
      en: "These posts added to the blog are visible to all site visitors",
      ar: "هذه الأخبار المضافة إلى المدونة مرئية لجميع زوار الموقع",
    },
    addAccountText: {
      fr: "Seul l'administrateur a le droit d'ajouter de nouveaux comptes",
      en: "Only the administrator has the right to add new accounts",
      ar: "يحق للمسؤول فقط إضافة حسابات جديدة",
    },
    aboutDescription: {
      fr: "Cette section à propos est l'une des sections les plus importantes du site Web. Veuillez rédiger une brève description de la délégation de la protection d'enfant (DPE) afin d'informer les visiteurs du site sur cette organisation et ses différents objectifs ainsi que ses services fournis",
      en: "This about section is one of the most important sections of the website. Please write a brief description of the Child Protection Delegation (DPE) in order to inform site visitors about the organization, its various objectives and its provided services",
      ar: "يعد قسم 'معلومات عنا' أحد أهم أقسام الموقع. يرجى كتابة وصف موجز لمفوضية حماية الطفل (DPE) لإبلاغ زوار الموقع عن هذه المنظمة وأهدافها المختلفة والخدمات المقدمة",
    },
    emailListTitle: {
      fr: "Liste des emails envoyés par les utilisateurs",
      en: "List of emails sent by users",
      ar: "قائمة رسائل البريد الإلكتروني المرسلة من قبل المستخدمين",
    },
    suggestionsListTitle: {
      fr: "Liste des suggestions des services par régions",
      en: "List of service suggestions by region",
      ar: "قائمة اقتراحات الخدمة حسب المنطقة",
    },
    addServiceDescriptionPart1: {
      fr: "DPE offre plusieurs services dans les différentes régions.",
      en: "DPE offers several services in different regions.",
      ar: ".تقدم DPE عدة خدمات في مناطق مختلفة",
    },
    addServiceDescriptionPart2: {
      fr: "Veuillez ajouter le service tout en indiquant son nom, le gouvernorat auquel il lui est associé et son adresse. Il faut aussi ajouter le numéro de téléphone et aussi l'adresse email de responsable de ce service.",
      en: "Please add the service by indicating its name, the governorate with which it is associated and its address. You should also add the telephone number and also the email address of the person in charge of this service.",
      ar: "يرجى إضافة الخدمة مع ذكر اسمها والمحافظة التي ترتبط بها وعنوانها ، كما يجب إضافة رقم الهاتف وعنوان البريد الإلكتروني للمسؤول عن هذه الخدمة.",
    },
    listReport: {
      fr: "La liste des signalements",
      en: "The list of reports",
      ar: "قائمة التقارير",
    },
    content: {
      fr: "Contenu",
      en: "Content",
      ar: "المحتوى",
    },
    file: {
      fr: "Fichier",
      en: "File",
      ar: "الملف",
    },
    joinFile: {
      fr: "Joindre un fichier",
      en: "Attach a file",
      ar: "إرفاق ملف",
    },
    video: {
      fr: "Vidéo",
      en: "Video",
      ar: "فيديو",
    },
    addFile: {
      fr: "Ajouter un fichier",
      en: "Add a file",
      ar: "إضافة ملف",
    },
    addVideo: {
      fr: "Ajouter une vidéo",
      en: "Add a video",
      ar: "إضافة مقطع فيديو",
    },
    docType: {
      fr: "Type de document",
      en: "Document type",
      ar: "نوع الوثيقة",
    },
    videoLink: {
      fr: "Lien de la vidéo",
      en: "Video link",
      ar: "رابط الفيديو",
    },
    editDocumentTitle: {
      fr: "Modifier un fichier existant dans la médiathèque",
      en: "Edit an existing file in the media library",
      ar: "قم بتحرير ملف موجود في مكتبة الوسائط",
    },
    editDoc: {
      en: "Edit document",
      fr: "Modifier document",
      ar: "تحرير وثيقة",
    },
    editPostTitle: {
      fr: "Modifier une publication existante dans le blog",
      en: "Edit an existing post in the blog",
      ar: "قم بتحرير منشور موجود في المدونة",
    },
    editServiceTitle: {
      fr: "Modifier un service existant dans la liste des services",
      en: "Edit an existing service",
      ar: "تعديل خدمة موجودة في قائمة الخدمات",
    },
    editService: {
      en: "Edit service",
      fr: "Modifier service",
      ar: " تحرير الخدمة",
    },
    editPost: {
      en: "Edit post",
      fr: "Modifier publication",
      ar: "تحرير منشور",
    },
    homeManagement: {
      en: "Home page management",
      fr: "Gestion de la page d'accueil",
      ar: "إدارة الصفحة الرئيسية",
    },
    homeEditText: {
      en: "Management of the Home page's content",
      fr: "Gestion de contenu de la page d'accueil",
      ar: "إدارة محتوى الصفحة الرئيسية",
    },
    saveHomedata: {
      en: "Save data",
      fr: "Sauvegarder les données",
      ar: "حفظ البيانات",
    },
    homeManagementDescription1: {
      en: "Home page is the main page in the web site. It's the first page which will appear to the user.",
      fr: "La page d'accueil est la page principale du site Web, c'est la première page qui apparaîtra à l'utilisateur.",
      ar: "الصفحة الرئيسية هي الصفحة الرئيسية في موقع الويب ، وهي أول صفحة تظهر للمستخدم!",
    },
    homeManagementDescription2: {
      en: "Every single slide in the home page contains a picture and a title. Please fill in the field 'Slide title 1', 'Slide title 2' and 'Slide title 3' in the form",
      fr: "Chaque diapositive de la page d'accueil contient une image et un titre. Veuillez remplir les champs «Titre de diapositive 1» , «Titre de diapositive 2» et «Titre de diapositive 2» dans le formulaire.",
      ar: "تحتوي كل شريحة في الصفحة الرئيسية على صورة وعنوان. يرجى ملء الحقل 'عنوان الشريحة 1' و'عنوان الشريحة 2' و'عنوان الشريحة 3' في النموذج",
    },
    slide1Title: {
      en: "Slide title 1",
      fr: "Titre de diapositive 1",
      ar: "عنوان الشريحة 1",
    },
    slideImage: {
      en: "Slide images",
      fr: "Images de diapositive",
      ar: "صور الشريحة",
    },
    slide2Title: {
      en: "Slide title 2",
      fr: "Titre de diapositive 2",
      ar: "عنوان الشريحة 2",
    },
    slide3Title: {
      en: "Slide title 3",
      fr: "Titre de diapositive 3",
      ar: "عنوان الشريحة 3",
    },
    homeManagementDescription3: {
      en: "In the home page, there is 3 cards (News and events, Child area, Services). Please write the description of each one ",
      fr: "Dans la page d'accueil, il y a 3 cartes (Actualités et événements, space enfant , Services). Veuillez saisir la description de chacune",
      ar: "في الصفحة الرئيسية يوجد 3 بطاقات (أخبار وفعاليات ، منطقة أطفال ، خدمات). الرجاء إدخال وصف لكل واحدة",
    },
    editPostCategory: {
      en: "Edit the category of publications",
      fr: "Modifier la catégorie des publications",
      ar: "قم بتغيير فئة المنشورات",
    },
    editDocCategory: {
      en: "Edit document category",
      fr: "Modifier la catégorie de document",
      ar: "تحرير فئة الوثيقة",
    },
    editDocCategoryTextPart1: {
      fr: "Seul l'administrateur a le droit de modifier les catégories",
      en: "Only the administrator has the right to modify the categories",
      ar: "فقط المسؤول لديه الحق في تعديل الفئات",
    },
    editDocCategoryTextPart2: {
      fr: "Vous pouvez modifier le nom de la catégorie dans la langue de votre choix (français, anglais, arabe)",
      en: "You can edit the name of category in any language you want (French, english, arabic)",
      ar: "يمكنك تعديل اسم الفئة بأي لغة تريدها (الفرنسية ، الإنجليزية ، العربية)",
    },
    editAbout: {
      fr: "Modifier 'A propos de nous'",
      en: "Edit 'About us'",
      ar: "تحرير 'نبذة عنا'",
    },
    editAboutText: {
      fr: "Modifier la section 'A propos de nous'",
      en: "Edit the section 'About us'",
      ar: "تحرير قسم 'نبذة عنا'",
    },
    dcafMention1: {
      fr: "Ce site a été réalisé dans le cadre d'appui du centre pour la gouvernance du secteur de",
      en: "This site was produced as part of the support of the Geneva Center for Security Sector",
      ar: "تمّ إنجاز الموقع بدعم من مركز جينيف لحوكمة قطاع الأمن (DCAF)"
    },
    dcafMention2: {
      fr: "sécurité- Genève (DCAF),et financé par le Fonds d'affectation pour l'Afrique du Nord (TFNA).",
      en: "Governance (DCAF), and financed by the Trust Fund for North Africa (TFNA).",
      ar: "تونس و بتمويل من الصندوق الإنمائي لشمال إفريقيا (Trust Fund)"
    },
    searchReport: {
      en: "Search report",
      fr: "Chercher des signalements",
      ar: "البحث في التقارير",
    },
    CategTypeRequired: {
      fr: "Ce champs est obligatoire, il faut choisir type de la catégorie",
      en: "This field is required, you must choose a category type",
      ar: "الرجاء تعبئة كافة البيانات ،يجب إدخال نوع الفئة",
    },
    CategRequired: {
      fr: "Ce champs est obligatoire, il faut choisir la catégorie",
      en: "This field is required, you must choose a category",
      ar: "الرجاء تعبئة كافة البيانات ،يجب إدخال الفئة",
    },
    addCategorySer: {
      fr: "Ajouter catégorie des services",
      en: "Add category of service",
      ar: "أضف فئة جديدة",
    },
    categoryListService: {
          fr: "Liste des catégories des services",
          en: "List of categories of services",
          ar: "قائمة الفئات",
    },
    categoryListShowService: {
          fr: "Afficher la liste des catégories des services",
          en: "Display the list of services categories",
          ar: "اعرض قائمة الفئات",
    },
    placholderCategoryServFr: {
          fr: "Nom de la catégorie du service en français",
          en: "Name of service category in frensh",
          ar: "اسم  الفئة باللغة الفرنسية",
    },
    placholderCategoryServEn: {
          fr: "Nom de la catégorie du service en anglais",
          en: "Name of service category in english",
          ar: "اسم  الفئة باللغة الإنجليزية",
    },
    placholderCategoryServAr: {
          fr: "Nom de la catégorie du service en arabe",
          en: "Name of service category in arabic",
          ar: "اسم  الفئة باللغة العربية",
    },
    editServCategory: {
      en: "Edit the category of services",
      fr: "Modifier la catégorie des services",
      ar: "قم بتغيير فئة الخدمات",
    },
    manageServCategory: {
      fr: "Gérer les catégories ",
      en: "Manage service categories",
      ar: "إدارة الفئات",
    },
    manageServSubCategory: {
      fr: "Gérer les sous-catégories ",
      en: "Manage service subcategories",
      ar: "إدارة الفئات الفرعية",
    },
    sortByName:{
      fr: "Tri par nom",
      en: "Sort by name",
      ar: "الترتيب حسب الاسم"
    },
    addSubCategorySer: {
      fr: "Ajouter sous-catégorie des services",
      en: "Add sub-category of services",
      ar: "أضف فئة فرعية جديدة",
    },
    placholderSubCategoryServFr: {
          fr: "Nom de la sous-catégorie du service en français",
          en: "Name of service subcategory in frensh",
          ar: "اسم الفئة الفرعية باللغة الفرنسية",
    },
    placholderSubCategoryServEn: {
          fr: "Nom de la sous-catégorie du service en anglais",
          en: "Name of service subcategory in english",
          ar: "اسم الفئة الفرعية باللغة الإنجليزية",
    },
    placholderSubCategoryServAr: {
          fr: "Nom de la sous-catégorie du service en arabe",
          en: "Name of service subcategory in arabic",
          ar: "اسم الفئة الفرعية باللغة العربية",
    },
    subcategoryListService: {
      fr: "Liste des sous-catégories des services",
      en: "List of subcategories of services",
      ar: "قائمة الفئات الفرعية",
    },
    subcategoryListShowService: {
          fr: "Afficher la liste des sous-catégories des services",
          en: "Display the list of subcategories services",
          ar: "اعرض قائمة الفئات",
    },
    deleteSubCategory: {
      fr: "Supprimer la catégorie",
      en: "Delete category",
      ar: "حذف الفئة",
    },
    editSubCategory: {
      fr: "Modifier la sous-catégorie",
      en: "Edit subcategory",
      ar: "تحرير الفئة الفرعية",
    },
    subcategoryFr: {
      fr: "La sous-catégorie en français",
      en: "Subcategory in French",
      ar: "الفئة الفرعية بالفرنسية",
    },
    subcategoryAr: {
      fr: "La sous-catégorie en arabe",
      en: "Subcategory in Arabic",
      ar: "الفئة الفرعية بالعربية",
    },
    subcategoryEn: {
      fr: "La sous-catégorie en anglais",
      en: "Subcategory in English",
      ar: "الفئة الفرعية بالإنجليزية",
    },
    editServSubCategory: {
      en: "Edit the subcategory of services",
      fr: "Modifier la sous-catégorie des services",
      ar: "قم بتغيير فئة الخدمات الفرعية",
    },
    filterPerCateg:{
      en:"Filter by category",
      fr:"Filtrer par catégorie",
      ar:"تصفية حسب الفئة"
    },
    filterPerSubCateg:{
      en:"Filter by subcategory",
      fr:"Filtrer par sous-catégorie",
      ar:"تصفية حسب الفئة الفرعية"
    },
    searchPerCateg:{
      en:"Serach by all catgories",
      fr:"Rechercher par toutes les catégories",
      ar:"البحث عن طريق جميع الفئات"
    },
    searchPerSubCateg:{
      en:"Serach by all subcategory",
      fr:"Rechercher par toutes les sous-catégories",
      ar:"البحث عن طريق جميع الفئات الفرعية"
    },
    childrenChoiceF:{
      en:"Do you want to report a threat?",
      fr:"Voulez-vous signaler une menace ?",
      ar:"تحب تبلغ ؟"
    },
    childrenChoiceS:{
      en:"Do you want to understand?",
      fr:"Voulez-vous comprendre ?",
      ar:"تحب تفهم ؟"
    },
    continueNav:{
      en:"Continue navigation",
      fr:"Continuer la navigation",
      ar:"استمر في التصفح"
    },
    searchAll:{
      en:"All",
      fr:"Tous",
      ar:"الكل"
    },
    back:{
      en:"Back",
      fr:"Retour",
      ar:"رجوع"
    },
    lessThanAge:{
      en:"Are you less than 13 years old?",
      ar:"عمرك أقل من 13 سنة ؟",
      fr:"Avez-vous moins de 13 ans ?"
    },
    moreThanAge:{
      en:"Are you more than 13 years old?",
      ar:"عمرك أكثر من 13 سنة ؟",
      fr:"Avez-vous plus de 13 ans ?"
    },
    ageRequired: {
      fr: "Ce champ est obligatoire, il faut préciser le tranche d'âge",
      en: "This field is required, you must enter age range",
      ar: "الرجاء تعبئة كافة البيانات ،يجب الفئة العمرية",
    },
    ageRangeMore13: {
      fr: "Plus que 13 ans",
      en: "More than 13 years",
      ar: "أكثر من 13 سنة",
    },
    ageRangeUnder13: {
      fr: "Moins que 13 ans",
      en: "Under 13",
      ar: "أقل من 13 سنة",
    }
  },
};
