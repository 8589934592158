import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceCategory } from '../pojo/ServiceCategory';

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoryService {

  private DCAF_WS_API : string = "";

  constructor(private http: HttpClient, @Inject('conf') private conf : string) {
         this.DCAF_WS_API = conf +'/serviceCategory/' ;
  }

  addServiceCategory(category: ServiceCategory): Observable<any> {
         return this.http.post(this.DCAF_WS_API + "add" , category);
  }

  getAllServicesCategories(): Observable<any> {
         return this.http.get(this.DCAF_WS_API + "public/all");
  }
  getServiceCategoryById(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API +"public/categ/"+id);
  }

  updateServCategory(idC: number , category: ServiceCategory): Observable<any> {
       return this.http.put(this.DCAF_WS_API + "update/"+ idC , category);
  }

  deleteServiceCategory(id: number): Observable<any> {
       const url = `${this.DCAF_WS_API}${id}`;
       return this.http.delete(url);
  }
  getAllServicesCategoriesPic(language:string): Observable<any> {
     return this.http.get(this.DCAF_WS_API + "public/all/" + language);
   }

}
