import { Component, OnInit } from '@angular/core';
import {Account} from "../../../pojo/Account";
import { AccountService } from '../../../services/account.service';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import {ModalService} from "../../../common-elements/_modal";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule
} from "@angular/forms";
@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent extends GenericComponent implements OnInit {

  account: Account;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  gouvernoratList: Array<any> = [];
  accountForm: FormGroup;
  submitted : boolean = false ;

  constructor(private token: TokenStorageService, private accountService: AccountService, private fb: FormBuilder, private localService: LocalService, private i18NService: I18NService,private modalService:ModalService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  initForm() {
    this.accountForm = this.fb.group({
      email: ["", Validators.required],
      governorate: ["", Validators.required]
    });
  }
  ngOnInit() {
    this.account = new Account();
    this.initForm();
    this.gouvernoratList = [this.appsUI.Ariana[this.i18n], this.appsUI.Beja[this.i18n], this.appsUI.BenArous[this.i18n]
    , this.appsUI.Bizerte[this.i18n] , this.appsUI.Gabès[this.i18n] , this.appsUI.Gafsa[this.i18n]  , this.appsUI.Jendouba[this.i18n]
    , this.appsUI.Kairouan[this.i18n] , this.appsUI.Kasserine[this.i18n] , this.appsUI.Kebili[this.i18n] , this.appsUI.Kef[this.i18n]
    , this.appsUI.Mahdia[this.i18n] , this.appsUI.Manouba[this.i18n] , this.appsUI.Médenine[this.i18n] , this.appsUI.Monastir[this.i18n]
    , this.appsUI.Nabeul[this.i18n] , this.appsUI.Sfax[this.i18n] , this.appsUI.SidiBouzid[this.i18n], this.appsUI.Siliana[this.i18n]
    , this.appsUI.Sousse[this.i18n] , this.appsUI.Tataouine[this.i18n], this.appsUI.Tozeur[this.i18n], this.appsUI.Tunis[this.i18n]
    , this.appsUI.Zaghouan[this.i18n]];
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  addAccount(){
    this.submitted = true ;
    if(this.accountForm.valid){
      this.accountService.addAccount(this.accountForm.value).subscribe(
        data => {
          this.openModal('custom-modal-confirm-add');
          window.location.reload();
        },
        error => {
          console.log(error);
        }
      );
    }
  }
}
