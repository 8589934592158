import { Component, OnInit } from '@angular/core';
import {GenericComponent} from "../../../GenericComponent";
import {RequestService} from "../../../pojo/RequestService";
import {TokenStorageService} from "../../../services/token-storage.service";
import {RequestServiceService} from "../../../services/request-service.service";
import {LocalService} from "../../../services/LocalService";
import {I18NService} from "../../../services/I18NService";
import { ModalService } from '../../../common-elements/_modal';
import { UploadFileService } from '../../../services/upload-file.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-suggestion-list',
  templateUrl: './suggestion-list.component.html',
  styleUrls: ['./suggestion-list.component.css']
})
export class SuggestionListComponent  extends GenericComponent implements OnInit {

  requestService: RequestService;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  regions: any[];
  requests: any;
  requestId: number;
  SuggestionDescription: any;
  constructor(private modalService: ModalService, private token: TokenStorageService, 
    private requestServiceService: RequestServiceService, private localService: LocalService,
    private i18NService: I18NService,private uploadService: UploadFileService,public sanitizer: DomSanitizer) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.requestService = new RequestService();
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.requestServiceService.getAllRequestService().subscribe(res=>{
      this.requests = res;
      for(let i=0;i<this.requests;i++){
        this.requests[i].fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.requests[i].fileUrl);
      }
    })
    this.regions = [this.appsUI.Ariana[this.i18n], this.appsUI.Beja[this.i18n], this.appsUI.BenArous[this.i18n]
      , this.appsUI.Bizerte[this.i18n] , this.appsUI.Gabès[this.i18n] , this.appsUI.Gafsa[this.i18n]  , this.appsUI.Jendouba[this.i18n]
      , this.appsUI.Kairouan[this.i18n] , this.appsUI.Kasserine[this.i18n] , this.appsUI.Kebili[this.i18n] , this.appsUI.Kef[this.i18n]
      , this.appsUI.Mahdia[this.i18n] , this.appsUI.Manouba[this.i18n] , this.appsUI.Médenine[this.i18n] , this.appsUI.Monastir[this.i18n]
      , this.appsUI.Nabeul[this.i18n] , this.appsUI.Sfax[this.i18n] , this.appsUI.SidiBouzid[this.i18n], this.appsUI.Siliana[this.i18n]
      , this.appsUI.Sousse[this.i18n] , this.appsUI.Tataouine[this.i18n], this.appsUI.Tozeur[this.i18n], this.appsUI.Tunis[this.i18n]
      , this.appsUI.Zaghouan[this.i18n]];
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  confirmDelete(id){
    this.requestId = id ;
    this.openModal('custom-modal-delete-suggestion');
  }
  deleteSuggestion(){
    this.requestServiceService.deleteRequestService(this.requestId).subscribe(
      data => {
        this.requests = data;
        this.closeModal('custom-modal-delete-suggestion');
      },
      error => {
        console.log(error);
      }
    );
  }

  detailsSuggestionDescription(id){
    this.requestServiceService.getRequestServiceById(id).subscribe(
      data => {
      this.SuggestionDescription= data.description;
       this.openModal('custom-modal-show-suggestion-description');
      },
      error => {
        console.log(error);
      }
    );

  }


}
