import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericComponent } from '../../GenericComponent';
import { I18NService } from '../../services/I18NService';
import { LocalService } from '../../services/LocalService';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-children-choice',
  templateUrl: './children-choice.component.html',
  styleUrls: ['./children-choice.component.css']
})
export class ChildrenChoiceComponent extends GenericComponent implements OnInit {
  appsUI: any = {};
  hasFirstCardShoween: boolean = true;
  hasSecondCardShoween: boolean = false;
  isTeenager: boolean;
  constructor(private router: Router, private tokenStorageService: TokenStorageService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.hasFirstCardShoween = true;
    this.hasSecondCardShoween = false;
  }

  GoToNext(choice: Boolean) {
    this.hasFirstCardShoween = false;
    this.hasSecondCardShoween = true;
    if (choice === true) {
      this.isTeenager = true;
    }
    else {
      this.isTeenager = false;
    }
  }
  GoToChildrenPage() {
    if (this.isTeenager === true) {
      this.router.navigate(['/blog/children/isteenager/' + this.isTeenager]);
    }
    else {
      this.router.navigate(['/blog/children/isteenager/' + this.isTeenager]);
    }
  }

}
