import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate} from '@angular/router';
import { AddQuestionComponent } from './components/question/add-question/add-question.component';
import { EditQuestionComponent } from './components/question/edit-question/edit-question.component';
import { ListQuestionComponent } from './components/question/list-question/list-question.component';
import { AddContactComponent } from './components/contact/add-contact/add-contact.component';
import { EditContactComponent } from './components/contact/edit-contact/edit-contact.component';
import {ServiceOutletComponent} from "./components/service/service-outlet/service-outlet.component";
import {AddServiceComponent} from "./components/service/add-service/add-service.component";
import {ListServiceComponent} from "./components/service/list-service/list-service.component";
import {EditServiceComponent} from "./components/service/edit-service/edit-service.component";
import {EditPostComponent} from "./components/blog/edit-post/edit-post.component";
import {ListPostComponent} from "./components/blog/list-post/list-post.component";
import {AddPostComponent} from "./components/blog/add-post/add-post.component";
import {PostOutletComponent} from "./components/blog/post-outlet/post-outlet.component";
import { SendContactFormComponent } from './components/contact-form/send-contact-form/send-contact-form.component';
import { ListEmailsComponent } from './components/contact-form/list-emails/list-emails.component';
import { AddUserComponent } from './components/signup/add-user/add-user.component';
import { LoginComponent } from './components/signin/login/login.component';
import { AddDocCategoryComponent } from './components/categoryDoc/add-doc-category/add-doc-category.component';
import { ListDocCategoryComponent } from './components/categoryDoc/list-doc-category/list-doc-category.component';
import { EditDocCategoryComponent } from './components/categoryDoc/edit-doc-category/edit-doc-category.component';
import {AddCategoryComponent} from "./components/categoryBlog/add-category/add-category.component";
import {EditCategoryComponent} from "./components/categoryBlog/edit-category/edit-category.component";
import { AddReportTypeComponent } from './components/report-type/add-report-type/add-report-type.component';
import { ListReportTypeComponent } from './components/report-type/list-report-type/list-report-type.component';
import { EditReportTypeComponent } from './components/report-type/edit-report-type/edit-report-type.component';
import { AddReportComponent } from './components/report/add-report/add-report.component';
import { ListReportComponent } from './components/report/list-report/list-report.component';
import {AddAboutComponent} from "./components/about/add-about/add-about.component";
import {UploadFileComponent} from "./common-elements/files/upload-file/upload-file.component";
import { AddDocumentComponent } from './components/document/add-document/add-document.component';
import { ListDocumentComponent } from './components/document/list-document/list-document.component';
import { AddAccountComponent } from './components/account/add-account/add-account.component';
import { ListAccountComponent } from './components/account/list-account/list-account.component';
import { EditAccountComponent } from './components/account/edit-account/edit-account.component';
import {SuggestionServiceComponent} from "./components/service/suggestion-service/suggestion-service.component";
import {SuggestionListComponent} from "./components/service/suggestion-list/suggestion-list.component";
import {AboutUsComponent} from "./components/about/about-us/about-us.component";
import {HomeComponent} from "./components/home/home.component";
import {ChildrenComponent} from "./components/blog/children/children.component";
import {AuthGuardService as AuthGuard} from './auth/auth-guard.service';
import { DetailPostComponent } from './components/blog/detail-post/detail-post.component';
import { ServiceCardComponent } from './components/service/service-card/service-card.component';
import { ChildrenChoiceComponent } from './components/children-choice/children-choice.component';

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'edit-question/:id', component: EditQuestionComponent, canActivate: [AuthGuard]},
  {path: 'list-question', component: ListQuestionComponent},
  {path: 'edit-contact', component: EditContactComponent, canActivate: [AuthGuard]},
  {path: 'send-contact-form', component: SendContactFormComponent},
  {path: 'list-doc-category', component: ListDocCategoryComponent},
  {path: 'edit-doc-category/:id', component: EditDocCategoryComponent, canActivate: [AuthGuard]},
  {path: 'list-report-type', component: ListReportTypeComponent},
  {path: 'edit-report-type/:id', component: EditReportTypeComponent,canActivate: [AuthGuard]},
  {path: 'add-report', component: AddReportComponent},
  {path: 'list-report', component: ListReportComponent},
  {path: 'login', component: LoginComponent},
  {path: 'list-emails', component: ListEmailsComponent},
  {path: 'about', component: AboutUsComponent},
  {path: 'list-document', component: ListDocumentComponent},
  {path: 'list-account', component: ListAccountComponent},
  {path: 'edit-account/:id', component: EditAccountComponent,canActivate: [AuthGuard]},
  {path: 'blog/children/isteenager/:isteenager', component: ChildrenComponent},
  {path: 'administration', component: AddCategoryComponent,canActivate: [AuthGuard]},
  {path: 'blog/children', component: ChildrenChoiceComponent},

  {
    path: 'post', component: PostOutletComponent,
    children: [
      {path: 'posts', component: ListPostComponent},
      {path: 'edit-post/:id', component: EditPostComponent,canActivate: [AuthGuard]},
      {path: 'edit-category/:id', component: EditCategoryComponent,canActivate: [AuthGuard]},
      {path: 'detail-post/:id', component: DetailPostComponent},
    ]
  },
  {
    path: 'service', component: ServiceOutletComponent,
    children: [
      {path: 'edit-service/:id', component: EditServiceComponent,canActivate: [AuthGuard]},
      {path: 'all-services', component: ListServiceComponent},
      {path: 'suggest', component: SuggestionServiceComponent},
      {path: 'suggestion-list', component: SuggestionListComponent},
      {path: 'detail-service/:id', component: ServiceCardComponent},
    ]
  },
   {path: '', redirectTo: '/home', pathMatch: 'full'},

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
