export class Question {
  questionId: number;
  question: string;
  response: string;
  lang:string;
  isChild:boolean;
  isTeenager:boolean;

  constructor() {
  }
}
