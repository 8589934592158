import { Component, OnInit } from "@angular/core";
import { LocalService } from "../../../services/LocalService";
import { I18NService } from "../../../services/I18NService";
import { AboutService } from "../../../services/about.service";
import { GenericComponent } from "../../../GenericComponent";
import { TokenStorageService } from "../../../services/token-storage.service";
import { ModalService } from "../../../common-elements/_modal";
import { About } from "../../../pojo/About";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { UploadFileService } from "../../../services/upload-file.service";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.css"],
})
export class AboutUsComponent extends GenericComponent implements OnInit {
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  about: About[];
  arabicAbout: About[];
  aboutId: any;
  aboutUpdate: About;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: " ",
    translate: "no",
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  selectedFiles: FileList;
  visible: boolean;
  mode: string = "indeterminate";
  btnVisible : boolean = true;
  fileUrl: String;
  constructor(
    private localService: LocalService,
    private i18NService: I18NService,
    private aboutService: AboutService,
    private token: TokenStorageService,
    private modalService: ModalService,
    private uploadService: UploadFileService
  ) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.aboutUpdate = new About();
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes("ROLE_ADMIN");
    this.getListAbout();
  }
  getListAboutArabic(){
    this.aboutService.getAllAbout("ar").subscribe(
      (res) => {
        this.about = res;
      },
      (error) => {}
    );
  }
  getListAbout() {
    this.aboutService.getAllAbout(this.i18n).subscribe(
      (about) => {
        this.about = about;
        if(about === null){
            this.getListAboutArabic();
        }
        if(this.about.length == 0){
          this.btnVisible = false;

        }
        else{
          this.btnVisible = true;
          this.fileUrl = this.about['0'].fileUrl;
        }
      },
      (error) => {}
    );
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
    window.location.reload();
  }
  openEditAboutModal(aboutId) {
    this.openModal("custom-modal-edit-about");
    this.aboutId = aboutId;
    this.aboutService.getAboutById(this.aboutId).subscribe(
      (data) => {
        this.aboutUpdate = data;
      },
      (error) => { }
    );
  }
  editAbout() {
    this.aboutService.updateAbout(this.aboutId, this.aboutUpdate).subscribe(
      (data) => {
        this.about = data;
        this.closeModal("custom-modal-edit-about");
      },
      (error) => { }
    );
  }
  deleteAbout() {
    this.aboutService.deleteAbout(this.aboutId).subscribe(
      (res) => {
        this.modalService.close("custom-modal-delete-about");
        window.location.reload();
      },
      (error) => { }
    );
  }
  openDeleteAboutModal(aboutId) {
    this.openModal("custom-modal-delete-about");
    this.aboutId = aboutId;
  }
  activateProgressBar() {
    this.mode = "determinate";
  }

  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
    this.getListAbout();
    let aboutId = this.about[this.about.length - 1].aboutId;
    this.updateFile(aboutId);
  }
  updateFile(aboutId): void {
    const formData = new FormData();
    formData.append("targetType", "about");
    formData.append("targetId", JSON.stringify(aboutId));
    formData.append('file', this.selectedFiles.item(0));
    formData.append('cover', "");
    this.uploadService.udpateFile(formData).subscribe(
      (event) => {
        window.location.reload();
      },
      (err) => { }
    );

  }
}
