import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/account/' ;
    }
    getAccountList(): Observable<any> {
       return this.http.get(this.DCAF_WS_API );
    }
    deleteAccount(id: number) {
       const url = `${this.DCAF_WS_API}${id}`;
       return this.http.delete(url);
    }
    addAccount(account: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API , account);
    }
    getAccountById(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + id);
    }
    updateAccount(id: any , account: any): Observable<any> {
       return this.http.put(this.DCAF_WS_API + 'update/'+  id , account);
    }
}
