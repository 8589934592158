import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/contact' ;
    }

    addContact(contact: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API , contact);
    }

    getContact(lang: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + '/public/' + lang );
    }

    updateContact(contact: any): Observable<any> {
       return this.http.put(this.DCAF_WS_API+'/update', contact);
    }
}
