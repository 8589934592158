import { Component, OnInit } from '@angular/core';
import {Account} from "../../../pojo/Account";
import { AccountService } from '../../../services/account.service';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ModalService } from '../../../common-elements/_modal';

@Component({
  selector: 'app-list-account',
  templateUrl: './list-account.component.html',
  styleUrls: ['./list-account.component.css']
})
export class ListAccountComponent extends GenericComponent implements OnInit {

  account: Account;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  accountList: any[];
  accountId : any ;

  constructor(private modalService: ModalService, private token: TokenStorageService, private accountService: AccountService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.accountService.getAccountList().subscribe(
      data => {
        this.accountList = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  confirmDelete(accountId){
    this.openModal('custom-modal-delete-type');
    this.accountId = accountId;
  }
  deleteAccount(){
    this.accountService.deleteAccount(this.accountId).subscribe(
      data => {
        window.location.reload();
        this.modalService.close('custom-modal-delete-type');
      },
      error => {
        console.log(error);
      }
    );
  }
}
