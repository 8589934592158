import { Component, OnInit } from '@angular/core';
import {About} from "../../../pojo/About";
import {LocalService} from "../../../services/LocalService";
import {GenericComponent} from "../../../GenericComponent";
import {AboutService} from "../../../services/about.service";
import {I18NService} from "../../../services/I18NService";
import {TokenStorageService} from "../../../services/token-storage.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ModalService} from "../../../common-elements/_modal";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-add-about',
  templateUrl: './add-about.component.html',
  styleUrls: ['./add-about.component.css']
})
export class AddAboutComponent extends GenericComponent implements OnInit {
  about: About
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  currentFile: File;
  currentFiles: Blob[] = [];
  visible: boolean;
  mode: string = "indeterminate";
  selectedFiles: FileList;
  files: any;
  addboutform: FormGroup;
  oldAbout = new About();
  aboutForm: FormGroup;
  videoId: any;
  private selectedLang: string;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: ' ',
    translate: 'no',
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  constructor(private localService: LocalService,private aboutService: AboutService,private i18NService: I18NService, private fb: FormBuilder, private token: TokenStorageService,private modalService:ModalService, private uploadService: UploadFileService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
   ngOnInit() {
    this.selectedLang=this.i18n;


    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.visible = false;
    // Detail old about
    this.getAboutDetail();
    this.initForm();
    this.initFormData();
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
    window.location.reload();

  }
  initFormData() {
    this.oldAbout = new About();
    this.oldAbout.aboutId = undefined;
    this.oldAbout.content = "";
    this.oldAbout.fileUrl = "";

  }

  setradio(e: string): void   {
    this.selectedLang = e;
    this.getAboutDetail()
  }
  getAboutDetail() {
    this.aboutService.getAllAbout(this.selectedLang).subscribe((res) => {
      this.oldAbout = res[0];
      if (this.oldAbout == null) {
        this.initFormData();
      }
    },
      error => {});

  }
  addAbout() {
    this.aboutService.addAbout(this.aboutForm.value).subscribe((res) => {
      if (this.selectedFiles != null) {
        this.upload(res.aboutId);
      }
    // this.openModal('custom-modal-confirm-add');
    //  window.location.reload();
    },
      error => {});
  }

  updateAbout() {
    this.getAboutDetail();
    if (this.oldAbout.aboutId == undefined) {
      this.addAbout();
      this.openModal('custom-modal-confirm-add');
      window.location.reload();
    }
    else {
      let id = this.oldAbout.aboutId;
      this.aboutService.updateAbout(id, this.aboutForm.value).subscribe(
        (res) => {
          console.log(res)
          if (this.selectedFiles != null) {
            this.updateFile(res.aboutId);
          }
          this.openModal('custom-modal-confirm-add');
         window.location.reload();
        }
      );
    }

  }
  activateProgressBar() {
    this.mode = "determinate";
  }

  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }


  upload(aboutId): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFile = this.selectedFiles.item(i);
      const formData = new FormData();
      formData.append("targetType", "about");
      formData.append("targetId", JSON.stringify(aboutId));
      formData.append("file", this.currentFile);
      this.uploadService.upload(formData).subscribe(
        (event) => {
          this.openModal("custom-modal-confirm-add");
        },
        (err) => { }
      );
    }
  }

  updateFile(aboutId): void {
    const formData = new FormData();
    formData.append("targetType", "about");
    formData.append("targetId", JSON.stringify(aboutId));
    formData.append('file', this.selectedFiles.item(0));
    formData.append('cover', "");
    this.uploadService.udpateFile(formData).subscribe(
      (event) => {
        window.location.reload();
      },
      (err) => { }
    );

  }

  initForm() {
    let addAboutFormControl = {
      content: new FormControl(""),
      lang: new FormControl(""),
    }
    this.aboutForm = this.fb.group(addAboutFormControl);  }


}
