import { Component, OnInit } from '@angular/core';
import { LocalService } from "../../../services/LocalService";
import { I18NService } from "../../../services/I18NService";
import { TokenStorageService } from "../../../services/token-storage.service";
import { ModalService } from "../../../common-elements/_modal";
import { GenericComponent } from "../../../GenericComponent";
import { HomeService } from "../../../services/home.service";
import { Home } from "../../../pojo/Home";
import { UploadFileService } from '../../../services/upload-file.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-home',
  templateUrl: './add-home.component.html',
  styleUrls: ['./add-home.component.css']
})
export class AddHomeComponent extends GenericComponent implements OnInit {
  home: Home
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  currentFile: File;
  currentFiles: Blob[] = [];
  visible: boolean;
  mode: string = "indeterminate";
  selectedFiles: FileList;
  files: any;
  addhomeform: FormGroup;
  oldHome = new Home();
  homeForm: FormGroup;
  videoId: any;
  private selectedLang: string;
  constructor(private router: Router, private localService: LocalService, private homeService: HomeService, private i18NService: I18NService, private token: TokenStorageService, private modalService: ModalService,
    private uploadService: UploadFileService, private fb: FormBuilder) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.selectedLang=this.i18n;
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.visible = false;
    // Detail old home
    this.getHomeDetail();
    this.initForm();
    this.initFormData();
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  initFormData() {
    this.oldHome = new Home();
    this.oldHome.homeId = undefined;
    this.oldHome.card1_content = "";
    this.oldHome.card2_content = "";
    this.oldHome.card3_content = "";
    this.oldHome.description = " ";
    this.oldHome.lang = "";
    this.oldHome.slide1_text = "";
    this.oldHome.slide2_text = "";
    this.oldHome.slide3_text = "";
    this.oldHome.video_link = "https://youtu.be/coNgfEMcXig";
    this.oldHome.fileUrl1 = "";
    this.oldHome.fileUrl2 = "";
    this.oldHome.fileUrl3 = "";
  }
  setradio(e: string): void   {
    this.selectedLang = e;
    this.getHomeDetail()
  }
  getHomeDetail() {
    this.homeService.getHome(this.selectedLang).subscribe((res) => {
      this.oldHome = res;
      if (this.oldHome == null) {
        this.initFormData();
      }
    },
      error => {});
  }
  addHome() {
    this.openModal("custom-modal-loading-home");
    this.homeService.addHome(this.homeForm.value).subscribe((res) => {
      if (this.selectedFiles != null) {
        this.upload(res.homeId);
      }
      else {
        this.closeModal("custom-modal-loading-home");
        this.openModal('custom-modal-confirm-add-home');
      }
    },
      error => {});
  }
  updateHome() {
    this.getHomeDetail();
    if (this.oldHome.homeId == undefined) {
      this.addHome();
    }
    else {
      this.openModal("custom-modal-loading-home");
      let id = this.oldHome.homeId;
      this.homeService.updateHome(id, this.homeForm.value).subscribe(
        (res) => {
          if (this.selectedFiles != null) {
            this.updateFile(res.homeId);
          }
          else {
            this.closeModal("custom-modal-loading-home");
            this.openModal('custom-modal-confirm-add-home');
          }
        }
      );
    }
  }
  activateProgressBar() {
    this.mode = "determinate";
  }
  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }

  upload(homeId): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFile = this.selectedFiles.item(i);
      const formData = new FormData();
      formData.append("targetType", "home");
      formData.append("targetId", JSON.stringify(homeId));
      formData.append("file", this.currentFile);
      this.uploadService.upload(formData).subscribe(
        (event) => {
          this.closeModal("custom-modal-loading-home");
          this.openModal("custom-modal-confirm-add-home");
        },
        (err) => { }
      );
    }
  }
  updateFile(homeId): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFiles.push(this.selectedFiles.item(i));
    }
    const formData = new FormData();
    formData.append("targetType", "home");
    formData.append("targetId", JSON.stringify(homeId));
    for (var i = 0; i < this.currentFiles.length; i++) {
      formData.append('file', this.currentFiles[i]);
    }
    this.uploadService.udpateHomeFile(formData).subscribe(
      (event) => {
        this.closeModal("custom-modal-loading-home");
        this.openModal("custom-modal-confirm-add-home");
      },
      (err) => {}
    );

  }
  initForm() {
    let addhomeformControl = {
      description: new FormControl(""),
      slide1_text: new FormControl(""),
      slide2_text: new FormControl(""),
      slide3_text: new FormControl(""),
      card1_content: new FormControl(""),
      card2_content: new FormControl(""),
      card3_content: new FormControl(""),
      lang: new FormControl(""),
      video_link: new FormControl("")
    }
    this.homeForm = this.fb.group(addhomeformControl);
  }
  goToHome() {
      this.closeModal("custom-modal-confirm-add-home");
      this.router.navigate(['/home']);
  }

}
