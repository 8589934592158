import { Component, OnInit, Inject } from "@angular/core";
import { PostService } from "../../../services/post.service";
import { GenericComponent } from "../../../GenericComponent";
import { LocalService } from "../../../services/LocalService";
import { I18NService } from "../../../services/I18NService";
import { CategoryBlog } from "../../../pojo/CategoryBlog";
import { CategoryBlogService } from "../../../services/category-blog.service";
import { ModalService } from "../../../common-elements/_modal";
import { Post } from "../../../pojo/Post";
import { TokenStorageService } from "../../../services/token-storage.service";
import { UploadFileService } from "../../../services/upload-file.service";
import { Files } from "../../../pojo/Files";
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Postliked } from "../../../pojo/postliked";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ChildCategoryService } from "src/app/services/child-category.service";
import { ChildCategory } from "src/app/pojo/ChildCategory";
import { HttpParams } from '@angular/common/http';

@Component({
  selector: "app-list-post",
  templateUrl: "./list-post.component.html",
  styleUrls: ["./list-post.component.css"],
})
export class ListPostComponent extends GenericComponent implements OnInit {
  posts: Post[];
  categories: CategoryBlog[];
  blogTitle: string;
  appsUI: any = {};
  category: any;
  postId: any;
  isLiked = false;
  post1: Post;
  post2: Post;
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  updatedPostId: any;
  deletePostId: any;
  updatedPost: Post;
  deletedPost: Post;
  photos: Files[];
  hasimage: boolean;
  userIP: any;
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  fileType: string;
  visible: boolean;
  p: number = 1;
  count: number;
  getDocumentBy: string;
  noDocFound: boolean = false;
  mode: string = "indeterminate";
  configPost: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: " ",
    translate: "no",
    toolbarHiddenButtons: [["insertImage"], ["insertVideo"], ["link"], ["unlink"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  postForm: FormGroup;
  submitted = false;
  extList = ["png", "jpg", "jpeg", "gif"];
  extFileList = ["pdf"];
  extDocList = ["doc", "xls", "csv", "docx", "zip", "ppt", "pptx", "ooxml"];
  childCategories: ChildCategory;
  private http: HttpClient;
  constructor(
    private httpBackend: HttpBackend,
    private localService: LocalService,
    private i18NService: I18NService,
    private postService: PostService,
    private modalService: ModalService,
    private categoryBlogService: CategoryBlogService,
    private token: TokenStorageService,
    private fileService: UploadFileService,
    @Inject("uiconf") private url: string,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private childCategoryService: ChildCategoryService,

  ) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui().apps;
     this.http = new HttpClient(httpBackend);
  }
  getVideoType(documentLink, document) {
    if (documentLink.includes("youtube")) {
      document.videoType = "youtube";
    }
    else if (documentLink.includes("dailymotion")) {
      document.videoType = "dailymotion";
    }
    else if (documentLink.includes("vimeo")) {
      document.videoType = "vimeo";
    }
  }
  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  getDailyMotionId(url) {
    var m = url.match(/^.+dailymotion.com\/(video|hub)\/([^_?]+)[^#]*(#video=([^_&]+))?/);
    if (m !== null) {
      if (m[4] !== undefined) {
        return m[4];
      }
      return m[2];
    }
    return null;
  }
  getVimeoId = (url: string) => {
    const match = /vimeo.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    }
  };
  ngOnInit() {
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes("ROLE_ADMIN");
    this.visible = false;
    this.getAllPosts(1);
    this.categoryBlogService.getAllCategories().subscribe((res) => {
      this.categories = res;
    });
    this.updatedPost = new Post();
    this.post1 = new Post();
    this.loadIp();
    // initialise the post form control
    this.initForm(this.updatedPost);
    this.childCategoryService.getAllCategories().subscribe((res) => {
      this.childCategories = res;
    })
  }

  getAllPosts(p) {
    this.noDocFound = false;
    this.getDocumentBy = 'all';
    this.postService.setRequestParams(
      new HttpParams().set('page', p.toString())
    );
    this.postService.getAllPosts(this.i18n).subscribe(
      (res) => {
        if (res) {
          if (res == null) {
            this.noDocFound = true;
          }
          else {
            var data1 = res["results"];
            this.count = res["count"];
            this.posts = res["results"];
            this.posts.forEach((value, index) => {
              if (data1[index].videoLink) {
                var documentLink = data1[index].videoLink;
                this.getVideoType(documentLink, this.posts[index]);
              }
              this.posts[index].isPdf = false;
              if (data1[index].file != null && data1[index].file != "") {
                let mimeType = data1[index].file.match(/[^:/]\w+(?=;|,)/)[0];
                this.posts[index].isPdf = this.extFileList.some(ext => mimeType.includes(ext));
                this.posts[index].isImage = this.extList.some(ext => mimeType.includes(ext));
                this.posts[index].isDoc = this.extDocList.some(ext => mimeType.includes(ext));
                if (this.posts[index].isPdf == true) {
                  this.posts[index].cover = this.sanitizer.bypassSecurityTrustResourceUrl(data1[index].file);
                }
                else if (this.posts[index].isDoc == true) {
                  this.posts[index].cover = this.sanitizer.bypassSecurityTrustResourceUrl(data1[index].file);
                }
                else {
                  this.posts[index].cover = data1[index].file;
                }
              }
            });
          }
        }
      },
      (error) => { }
    );
  }
  searchPostByTitle(p) {
    this.postService.setRequestParams(
      new HttpParams().set('page', p.toString())
    );
    this.p = p ;
    this.getDocumentBy = 'title';
    var fieldValue = this.blogTitle;
    if (fieldValue == null || fieldValue == '' || fieldValue.length == 0) {
      this.getAllPosts(1);
    }
    else {
      this.postService.getPostByTitle(this.blogTitle, this.i18n).subscribe(
        (res) => {
          if (res == null) {
            this.noDocFound = true;
          }
          else {
            this.noDocFound = false;
            var data1 = res["results"];
            this.count = res["count"];
            this.posts = res["results"];
            this.posts.forEach((value, index) => {
              this.posts[index].isPdf = false;
              if (data1[index].videoLink) {
                var documentLink = data1[index].videoLink;
                this.getVideoType(documentLink, this.posts[index]);
              }
              if (data1[index].file != null && data1[index].file != "") {
                let mimeType = data1[index].file.match(/[^:/]\w+(?=;|,)/)[0];
                this.posts[index].isPdf = this.extFileList.some(ext => mimeType.includes(ext));
                this.posts[index].isImage = this.extList.some(ext => mimeType.includes(ext));
                this.posts[index].isDoc = this.extDocList.some(ext => mimeType.includes(ext));
                if (this.posts[index].isPdf) {
                  this.posts[index].cover = this.sanitizer.bypassSecurityTrustResourceUrl(data1[index].file);
                }
                else if (this.posts[index].isDoc) {
                  this.posts[index].cover = this.sanitizer.bypassSecurityTrustResourceUrl(data1[index].file);
                }
                else {
                  this.posts[index].cover = data1[index].file;
                }
              }
            });
          }

        },
        (error) => { }
      );
    }
  }

  searchPostByCategory(p) {
    this.noDocFound = false;
    if (this.category == "undefined") {
      this.getAllPosts(1)
    }
    else {
      this.postService.setRequestParams(
        new HttpParams().set('page', p.toString())
      );
      this.p = p;
      this.getDocumentBy = 'category';
      this.postService.getPostByCategory(this.category, this.i18n).subscribe(
        (res) => {
          if (res == null) {
            this.noDocFound = true;
          }
          else {
            var data1 = res["results"];
            this.count = res["count"];
            this.posts = res["results"];
            this.posts.forEach((value, index) => {
              this.posts[index].isPdf = false;
              if (data1[index].videoLink) {
                var documentLink = data1[index].videoLink;
                this.getVideoType(documentLink, this.posts[index]);
              }
              if (data1[index].file != null && data1[index].file != "") {
                let mimeType = data1[index].file.match(/[^:/]\w+(?=;|,)/)[0];
                this.posts[index].isPdf = this.extFileList.some(ext => mimeType.includes(ext));
                this.posts[index].isImage = this.extList.some(ext => mimeType.includes(ext));
                this.posts[index].isDoc = this.extDocList.some(ext => mimeType.includes(ext));
                if (this.posts[index].isPdf) {
                  this.posts[index].cover = this.sanitizer.bypassSecurityTrustResourceUrl(data1[index].file);
                }
                else if (this.posts[index].isDoc) {
                  this.posts[index].cover = this.sanitizer.bypassSecurityTrustResourceUrl(data1[index].file);
                }
                else {
                  this.posts[index].cover = data1[index].file;
                }
              }
            });
          }

        },
        (error) => { }
      );
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  openModalRead(id: string, postId: any) {
    this.modalService.open(id);
    this.postService.getPostById(postId).subscribe((post) => {
      this.post1 = post;
      if (post.videoLink) {
        var documentLink = post.videoLink;
        this.getVideoType(documentLink, this.post1);
      }
      this.post1.isPdf = false;
      if (this.post1.file != null && this.post1.file != "") {
        let mimeType = this.post1.file.match(/[^:/]\w+(?=;|,)/)[0];
        this.post1.isPdf = this.extFileList.some(ext => mimeType.includes(ext));
        this.post1.isImage = this.extList.some(ext => mimeType.includes(ext));
        this.post1.isDoc = this.extDocList.some(ext => mimeType.includes(ext));

        if (this.post1.isPdf == true) {
          this.post1.cover = this.sanitizer.bypassSecurityTrustResourceUrl(this.post1.file);
          const base64String = this.post1.cover.toString();
          var stringLength = base64String.length - 'data:application/pdf;base64,'.length;
          var sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
          var sizeInKb = sizeInBytes / 1000;
          if (sizeInKb > 1500) {
            this.post1.isLarge = true;
          }
          else {
            this.post1.isLarge = false;
          }
        }
        else if (this.post1.isDoc == true) {
          this.post1.cover = this.sanitizer.bypassSecurityTrustResourceUrl(this.post1.file);
        }
        else {
          this.post1.cover = this.post1.file;
        }
      }
      this.fileService.getFiles(postId, "post").subscribe((photos) => {
        this.photos = photos;
      });
    });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  confirmDelete(postId) {
    this.openModal("custom-modal-delete-post");
    this.postId = postId;
  }

  deletePost() {
    this.postService.deletePost(this.deletePostId).subscribe(
      (data) => {
        this.modalService.close("custom-modal-delete-post");
        window.location.reload();
      },
      (error) => { }
    );
  }
  likePost(postId: any) {
    this.postService.getPostById(postId).subscribe((post) => {
      const postinfo = new Postliked();
      postinfo.isliked = !post.isLiked;
      postinfo.addressIp = this.userIP;
      postinfo.postId = postId;
      this.postService.likePost(postId, postinfo).subscribe((data) => {
        this.getAllPosts(this.p);
      });
    });
  }
  activateProgressBar() {
    this.mode = "determinate";
  }
  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }
  likePostModal(postId: any) {
    this.postService.getPostById(postId).subscribe((post) => {
      const postinfo = new Postliked();
      postinfo.isliked = !post.isLiked;
      postinfo.addressIp = this.userIP;
      postinfo.postId = postId;
      this.postService.likePost(postId, postinfo).subscribe((data) => {
        this.postService.getAllPosts(this.i18n).subscribe((res) => {
          this.posts = res;
          this.posts.forEach((value, index) => {
            this.posts[index].isPdf = false;
            if (res[index].file != null && res[index].file != "") {
              this.posts[index].isPdf = res[index].file.includes("pdf");
              if (this.posts[index].isPdf == true) {
                this.posts[index].cover = this.sanitizer.bypassSecurityTrustResourceUrl(res[index].file);
              }
              else {
                this.posts[index].cover = res[index].file;
              }
            }
          });
        });
        this.postService.getPostById(postId).subscribe((postData) => {
          this.post1 = postData;
          this.post1.isPdf = false;
          if (this.post1.file != null && this.post1.file != "") {
            this.post1.isPdf = this.post1.file.includes("pdf");
            if (this.post1.isPdf == true) {
              this.post1.cover = this.sanitizer.bypassSecurityTrustResourceUrl(this.post1.file);
            }
            else {
              this.post1.cover = this.post1.file;
            }
          }
        });
      });
    });
  }
  openDeleteModal(id: string, postId: any) {
    this.modalService.open(id);
    this.deletePostId = postId;
    this.postService.getPostById(postId).subscribe(
      (data) => {
        this.deletedPost = data;
      },
      (error) => { }
    );
  }
  openEditModal(id: string, postId: any) {
    this.modalService.open(id);
    this.updatedPostId = postId;
    this.postService.getPostById(this.updatedPostId).subscribe(
      (data) => {
        this.updatedPost = data;
        this.initForm(this.updatedPost);
        if (this.updatedPost.forChildren) {
          this.postForm.patchValue({ categoryType: "Children" });
        }
        else {
          this.postForm.patchValue({ categoryType: "Adult" });
        }
      },
      (error) => { }
    );
  }
  editPost() {
    this.submitted = true;
    // if form is invalid stop here !
    if (this.postForm.invalid) {
      if (this.postForm.controls.categoryType.value === "Adult") {
        this.postForm.patchValue({ childCategoryId: null });
        this.postForm.controls["childCategoryId"].clearValidators();
      }
      else {
        return;
      }
    }

    if (this.postForm.controls.categoryType.value === "Adult") {
      this.postForm.controls.childCategoryId.setValue(null);
      this.postForm.controls.forChildren.setValue(false);
      this.postForm.controls["childCategoryId"].clearValidators();
    }
    if (this.postForm.controls.categoryType.value === "Children") {
      this.postForm.controls.categoryId.setValue(null);
      this.postForm.controls.forChildren.setValue(true);
      this.postForm.controls["categoryId"].clearValidators();
    }
    this.postService
      .updatePost(this.updatedPostId, this.postForm.value)
      .subscribe(
        (data) => {
          if (this.selectedFiles != null) {
            this.upload();
          }
          else {
            window.location.reload();
          }
        },
        (error) => { }
      );
  }
  getUrl(id) {
    return (
      "https://www.facebook.com/sharer/sharer.php?u=" +
      this.url +
      "/post/detail-post/" +
      id +
      "/&amp;src=sdkpreparse"
    );
  }
  upload(): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFile = this.selectedFiles.item(i);
      const formData = new FormData();
      formData.append("targetType", "post");
      formData.append("targetId", JSON.stringify(this.updatedPostId));
      formData.append("file", this.currentFile);
      formData.append("cover", this.updatedPost.cover);
      this.fileService.udpateFile(formData).subscribe(
        (res) => {
          if (res) {
            window.location.reload();
          }
        },
        (err) => { }
      );
    }
  }
  loadIp() {
    this.http.get("https://jsonip.com").subscribe(
      (value: any) => {
        this.userIP = value.ip;
      },
      (error) => { }
    );
  }
  initForm(oldpost: Post) {
    this.postForm = this.fb.group({
      title: [oldpost.title, Validators.required],
      content: [oldpost.content, Validators.required],
      forChildren: [oldpost.forChildren],
      categoryId: [oldpost.categoryId, this.requiredIfAdult],
      childCategoryId: [oldpost.childCategoryId, this.requiredIfChildren],
      categoryType: ["", Validators.required],
    });
  }
  get f() {
    return this.postForm.controls;
  }
  requiredIfAdult(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get("categoryType").value == "Adult") {
      return Validators.required(formControl);
    }
  }
  requiredIfChildren(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get("categoryType").value == "Children") {
      return Validators.required(formControl);
    }
  }
  pageChanged($event) {
    this.p = $event;
    if (this.getDocumentBy == 'all') {
      this.getAllPosts($event);
    }
    else if (this.getDocumentBy == 'category') {
      this.searchPostByCategory($event);
    }
    else if(this.getDocumentBy == 'title'){
      this.searchPostByTitle($event);
    }
  }
}
