import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Post } from "../../../pojo/Post";
import { PostService } from "../../../services/post.service";
import { GenericComponent } from "../../../GenericComponent";
import { LocalService } from "../../../services/LocalService";
import { I18NService } from "../../../services/I18NService";
import { CategoryBlogService } from "../../../services/category-blog.service";
import { CategoryBlog } from "../../../pojo/CategoryBlog";
import { TokenStorageService } from "../../../services/token-storage.service";
import { Files } from "../../../pojo/Files";
import { UploadFileService } from "../../../services/upload-file.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ModalService } from "../../../common-elements/_modal";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChildCategoryService } from "../../../services/child-category.service";
import { ChildCategory } from "../../../pojo/ChildCategory";
import { Router } from '@angular/router';

@Component({
  selector: "app-add-post",
  templateUrl: "./add-post.component.html",
  styleUrls: ["./add-post.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPostComponent extends GenericComponent implements OnInit {
  post: Post;
  appsUI: any = {};
  categories: CategoryBlog[];
  childCategories: ChildCategory[];
  catId: any;
  selectedFiles: FileList;
  currentFile: File;
  message = "";
  forChildren : boolean = false ;

  fileInfos: Files[];
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  visible: boolean;
  mode: string = "indeterminate";

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: " ",
    translate: "no",
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  postForm: FormGroup;
  fileType: string;
  submitted = false;
  isTeenager: boolean;
  constructor(
    private localService: LocalService,
    private i18NService: I18NService,
    private postService: PostService,
    private categoryBlogService: CategoryBlogService,
    private token: TokenStorageService,
    private uploadService: UploadFileService,
    private modalService: ModalService,
    private fb: FormBuilder,
    private router: Router,
    private childCategoryService: ChildCategoryService
  ) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui().apps;
  }
  updateFileType(e) {
    this.postForm.patchValue({ fileType: e.value });
  }
  ngOnInit() {
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.childCategoryService.getAllCategories().subscribe((res) => {
      this.childCategories = res;
    })
    this.isAdmin = this.roles.includes("ROLE_ADMIN");
    this.visible = false;
    this.categoryBlogService.getAllCategories().subscribe((res) => {
      this.categories = res;
    });
    this.post = new Post();
    this.post.createdDate = Date.now();
    // initialise the post form control
    this.initForm();
  }
  activateProgressBar() {
    this.mode = "determinate";
  }

  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  addPost() {
    this.submitted = true;
    if(this.postForm.invalid){
      this.postForm.controls.childCategoryId.reset();
      this.postForm.controls.categoryId.reset();
      return;
    }
    if (this.postForm.controls.categoryType.value === 'Children'){
      this.postForm.patchValue({forChildren:true});
      this.forChildren = true ;
    }
    else{
      this.postForm.patchValue({forChildren:false});
      this.forChildren = false ;
    }
    this.openModal("custom-modal-loading-post");
    this.postService.addPost(this.postForm.value).subscribe(
        (res) => {
          this.post = res;
          if (this.selectedFiles != null) {
            this.upload();
          }
          else {
            this.closeModal("custom-modal-loading-post");
            this.openModal("custom-modal-confirm-add-post");
          }
        },
        (error) => { }
    );
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }
  upload(): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFile = this.selectedFiles.item(i);
      const formData = new FormData();
      formData.append("targetType", "post");
      formData.append("targetId", JSON.stringify(this.post.postId));
      formData.append("file", this.currentFile);
      this.uploadService.upload(formData).subscribe(
        (event) => {
          if(event['add file'] == 'true'){
            this.closeModal("custom-modal-loading-post");
            this.openModal("custom-modal-confirm-add-post");
          }
        },
        (err) => { }
      );
    }
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  initForm() {
    this.postForm = this.fb.group({
      title: ["", Validators.required],
      content: ["", Validators.required],
      forChildren: [""],
      categoryId: ["", this.requiredIfAdult],
      cover: [""],
      categoryType: ["", Validators.required],
      childCategoryId: ["",this.requiredIfChildren],
      lang: ["", Validators.required],
      isTeenager:["", this.requiredIfChildren],
      videoLink: [""],
      fileType: ["", Validators.required]
    });
  }
  get f() {
    return this.postForm.controls;
  }

  requiredIfAdult(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get("categoryType").value == "Adult") {
      return Validators.required(formControl);
    }
  }
  requiredIfChildren(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get("categoryType").value == "Children") {
      return Validators.required(formControl);
    }
  }
  goToChildBlog() {
      this.isTeenager = this.postForm.controls.isTeenager.value;
      this.closeModal("custom-modal-confirm-add-post");
      this.router.navigate(['/blog/children/isteenager/'+ this.isTeenager]);
  }
  goToAdultBlog() {
      this.closeModal("custom-modal-confirm-add-post");
      this.router.navigate(['/post/posts']);
  }
}
