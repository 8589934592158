import { Component, Inject, OnInit } from '@angular/core';
import { ReportService } from '../../../services/report.service';
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ActivatedRoute } from '@angular/router';
import { ReportTypeService } from '../../../services/report-type.service';
import { Report } from "../../../pojo/Report";
import { ModalService } from '../../../common-elements/_modal';
import { DomSanitizer } from "@angular/platform-browser";
import { ReportdpeService } from '../../../services/reportdpe.service';

@Component({
  selector: 'app-list-report',
  templateUrl: './list-report.component.html',
  styleUrls: ['./list-report.component.css']
})
export class ListReportComponent extends GenericComponent implements OnInit {

  constructor(public sanitizer: DomSanitizer, private reportTypeService: ReportTypeService, private modalService: ModalService, private reportService: ReportService, private reportServiceDpe: ReportdpeService,
    @Inject('username') private username : string,@Inject('password') private password : string,private token: TokenStorageService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
    this.username = username;
    this.password = password;
  }

  reportList: any[];
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  updateReport: Report;
  reportId: any;
  stateList: Array<any> = ["à lire", "urgent", "en cours de résolution", "résolu"];
  gouvernoratList: Array<any> = [];
  reportTypeList: Array<any> = [];
  searchGouvernorat: any;
  reportTypeId: any;
  p: number = 1;
  reportMessage: any;
  reportDetails: Report;
  isVideo: boolean;
  isPdf: boolean = false;
  accessToken: string;


  ngOnInit() {
    this.updateReport = new Report();
    this.reportDetails = new Report();
    this.gouvernoratList = [this.appsUI.Ariana[this.i18n], this.appsUI.Beja[this.i18n], this.appsUI.BenArous[this.i18n]
      , this.appsUI.Bizerte[this.i18n], this.appsUI.Gabès[this.i18n], this.appsUI.Gafsa[this.i18n], this.appsUI.Jendouba[this.i18n]
      , this.appsUI.Kairouan[this.i18n], this.appsUI.Kasserine[this.i18n], this.appsUI.Kebili[this.i18n], this.appsUI.Kef[this.i18n]
      , this.appsUI.Mahdia[this.i18n], this.appsUI.Manouba[this.i18n], this.appsUI.Médenine[this.i18n], this.appsUI.Monastir[this.i18n]
      , this.appsUI.Nabeul[this.i18n], this.appsUI.Sfax[this.i18n], this.appsUI.SidiBouzid[this.i18n], this.appsUI.Siliana[this.i18n]
      , this.appsUI.Sousse[this.i18n], this.appsUI.Tataouine[this.i18n], this.appsUI.Tozeur[this.i18n], this.appsUI.Tunis[this.i18n]
      , this.appsUI.Zaghouan[this.i18n]];
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.reportService.getAllReports().subscribe(
      data => {
        this.reportList = data;
        this.reportList.forEach((value, index) => {
          this.reportList[index].isPdf = false;
          if (this.reportList[index].file != null && this.reportList[index].file != "") {
            this.reportList[index].isPdf = this.reportList[index].file.includes("pdf");
            this.reportList[index].file = this.sanitizer.bypassSecurityTrustResourceUrl(this.reportList[index].file);
          }
        });
      },
      error => {
        console.log(error);
      }
    );
    this.reportTypeService.getAllReportTypes().subscribe(
      data => {
        this.reportTypeList = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  editReportState(reportId) {
    this.reportId = reportId;
    this.reportService.getReportById(reportId).subscribe(
      data => {
        this.updateReport = data;
        this.openModal('custom-modal-edit-state');
      },
      error => {
        console.log(error);
      }
    );
  }
  openModalReportFile(id: string, report: any, isVideo: boolean) {
    this.isVideo = isVideo;
    this.reportDetails = report;
    this.modalService.open(id);
  }
  confrirmEdit() {
    this.reportService.updateReportState(this.reportId, this.updateReport.state).subscribe(
      data => {
        this.closeModal('custom-modal-edit-state');
        window.location.reload();
      },
      error => {
        console.log(error);
      }
    );
  }
  searchReportByGovernorate() {
    this.reportService.getReportByGovernorate(this.searchGouvernorat).subscribe(
      data => {
        this.reportList = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  searchReportByReportType() {
    this.reportService.getReportByReportTypeId(this.reportTypeId).subscribe(
      data => {
        this.reportList = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  confirmDelete(id) {
    this.openModal('custom-modal-delete-report');
    this.reportId = id;
  }
  deleteReport() {
    this.reportService.deleteReport(this.reportId).subscribe(
      data => {
        this.modalService.close('custom-modal-delete-report');
        window.location.reload();
      },
      error => {
        console.log(error);
      }
    );
  }
  detailsReportMessage(id) {
    this.reportService.getReportById(id).subscribe(
      data => {
        this.reportMessage = data.message;
        this.openModal('custom-modal-show-report-message');
      },
      error => {
        console.log(error);
      }
    );
  }

  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  sendReportToSi(report){
    report.hasbeensent = true;
    this.reportService.updateReport(report.reportId,report).subscribe(
      data => {
        const payload = {
          username: this.username,
          password: this.password
        }
        this.reportServiceDpe.authenticateSi(payload).subscribe(res => {
          this.accessToken = res['accessToken'];
          this.reportServiceDpe.addReportToSi(this.accessToken,report.name,report.email,report.address,report.governorate,report.message).subscribe(res => {
          }, error => {
            console.log (error);
          });
        }, error => {
          console.log (error);
        });
      },
      error => {
        console.log(error);
      }
    );



  }

}
