import { Component, OnInit } from '@angular/core';
import {GenericComponent} from "../../GenericComponent";
import {TokenStorageService} from "../../services/token-storage.service";
import {LocalService} from "../../services/LocalService";
import {I18NService} from "../../services/I18NService";

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent extends GenericComponent implements OnInit {
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  constructor(private token: TokenStorageService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  ngOnInit() {
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
  }

}
