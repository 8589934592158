import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class approvalService {

 private approvalStageState = new BehaviorSubject(false);
 currentApprovalStageState = this.approvalStageState.asObservable();

 constructor() {

 }
 updateApprovalState(state: boolean) {
 this.approvalStageState.next(state)
 }
}
