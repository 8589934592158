import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../services/report.service';
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportTypeService } from '../../../services/report-type.service';
import { Report } from "../../../pojo/Report";
import { ModalService } from "../../../common-elements/_modal";
import { UploadFileService } from '../../../services/upload-file.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.css']
})
export class AddReportComponent extends GenericComponent implements OnInit {

  constructor(private reportService: ReportService, private reportTypeService: ReportTypeService, private localService: LocalService, private i18NService: I18NService, private uploadService: UploadFileService, private modalService: ModalService, private router: Router) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  reportTypeList: any[];
  report: Report;
  createdReport: Report;
  appsUI: any = {};
  roles: string[] = [];
  selectedFiles: FileList;
  currentFile: File;
  fileType: string;
  formInvalid: boolean = false;
  gouvernoratList: Array<any> = [];
  ageRangeList = [
    { name: "babe", min: 0, max: 3 },
    { name: "child", min: 4, max: 8 },
    { name: "schoolChild", min: 8, max: 12 },
    { name: "collegeStudent", min: 12, max: 15 },
    { name: "young", min: 15 }
  ]
  selected: string;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: " ",
    translate: "no",
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

  visible: boolean;
  mode: string = "indeterminate";
  isLinear = false;

  ngOnInit() {
    this.report = new Report();
    this.visible = false;
    this.createdReport = new Report();
    this.gouvernoratList = [this.appsUI.Ariana[this.i18n], this.appsUI.Beja[this.i18n], this.appsUI.BenArous[this.i18n]
      , this.appsUI.Bizerte[this.i18n], this.appsUI.Gabès[this.i18n], this.appsUI.Gafsa[this.i18n], this.appsUI.Jendouba[this.i18n]
      , this.appsUI.Kairouan[this.i18n], this.appsUI.Kasserine[this.i18n], this.appsUI.Kebili[this.i18n], this.appsUI.Kef[this.i18n]
      , this.appsUI.Mahdia[this.i18n], this.appsUI.Manouba[this.i18n], this.appsUI.Médenine[this.i18n], this.appsUI.Monastir[this.i18n]
      , this.appsUI.Nabeul[this.i18n], this.appsUI.Sfax[this.i18n], this.appsUI.SidiBouzid[this.i18n], this.appsUI.Siliana[this.i18n]
      , this.appsUI.Sousse[this.i18n], this.appsUI.Tataouine[this.i18n], this.appsUI.Tozeur[this.i18n], this.appsUI.Tunis[this.i18n]
      , this.appsUI.Zaghouan[this.i18n]];
    this.reportTypeService.getAllReportTypes().subscribe(
      data => {
        this.reportTypeList = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }
  activateProgressBar() {
    this.mode = "determinate";
  }

  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  upload(): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFile = this.selectedFiles.item(i);
      const formData = new FormData();
      formData.append('targetType', 'report');
      formData.append('targetId', JSON.stringify(this.createdReport.reportId));
      formData.append('file', this.currentFile);
      this.uploadService.upload(formData).subscribe(
        event => { },
        err => { });
    }
  }
  testaddReport() {
    if (this.report.address === undefined || this.report.governorate === undefined || this.report.reportTypeId === undefined || this.selected === undefined || this.report.message === undefined) {
      this.formInvalid = true;
    }
    else {
      this.addReport();
    }
  }
  moveToHome(){
    this.router.navigate(['/home']);
  }
  addReport() {
    for (let i = 0; i < this.ageRangeList.length; i++) {
      if (this.ageRangeList[i].name == this.selected) {
        this.report.minAge = this.ageRangeList[i].min;
        this.report.maxAge = this.ageRangeList[i].max;
      }
    }
    this.reportService.addReport(this.report).subscribe(
      data => {
        this.createdReport = data;
        if (this.selectedFiles != null) {
          this.upload();
        }
        this.reportService.sendMailToAdmin(this.createdReport).subscribe(
          dataF => {

          },
          error => {
            console.log(error);
          }
        );
        this.reportService.sendMailFunction(this.createdReport).subscribe(
          dataF => {

          },
          error => {
            console.log(error);
          }
        );
        this.openModal('custom-modal-confirm-add');
      },
      error => {
        console.log(error);
      }
    );
  }
  AgeRangeChanged(value) {
    this.selected = value;
  }
  change() {
    this.report.name = "";
    this.report.email = "";
  }
  closeModal(id: string) {
    this.modalService.close(id);
    this.router.navigate(['/home']);
  }

}
