export class Document {
  documentId: number;
  title: string;
  content: string;
  categoryId: any;
  docSubCategoryID: any;
  fileUrl: any;
  videoLink: string;
  isFile: boolean;
  isImage: boolean;
  fileType: string;
  lang: string;
  isPdf : boolean = false ;
  isLarge : boolean = false ;
  isDoc: boolean =false;
  isXls: boolean = false;
  videoType: string ;

  constructor() {}
}
