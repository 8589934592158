import { Component, OnInit } from '@angular/core';
import {DocCategory} from "../../../pojo/DocCategory";
import { DocCategoryService } from '../../../services/doc-category.service';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';

@Component({
  selector: 'app-add-doc-category',
  templateUrl: './add-doc-category.component.html',
  styleUrls: ['./add-doc-category.component.css']
})
export class AddDocCategoryComponent extends GenericComponent implements OnInit {

  docCategory: DocCategory;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};

  constructor(private localService: LocalService, private i18NService: I18NService, private token: TokenStorageService, private docCategoryService: DocCategoryService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.docCategory = new DocCategory();
  }
  addDocCategory(){
    this.docCategoryService.addDocCategory(this.docCategory).subscribe(
      data => {
      },
      error => {
        console.log(error);
      }
    );
  }

}
