import { Component, OnInit } from '@angular/core';
import {Question} from "../../../pojo/Question";
import { QuestionService } from '../../../services/question.service';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ModalService} from "../../../common-elements/_modal";
import { Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule
} from "@angular/forms";

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.css']
})
export class AddQuestionComponent extends GenericComponent implements OnInit {

  faq: Question;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  name = 'Angular 6';
  content = '';
  questionForm: FormGroup;
  submitted : boolean = false ;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: ' ',
    translate: 'no',
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };


  constructor(private router: Router,private token: TokenStorageService, private fb: FormBuilder, private questionService: QuestionService, private localService: LocalService, private i18NService: I18NService, private modalService: ModalService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  initForm() {
    this.questionForm = this.fb.group({
      question: ["", Validators.required],
      response: ["", Validators.required],
      isChild: ["", Validators.required],
      lang: ["", Validators.required],
      isTeenager: ["", this.requiredIfChildren]
    });
  }
  ngOnInit() {
    this.faq = new Question();
    this.initForm();
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
  }
  closeModal(id: string) {
    this.modalService.close(id);

  }
   openModal(id: string) {
     this.modalService.open(id);

   }
  addFaq(){
    this.submitted = true;
    if(this.questionForm.valid){
      if(this.questionForm.controls.isChild.value === 'true' && this.questionForm.controls.isTeenager.value === 'true'){
        this.questionForm.patchValue({isTeenager:true});
      }else{
        this.questionForm.patchValue({isTeenager:false});
      }
      this.questionService.addQuestion(this.questionForm.value).subscribe(
        data => {
          this.closeModal('custom-modal-add-question');
          this.openModal('custom-modal-confirm-add');
          window.location.reload();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  requiredIfChildren(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get("isChild").value == "true") {
      return Validators.required(formControl);
    }
  }
}
