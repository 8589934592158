export class ServiceSubCategory {
  servSubCategoryID: number;
  servSubCategoryFr: string;
  servSubCategoryAr: string;
  servSubCategoryEn: string;
  createdDate:Date;
  categoryId: number;
  constructor() {
  }
}
