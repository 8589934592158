import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private DCAF_WS_API : string = "";

  constructor(private http: HttpClient, @Inject('conf') private conf : string) {
    this.DCAF_WS_API = conf +'/home' ;
  }
  getHome(lang:string): Observable<any> {
    return this.http.get(this.DCAF_WS_API + '/public/' + lang);
  }
  addHome(home: any): Observable<any> {
    return this.http.post(this.DCAF_WS_API , home);
  }
  updateHome(id: any,home:any): Observable<any> {
    return this.http.put(this.DCAF_WS_API+'/update/'+id , home);
  }
}
