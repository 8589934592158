import { Component, OnInit } from '@angular/core';
import {Contact} from "../../../pojo/Contact";
import { ContactService } from '../../../services/contact.service';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import {ModalService} from "../../../common-elements/_modal";

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent extends GenericComponent implements OnInit {

  contact: Contact;
  oldcontact: Contact;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};

  constructor(private token: TokenStorageService, private contactService: ContactService, private localService: LocalService, private i18NService: I18NService, private modalService:ModalService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.contact = new Contact();
    this.oldcontact = new Contact();
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.contactService.getContact(this.i18n).subscribe(
      data => {
        this.oldcontact = data[0];
      },
      error => {
        console.log(error);
      }
    );
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  addContact(){
    this.contactService.addContact(this.contact).subscribe(
      data => {
        this.openModal('custom-modal-confirm-add');
      },
      error => {
        console.log(error);
      }
    );
  }
}
