import { Component, OnInit } from '@angular/core';
import { ContactForm } from "../../../pojo/ContactForm";
import { ContactFormService } from '../../../services/contact-form.service';
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { Contact } from "../../../pojo/Contact";
import { ContactService } from '../../../services/contact.service';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ModalService } from "../../../common-elements/_modal";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule
} from "@angular/forms";
@Component({
  selector: 'app-send-contact-form',
  templateUrl: './send-contact-form.component.html',
  styleUrls: ['./send-contact-form.component.css']
})
export class SendContactFormComponent extends GenericComponent implements OnInit {

  contactForm: ContactForm;
  appsUI: any = {};
  contact: Contact;
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  sendContactForm: FormGroup;
  submitted: boolean = false;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: " ",
    translate: "no",
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  constructor(private modalService: ModalService, private token: TokenStorageService, private fb: FormBuilder, private contactService: ContactService, private contactFormService: ContactFormService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }
  initForm() {
    this.sendContactForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      subject: ["", Validators.required],
      message: ["", Validators.required]
    });
  }
  ngOnInit() {
    this.initForm();
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.contact = new Contact();
    this.contactForm = new ContactForm();
    this.contactService.getContact(this.i18n).subscribe(
      data => {
        this.contact = data[0];
      },
      error => {
        console.log(error);
      }
    );
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  sendContactEmail() {
    this.submitted = true;
    if (this.sendContactForm.valid) {
      setTimeout(() => {
        this.openModal('custom-modal-confirm-send');
      }, 2000);
      this.contactFormService.sendContactForm(this.sendContactForm.value).subscribe(
        data => {
          //this.openModal('custom-modal-confirm-send');
        },
        error => {
          console.log(error);
        }
      );
    }
  }

}
