import { Component, OnInit } from '@angular/core';
import {DocCategory} from "../../../pojo/DocCategory";
import { DocCategoryService } from '../../../services/doc-category.service';
import { ModalService } from '../../../common-elements/_modal';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';

@Component({
  selector: 'app-list-doc-category',
  templateUrl: './list-doc-category.component.html',
  styleUrls: ['./list-doc-category.component.css']
})
export class ListDocCategoryComponent extends GenericComponent implements OnInit {

  categories: any[];
  categoryId : any;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};

  constructor(private localService: LocalService, private i18NService: I18NService, private token: TokenStorageService, private modalService: ModalService, private docCategoryService: DocCategoryService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.docCategoryService.getAllDocCategories().subscribe(
      data => {
        this.categories = data;
      },
      error => {
        console.log(error);
      }
    )
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  confirmDelete(categoryId){
    this.openModal('custom-modal-delete-cat');
    this.categoryId = categoryId;
  }
  deleteDocCategory(){
    this.docCategoryService.deleteDocCategory(this.categoryId).subscribe(
      data => {
        this.modalService.close('custom-modal-delete-cat');
      },
      error => {
        console.log(error);
      }
    );
  }
}
