import { Component, OnInit } from '@angular/core';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { UserService } from '../../../services/user.service';
import {Credentials} from "../../../pojo/Credentials";
import { TokenStorageService } from '../../../services/token-storage.service';
import {ModalService} from "../../../common-elements/_modal";
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends GenericComponent implements OnInit {

  appsUI: any = {};
  credentials : Credentials ;
  roles: string[] = [];
  rememberMe = false;
  emailError = false ;
  email : string ;
  isLoginFailed = false;

  constructor(private modalService:ModalService, private tokenStorage: TokenStorageService, private userService: UserService, private localService: LocalService, private i18NService: I18NService){
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
      this.credentials = new Credentials();
  }

  login(){
    this.userService.login(this.credentials).subscribe(
      data => {
          if (this.rememberMe){
            this.tokenStorage.ActiveRememberMe(data);
          }
          this.tokenStorage.saveToken(data.accessToken);
          this.tokenStorage.saveUser(data);
          this.roles = this.tokenStorage.getUser().roles;
          this.isLoginFailed = false;
          window.location.reload();
      },
      error => {
        this.isLoginFailed = true ;
        console.log(error);
      }
    );
  }
  openModal(id: string, idP: string) {
    this.modalService.close(idP);
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  forgetpassword(){
    let emailparams = new HttpParams().set('email', this.email);
    this.userService.resetPassword(emailparams).subscribe(
      data => {
      },
      error => {
        console.log(error);
      }
    );
  }
}
