import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceSubCategory } from '../pojo/ServiceSubCategory';

@Injectable({
  providedIn: 'root'
})
export class ServiceSubCategoryService {

  private DCAF_WS_API : string = "";

  constructor(private http: HttpClient, @Inject('conf') private conf : string) {
         this.DCAF_WS_API = conf +'/servSubCategory/' ;
  }

  addServiceSubCategory(subcategory: ServiceSubCategory): Observable<any> {
         return this.http.post(this.DCAF_WS_API + "add" , subcategory);
  }
  getAllServicesSubCategories(): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/all");
  }
  getAllServicesSubCategoriesPic(language:string): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/all/" + language);
  }

  getServiceSubCategoryById(id: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API +"public/subcateg/"+id);
  }
  updateServSubCategory(idC: number , subcategory: ServiceSubCategory): Observable<any> {
    return this.http.put(this.DCAF_WS_API + "update/"+ idC , subcategory);
  }

  deleteServiceSubCategory(id: number): Observable<any> {
    const url = `${this.DCAF_WS_API}${id}`;
    return this.http.delete(url);
  }
  getAllSubCategByCategId(id:number,language:string): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/subcateg/" + id + "/" + language);
  }

}
