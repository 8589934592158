export class User {
  infoId: number;
  firstname: string;
  lastname: string;
  birthday: string;
  address: string;
  gender: string;
  state: string;
  email: string;
  job: string;
  phone: string;
  userId: number;
  password: string;
  username: string;
  role: string ;

  constructor() {
  }
}
