import { Component, OnInit } from '@angular/core';
import { ReportTypeService } from '../../../services/report-type.service';
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ModalService } from '../../../common-elements/_modal';
import { ReportType } from "../../../pojo/ReportType";

@Component({
  selector: 'app-list-report-type',
  templateUrl: './list-report-type.component.html',
  styleUrls: ['./list-report-type.component.css']
})
export class ListReportTypeComponent extends GenericComponent implements OnInit {

  constructor(private modalService: ModalService, private reportTypeService: ReportTypeService, private token: TokenStorageService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  reportTypeList: any;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  reportTypeId: any;
  reportTypeUp: ReportType;
  private reportTypeName: string;

  ngOnInit() {
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.reportTypeUp = new ReportType();
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.reportTypeService.getAllReportTypes().subscribe(
      data => {
        this.reportTypeList = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  openEditModal(reportTypeId, typeName) {
    this.openModal('custom-modal-edit-report-type');
    this.reportTypeId = reportTypeId;
    this.reportTypeService.getReportTypeById(this.reportTypeId).subscribe(
      data => {
        console.log(data);
        this.reportTypeUp = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  editReportType() {
    this.reportTypeService.updateReportType(this.reportTypeId, this.reportTypeUp).subscribe(
      data => {
        this.modalService.close('custom-modal-edit-report-type');
        this.reportTypeList = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  confirmDelete(reportTypeId) {
    this.openModal('custom-modal-delete-type');
    this.reportTypeId = reportTypeId;
  }
  deleteReportType() {
    this.reportTypeService.deleteReportType(this.reportTypeId).subscribe(
      data => {
        this.modalService.close('custom-modal-delete-type');
        this.reportTypeList = data;
      },
      error => {
        console.log(error);
      }
    );
  }
}
