import { Component, OnInit } from '@angular/core';
import { Contact } from "../../../pojo/Contact";
import { ContactService } from '../../../services/contact.service';
import { I18NService } from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import { GenericComponent } from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import { ModalService } from "../../../common-elements/_modal";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.css']
})
export class EditContactComponent extends GenericComponent implements OnInit {
  contact: Contact
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  currentFile: File;
  currentFiles: Blob[] = [];
  visible: boolean;
  mode: string = "indeterminate";
  addContactform: FormGroup;
  oldContact = new Contact();
  contactForm: FormGroup;
  videoId: any;
  contactCard :Contact
  private selectedLang: string;
  constructor(private modalService: ModalService, private token: TokenStorageService, private contactService: ContactService, private localService: LocalService, private i18NService: I18NService, private fb: FormBuilder) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.selectedLang = this.i18n;
    this.contactCard = new Contact();

    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.visible = false;
    this.contactService.getContact(this.i18n).subscribe(
      data => {
        this.contactCard = data[0];
      },
      error => {
        console.log(error);
      }
    );

    // Detail old contact
    this.getContactDetail();
    this.initForm();
    this.initFormData();
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
    window.location.reload();
  }
  initFormData() {
    this.oldContact = new Contact();
    this.oldContact.contactId = undefined;
    this.oldContact.address = "";
    this.oldContact.email = "";
    this.oldContact.phone = "";
    this.oldContact.lang = " ";
  }
  setradio(e: string): void {
    this.selectedLang = e;
    this.getContactDetail()
  }


  getContactDetail() {
    this.contactService.getContact(this.selectedLang).subscribe((res) => {
      this.oldContact = res[0];
      if (this.oldContact == null) {
        this.initFormData();
      }
    },
      error => { });
  }
  addContact() {
    this.contactService.addContact(this.contactForm.value).subscribe((res) => {
      this.openModal('custom-modal-confirm-add');
      window.location.reload();
    },
      error => { });
  }

  updateContact() {
    this.getContactDetail();
    if (this.oldContact.contactId == undefined) {
      this.addContact();
    }
    else {
      let id = this.oldContact.contactId;
      this.contactService.updateContact(this.oldContact).subscribe(
        (res) => {
          this.openModal('custom-modal-confirm-add');
          window.location.reload();
        }
      );
    }
  }
  initForm() {
    let addContactformControl = {
      address: new FormControl(""),
      email: new FormControl(""),
      phone: new FormControl(""),
      lang: new FormControl("")
    }
    this.contactForm = this.fb.group(addContactformControl);
  }

}
