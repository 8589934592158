import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/about' ;
    }
    addAbout(about: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API , about);
    }
    getAllAbout(lang:string): Observable<any> {
       return this.http.get(this.DCAF_WS_API + '/public/all/' + lang);
    }
    deleteAbout(id: number): Observable<any> {
       const url = `${this.DCAF_WS_API}/${id}`;
       return this.http.delete(url);
    }
    getAboutById(id: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + '/public/' + id);
    }
    updateAbout(id: any , about: any): Observable<any> {
       return this.http.put(this.DCAF_WS_API + '/update/'+  id , about);
    }
}
