import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class DocSubCategoryService {

   private DCAF_WS_API: string = "";

   constructor(private http: HttpClient, @Inject('conf') private conf: string) {
      this.DCAF_WS_API = conf + '/docsubcategory/';
   }
   getAllDocSubCategories(lang: string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "public/all/" + lang);
   }
   addSubDocCategory(category: any, lang: string): Observable<any> {
      return this.http.post(this.DCAF_WS_API + "add/" + lang, category);
   }
   deleteSubDocCategory(id: number, lang: string): Observable<any> {
      const url = `${this.DCAF_WS_API}${id}/${lang}`;
      return this.http.delete(url);
   }
   getSubCategoryById(id: any): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "public/"+ id);
   }
   updateSubCategory(id: any, category: any, lang: string): Observable<any> {
      return this.http.put(this.DCAF_WS_API + 'update/' + id + '/' + lang, category);
   }
   getSubCategoriesByCatId(catId: any, lang: string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "public/subcategories/" + catId + '/' + lang);
   }
}
