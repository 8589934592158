export class Contact {
  contactId: number;
  email: string;
  phone: string;
  address: string;
  lang: string;

  constructor() {
  }
}
