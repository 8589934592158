import { Component, OnInit } from '@angular/core';
import {UploadFileService} from "../../../services/upload-file.service";
import {Observable} from "rxjs";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {Files} from "../../../pojo/Files";

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';

  fileInfos:Files [];

  constructor(private uploadService: UploadFileService) { }

  ngOnInit() {
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
  }
  upload(): void {
    this.currentFile = this.selectedFiles.item(0);
    this.uploadService.upload(this.currentFile).subscribe(
      event => {
       console.log("OKKKK")
      },
      err => {
       console.log("ERROR")
      });
  }
}
