import {  HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportdpeService {

  private DCAF_WS_API : string = "";

  constructor(private http: HttpClient , @Inject('conf') private conf : string, @Inject('siconf') private siconf : string,private handler: HttpBackend) {
     this.DCAF_WS_API = conf ;

     this.http = new HttpClient(handler);
  }
   authenticateSi(payload){
      return this.http.post(this.DCAF_WS_API+'/si/login', payload);
    }

  addReportToSi(auth_token,name,email,address,governorate,message){
      const headers = new HttpHeaders()
      .set('Authorization', `${auth_token}`)
      .set('content-type', 'application/json')
      const body = {
         name: name,
         email: email,
         address: address,
         governorate: governorate,
         message: message
      }
      return this.http.post(this.DCAF_WS_API+'/si/addreport', body, { headers: headers });
    }
}
