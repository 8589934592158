import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RequestServiceService {

  private DCAF_WS_API : string = "";

  constructor(private http: HttpClient, @Inject('conf') private conf : string) {
    this.DCAF_WS_API = conf +'/requestService' ;
  }
  addRequestService(requestService: any): Observable<any> {
    return this.http.post(this.DCAF_WS_API  + "/public" , requestService);
  }
  deleteRequestService(id: number) {
    const url = `${this.DCAF_WS_API}/${id}`;
    return this.http.delete(url);
  }
  updateRequestService(id : any , service: any): Observable<any> {
    return this.http.put(this.DCAF_WS_API+ id , service);
  }
  getAllRequestService(): Observable<any> {
    return this.http.get(this.DCAF_WS_API );
  }
  getRequestServiceById(id: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API +"/"+ id);
  }
  getRequestServiceByState(state: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "/state/"+ state);
  }
}
