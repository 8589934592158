import {Component, OnInit} from '@angular/core';
import {Post} from "../../../pojo/Post";
import {PostService} from "../../../services/post.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GenericComponent} from "../../../GenericComponent";
import {LocalService} from "../../../services/LocalService";
import {I18NService} from "../../../services/I18NService";
import {CategoryBlogService} from "../../../services/category-blog.service";
import {CategoryBlog} from "../../../pojo/CategoryBlog";
import {TokenStorageService} from "../../../services/token-storage.service";

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.css']
})
export class EditPostComponent extends GenericComponent implements OnInit {
  post: Post;
  postId: any;
  appsUI: any = {};


  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};

  constructor(private localService: LocalService, private i18NService: I18NService, private postService: PostService, private route: ActivatedRoute, private token:TokenStorageService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }


  ngOnInit() {
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');

    this.route.params.subscribe(params => {
      this.postId = params['id'];
      this.postService.getPostById(this.postId).subscribe((post) => {
        this.post = post;
        if (!post.title) {
          this.post.title = '';
        }
        if (!post.content) {
          this.post.content = '';
        }
      });
    });
  }

  update() {
    this.postService.addPost(this.post).subscribe(res => {
      this.post = res;
      this.postId = this.post.postId;
    });
  }

    }
