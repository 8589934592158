import { Injectable, Inject } from "@angular/core";
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  private DCAF_WS_API: string = "";
  private params;

  constructor(private http: HttpClient, @Inject("conf") private conf: string) {
    this.DCAF_WS_API = conf + "/document/";
    this.params = new HttpParams();
  }
  setRequestParams(params: any){
    this.params = params;
  }
  addDocument(document: any): Observable<any> {
    return this.http.post(this.DCAF_WS_API, document);
  }
  getAllDocuments(lang: string, page: number): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/all/" + lang, {params: this.params});
  }
  getDocumentByTitle(title: any, language: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/title/" + title + "/" + language, {params: this.params});
  }

  getDocumentByCategoryId(categoryId: any, language: any): Observable<any> {
    return this.http.get(
      this.DCAF_WS_API + "public/categoryId/" + categoryId + "/" + language, {params: this.params}
    );
  }
  getDocumentBySubCategoryId(categoryId: any, language: any): Observable<any> {
    return this.http.get(
      this.DCAF_WS_API + "public/subcategoryId/" + categoryId + "/" + language, {params: this.params}
    );
  }
  downloadById(documentId: any, language: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/" + documentId + "/" + language +"/file");
  }
  getDocumentByDocumentId(documentId: any, language: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/" + documentId + "/" + language);
  }
  deleteDocument(id: number, lang: string): Observable<any> {
    const url = `${this.DCAF_WS_API}${id}/${lang}`;
    return this.http.delete(url);
  }
  updateDocument(id: any, document: any, language: string): Observable<any> {
    return this.http.put(
      this.DCAF_WS_API + "update/" + id + "/" + language,
      document
    );
  }
  getDocumentById(id: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "public/" +  id);
  }
}
