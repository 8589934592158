export class SubCategory {
  categoryId: number;
  categoryName: string;
  createdDate: string;
  docSubCategoryAr: string;
  docSubCategoryEn: string;
  docSubCategoryFr: string;
  docSubCategoryID: number;

  constructor() {
  }
}
