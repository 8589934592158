import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-outlet',
  templateUrl: './post-outlet.component.html',
  styleUrls: ['./post-outlet.component.css']
})
export class PostOutletComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
