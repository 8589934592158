import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import axios from "axios";

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  private DCAF_WS_API: string = "";

  constructor(private http: HttpClient, @Inject('conf') private conf: string) {
    this.DCAF_WS_API = conf + '/files';
  }

  upload(formData): Observable<any> {
    return this.http.post(this.DCAF_WS_API, formData);
  }
  udpateFile(formData): Observable<any> {
    return this.http.put(this.DCAF_WS_API, formData);
  }
  getFiles(targetId: any, targetType: any): Observable<any> {
    return this.http.get(this.DCAF_WS_API + "/public/id/" + targetId + "/type/" + targetType);
  }
  deleteFile(targetId: any, targetType: any): Observable<any> {
    return this.http.delete(this.DCAF_WS_API + "/id/" + targetId + "/type/" + targetType);
  }
  udpateHomeFile(formData): Observable<any> {
    return this.http.put(this.DCAF_WS_API+'/updateHome', formData);
  }
  udpateFileCateg(formData): Observable<any> {
    return this.http.put(this.DCAF_WS_API+'/category', formData);
  }
}
