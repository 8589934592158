import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { DocCategoryService } from "../../../services/doc-category.service";
import { I18NService } from "../../../services/I18NService";
import { LocalService } from "../../../services/LocalService";
import { GenericComponent } from "../../../GenericComponent";
import { TokenStorageService } from "../../../services/token-storage.service";
import { Document } from "../../../pojo/Document";
import { DocumentService } from "../../../services/document.service";
import { UploadFileService } from "../../../services/upload-file.service";
import { ModalService } from "../../../common-elements/_modal";
import { DocSubCategoryService } from "../../../services/doc-sub-category.service";
import { Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-add-document",
  templateUrl: "./add-document.component.html",
  styleUrls: ["./add-document.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDocumentComponent extends GenericComponent implements OnInit {
  categories: any[];
  subcategories: any[];
  appsUI: any = {};
  roles: string[] = [];
  isAdmin: boolean;
  currentUser: any = {};
  document: Document;
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  fileType: string;
  visible: boolean;
  mode: string = "indeterminate";
  docForm: FormGroup;
  submitted = false;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: " ",
    translate: "no",
    toolbarHiddenButtons: [["insertImage"],["insertVideo"],["link"],["unlink"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  constructor(
    private modalService: ModalService,
    private documentService: DocumentService,
    private localService: LocalService,
    private i18NService: I18NService,
    private token: TokenStorageService,
    private docCategoryService: DocCategoryService,
    private uploadService: UploadFileService,
    private docSubCategoryService: DocSubCategoryService,
    private fb: FormBuilder,
    private router: Router
  ) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
    //this.placeholder = this.appsUI.answer[this.i18n];
  }

  ngOnInit() {
    this.document = new Document();
    this.visible = false;
    if (this.token.getToken()) {
      this.roles = this.token.getUser().roles;
      this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
      this.roles = this.token.getUserRememberMe().roles;
      this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes("ROLE_ADMIN");
    // get category list
    this.docCategoryService.getAllDocCategories().subscribe((data) => {
      this.categories = data;
    });
    // initi form builder docForm
    this.initForm();
  }
  activateProgressBar() {
    this.mode = "determinate";
  }
  getCategoryId(categoryId) {
    this.docSubCategoryService.getSubCategoriesByCatId(categoryId, this.i18n).subscribe((data) => {
      this.subcategories = data;
    });
  }
  setIntrvl() {
    setInterval(() => this.activateProgressBar(), 1000);
  }
  selectFile(event): void {
    this.selectedFiles = event.target.files;
    this.visible = true;
    this.setIntrvl();
  }

  upload(): void {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.currentFile = this.selectedFiles.item(i);
      const formData = new FormData();
      formData.append("targetType", "media");
      formData.append("targetId", JSON.stringify(this.document.documentId));
      formData.append("file", this.currentFile);
      this.uploadService.upload(formData).subscribe((event) => {
        if(event['add file'] == 'true'){
          this.closeModal("custom-modal-loading");
          this.openModal("custom-modal-confirm-add-document");
        }
      });
    }
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  initForm() {
    this.docForm = this.fb.group({
      title: [""],
      content: [""],
      categoryId: ["", Validators.required],
      subcategoryId: ["", Validators.required],
      fileType: ["", Validators.required],
      videoLink: ["", this.requiredIfVideo],
      lang: ["", Validators.required],
    });
  }
  requiredIfVideo(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get("fileType").value == "video") {
      return Validators.required(formControl);
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.docForm.controls;
  }
  addDocument() {
    this.submitted = true;
    if(this.docForm.invalid){
    return;
    }
    //stop here if there is two media videolink and file
    if (this.submitted && this.selectedFiles && this.docForm.value.videoLink) {
      this.selectedFiles = undefined;
      this.docForm.patchValue({ videoLink: null });
      this.docForm.controls["videoLink"].clearValidators();
      return;
    }
    if (this.submitted && !this.selectedFiles && !this.docForm.value.videoLink) {
      return;
    }
    this.openModal("custom-modal-loading");
    this.documentService.addDocument(this.docForm.value).subscribe((data) => {
      this.document = data;

      if (this.selectedFiles != null) {
        this.upload();
      }
      else {
         this.closeModal("custom-modal-loading");
         this.openModal("custom-modal-confirm-add-document");
      }
      });
  }
  goToMediaLibrary() {
      this.closeModal("custom-modal-confirm-add-document");
      this.router.navigate(['/list-document']);
  }
  updateFileType(e) {
    this.docForm.patchValue({ fileType: e.value });
  }
}
