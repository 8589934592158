import { Component, OnInit } from '@angular/core';
import {Account} from "../../../pojo/Account";
import { AccountService } from '../../../services/account.service';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.css']
})
export class EditAccountComponent extends GenericComponent implements OnInit {

  account: Account;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};
  gouvernoratList: Array<any> = [];
  statusList: Array<any> = [];
  accountId : any ;
  enabled : any ;

  constructor(private route : ActivatedRoute, private token: TokenStorageService, private accountService: AccountService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.accountId = params.id ;
    });
    this.account = new Account();
    this.statusList = [this.appsUI.activated[this.i18n], this.appsUI.deactivated[this.i18n]];
    this.gouvernoratList = [this.appsUI.Ariana[this.i18n], this.appsUI.Beja[this.i18n], this.appsUI.BenArous[this.i18n]
    , this.appsUI.Bizerte[this.i18n] , this.appsUI.Gabès[this.i18n] , this.appsUI.Gafsa[this.i18n]  , this.appsUI.Jendouba[this.i18n]
    , this.appsUI.Kairouan[this.i18n] , this.appsUI.Kasserine[this.i18n] , this.appsUI.Kebili[this.i18n] , this.appsUI.Kef[this.i18n]
    , this.appsUI.Mahdia[this.i18n] , this.appsUI.Manouba[this.i18n] , this.appsUI.Médenine[this.i18n] , this.appsUI.Monastir[this.i18n]
    , this.appsUI.Nabeul[this.i18n] , this.appsUI.Sfax[this.i18n] , this.appsUI.SidiBouzid[this.i18n], this.appsUI.Siliana[this.i18n]
    , this.appsUI.Sousse[this.i18n] , this.appsUI.Tataouine[this.i18n], this.appsUI.Tozeur[this.i18n], this.appsUI.Tunis[this.i18n]
    , this.appsUI.Zaghouan[this.i18n]];
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.accountService.getAccountById(this.accountId).subscribe(
      data => {
      this.account.email = data.email;
      this.account.governorate = data.governorate;
      this.account.enabled = data.enabled;
      if(this.account.enabled == 1){
        this.enabled = true;
      }
      if(this.account.enabled == 0){
        this.enabled = false ;
      }
      },
      error => {
        console.log(error);
      }
    );
  }
  editAccount(){
    if (this.enabled){
      this.account.enabled = 1 ;
    }
    else {
      this.account.enabled = 0 ;
    }
    this.accountService.updateAccount(this.accountId , this.account).subscribe(
      data => {
      },
      error => {
        console.log(error);
      }
    );
  }
}
