import { Component, OnInit } from '@angular/core';
import {User} from "../../../pojo/User";
import { UserService } from '../../../services/user.service';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent extends GenericComponent implements OnInit {

  user: User;
  appsUI: any = {};

  constructor(private userService: UserService, private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  ngOnInit() {
    this.user = new User();
  }
  signup(){
    this.user.role = "user";
    this.userService.addUser(this.user).subscribe(
      data => {
      },
      error => {
        console.log(error);
      }
    );
  }
}
