import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

    private DCAF_WS_API : string = "";

    constructor(private http: HttpClient, @Inject('conf') private conf : string) {
       this.DCAF_WS_API = conf +'/service' ;
    }
    addService(service: any): Observable<any> {
       return this.http.post(this.DCAF_WS_API , service);
    }
    deleteService(id: number) {
       const url = `${this.DCAF_WS_API}/${id}`;
       return this.http.delete(url);
    }
    updateService(id : any , service: any): Observable<any> {
       return this.http.put(this.DCAF_WS_API + '/update/' + id , service);
    }
    getAllServices(lang:string): Observable<any> {
       return this.http.get(this.DCAF_WS_API + '/public/all/' + lang);
    }
    getServiceById(id: any,lang:string): Observable<any> {
       return this.http.get(this.DCAF_WS_API + '/public/' + id+"/"+lang);
    }
    getServiceByRegion(cityName: any, lang : any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "/public/city/"+ cityName + '/' + lang);
    }
    getServiceByTitle(title: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "/public/title/"+ title);
    }
    getServiceByTitleAndCityNme(title: any, cityName: any): Observable<any> {
       return this.http.get(this.DCAF_WS_API + "/public/titleAndRegion/"+ title + "/"+ cityName);
    }
    getServiceByCategoryId(idCateg: any): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "/public/categoryId/"+ idCateg);
    }
    getServiceByCategoryAndSubCateg(idSubCateg: any): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "/public/subcategoryId/"+ idSubCateg);
    }
    getServiceByCategAndRegion(cityName: any, categId : any, lang:string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "/public/city/"+ cityName + '/categ/' + categId+'/lang/'+lang);
    }

    getServiceByCategAndTitle( categId : number,title: string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "/public/title/"+ title + '/categ/' + categId);
    }

    getServiceByCategAndTitleAndRegion(categId:number,title:string,region:string, lang:string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "/public/categ/"+ categId+"/title/"+title+"/city/"+region+'/lang/'+lang);
    }

    getServiceByCategAndTitleAndRegionAndSubCateg(categId:number,title:string,region:string,subCategId:number, lang:string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "/public/categ/"+ categId+"/title/"+title+"/city/"+region+"/subcateg/"+subCategId+'/lang/'+lang);
    }

    getServiceBySubCategAndTitle(subcategId:number,categId:number,title:string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "/public/categ/"+ categId+"/subcateg/"+subcategId+"/title/"+title);
    }

    getServiceBySubCategAndCity(subcategId:number,categId:number,city:string, lang:string): Observable<any> {
      return this.http.get(this.DCAF_WS_API + "/public/categ/"+ categId+"/subcateg/"+subcategId+"/city/"+city+'/lang/'+lang);
    }
}
