import { Component, OnInit } from '@angular/core';
import { ReportTypeService } from '../../../services/report-type.service';
import { I18NService} from '../../../services/I18NService';
import { LocalService } from '../../../services/LocalService';
import {GenericComponent} from '../../../GenericComponent';
import { TokenStorageService } from '../../../services/token-storage.service';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-edit-report-type',
  templateUrl: './edit-report-type.component.html',
  styleUrls: ['./edit-report-type.component.css']
})
export class EditReportTypeComponent  extends GenericComponent  implements OnInit {

  constructor(private route : ActivatedRoute, private reportTypeService: ReportTypeService, private token: TokenStorageService,  private localService: LocalService, private i18NService: I18NService) {
    super(localService, i18NService, "app");
    this.appsUI = this.i18NService.ui()["apps"];
  }

  reportTypeId: any ;
  reportTypeName: any ;
  appsUI: any = {};
  roles: string[] = [];
  isAdmin : boolean ;
  currentUser:  any = {};

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.reportTypeId = params.id ;
    });
    if (this.token.getToken()) {
       this.roles = this.token.getUser().roles;
       this.currentUser = this.token.getUser();
    }
    if (this.token.getRememberMe()) {
       this.roles = this.token.getUserRememberMe().roles;
       this.currentUser = this.token.getUserRememberMe();
    }
    this.isAdmin = this.roles.includes('ROLE_ADMIN');
    this.reportTypeService.getReportTypeById(this.reportTypeId).subscribe(
      data => {
      this.reportTypeName = data.typeName;
      },
      error => {
        console.log(error);
      }
    );
  }
  editReportType(){
    this.reportTypeService.updateReportType(this.reportTypeId , this.reportTypeName).subscribe(
      data => {
      },
      error => {
        console.log(error);
      }
    );
  }
}
