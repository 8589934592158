import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService {

  constructor(public token: TokenStorageService, public router: Router) {}

  canActivate(): boolean {
    if ((!this.token.getRememberMe()) && (!this.token.getUser())) {
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }
}
